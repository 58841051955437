import { CotData, CotDataKeyMap, CotDataResponse, CotDataRow, CotDataRowKey, CotDataRowKeys } from '@protos/cot';

const getCounterPartyData = (counterPartyDataResponse: CotDataResponse, key: CotDataRowKey) => {
  const managedMoney = counterPartyDataResponse.CounterPartyData?.find(cp => cp.CounterParty === 'Managed Money');
  const swapDealer = counterPartyDataResponse.CounterPartyData?.find(cp => cp.CounterParty === 'Swap Dealer');
  const otherReportables = counterPartyDataResponse.CounterPartyData?.find(cp => cp.CounterParty === 'Other Reportables');
  const producerMerchantProcessor = counterPartyDataResponse.CounterPartyData?.find(cp => cp.CounterParty === 'Producer/Merchant/Processor/User');

  switch (key) {
    case 'onekBarrels':
      return {
        oi: counterPartyDataResponse.OI,
        managedMoney: { long: managedMoney?.MarketLongPositionExtrap || 0, short: managedMoney?.MarketShortPositionExtrap || 0 },
        swapDealer: { long: swapDealer?.MarketLongPositionExtrap || 0, short: swapDealer?.MarketShortPositionExtrap || 0 },
        otherReportables: { long: otherReportables?.MarketLongPositionExtrap || 0, short: otherReportables?.MarketShortPositionExtrap || 0 },
        producerMerchantProcessor: {
          long: producerMerchantProcessor?.MarketLongPositionExtrap || 0,
          short: producerMerchantProcessor?.MarketShortPositionExtrap || 0,
        },
      };
    case 'commitmentChange':
      return {
        oi: counterPartyDataResponse.OI7DayChange,
        managedMoney: {
          long: managedMoney?.MarketLongPositionExtrap7DayChange || 0,
          short: managedMoney?.MarketShortPositionExtrap7DayChange || 0,
        },
        swapDealer: { long: swapDealer?.MarketLongPositionExtrap7DayChange || 0, short: swapDealer?.MarketShortPositionExtrap7DayChange || 0 },
        otherReportables: {
          long: otherReportables?.MarketLongPositionExtrap7DayChange || 0,
          short: otherReportables?.MarketShortPositionExtrap7DayChange || 0,
        },
        producerMerchantProcessor: {
          long: producerMerchantProcessor?.MarketLongPositionExtrap7DayChange || 0,
          short: producerMerchantProcessor?.MarketShortPositionExtrap7DayChange || 0,
        },
      };
    case 'percentageOfOI':
      return {
        oi: 100,
        managedMoney: { long: managedMoney?.CPLongMSExtrap || 0, short: managedMoney?.CPShortMSExtrap || 0 },
        swapDealer: { long: swapDealer?.CPLongMSExtrap || 0, short: swapDealer?.CPShortMSExtrap || 0 },
        otherReportables: { long: otherReportables?.CPLongMSExtrap || 0, short: otherReportables?.CPShortMSExtrap || 0 },
        producerMerchantProcessor: { long: producerMerchantProcessor?.CPLongMSExtrap || 0, short: producerMerchantProcessor?.CPShortMSExtrap || 0 },
      };
    case 'noOfCounterparty':
      return {
        oi: null,
        managedMoney: { long: managedMoney?.CounterPartyCount || 0, short: managedMoney?.CounterPartyCount || 0 },
        swapDealer: { long: swapDealer?.CounterPartyCount || 0, short: swapDealer?.CounterPartyCount || 0 },
        otherReportables: { long: otherReportables?.CounterPartyCount || 0, short: otherReportables?.CounterPartyCount || 0 },
        producerMerchantProcessor: {
          long: producerMerchantProcessor?.CounterPartyCount || 0,
          short: producerMerchantProcessor?.CounterPartyCount || 0,
        },
      };
  }
};

export const transformCotData = (data: CotDataResponse[]): Record<string, CotData> => {
  return data.reduce((acc, item) => {
    const managedMoney = item.CounterPartyData.find(cp => cp.CounterParty === 'Managed Money');
    const swapDealer = item.CounterPartyData.find(cp => cp.CounterParty === 'Swap Dealer');
    const otherReportables = item.CounterPartyData.find(cp => cp.CounterParty === 'Other Reportables');
    const producerMerchantProcessor = item.CounterPartyData.find(cp => cp.CounterParty === 'Producer/Merchant/Processor/User');

    if (managedMoney && swapDealer && otherReportables && producerMerchantProcessor) {
      const ProductCounterPartyData: CotDataRow[] = [];
      CotDataRowKeys.forEach((key: CotDataRowKey) => {
        const counterPartyData = getCounterPartyData(item, key);

        ProductCounterPartyData.push({
          rowId: `${item.ProductSymbol}-${key}-all`,
          symbol: item.ProductSymbol,
          key,
          subKey: 'all',
          ...counterPartyData,
        });
        ProductCounterPartyData.push({
          rowId: `${item.ProductSymbol}-${key}-old`,
          symbol: item.ProductSymbol,
          key,
          subKey: 'old',
          ...counterPartyData,
        });
        ProductCounterPartyData.push({
          rowId: `${item.ProductSymbol}-${key}-other`,
          symbol: item.ProductSymbol,
          key,
          subKey: 'other',
          oi: null,
          managedMoney: { long: 0, short: 0 },
          swapDealer: { long: 0, short: 0 },
          otherReportables: { long: 0, short: 0 },
          producerMerchantProcessor: { long: 0, short: 0 },
        });

        acc[item.ProductSymbol] = {
          rows: ProductCounterPartyData,
          symbol: item.ProductSymbol,
          fullName: item.FullName,
          exchanges: item.Exchanges,
          reportDate: item.ReportDate,
        };
      });
    }

    return acc;
  }, {} as Record<string, CotData>);
};

export const generateDtdContent = (cotData: Record<string, CotData>) => {
  let content = `The projected market position is extrapolated based on Onyx Commodities proprietary data. This estimate reflects our interpretation of the data collected by our company.\nThe actual market position may vary as it is influenced by multiple external factors and market dynamics beyond the scope of our data collection.\n\n\n`;

  Object.keys(cotData).forEach(symbol => {
    const data: CotData = cotData[symbol];
    const rows: CotDataRow[] = data.rows;

    // Table header
    content += `${symbol.toLocaleUpperCase()} - ${data.fullName}`.padEnd(137) + `Exchanges   : ${data.exchanges.join(', ')}\n`;
    content += `Disaggregated Commitment of Traders - Swaps traded with Onyx Only`.padEnd(137) + `Report Date : ${data.reportDate}\n`;
    content += `-------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n`;
    content += `     :                 :         Producer/Merchant/        :                                   :                                   :                                     \n`;
    content += `     :      Open       :          Processor/User           :            Swap Dealers           :            Managed Money          :          Other Reportables          \n`;
    content += `     :    Interest     :        Long     :    Short        :        Long     :    Short        :        Long     :     Short       :       Long      :     Short         \n`;
    content += `-------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n`;

    // Function to format values for alignment
    const formatValue = (value: any, width: number = 15) => {
      return value !== undefined && value !== null ? value.toLocaleString().padStart(width) : ' '.repeat(width);
    };

    // Table rows
    CotDataRowKeys.forEach(key => {
      const allRow = rows.find(r => r.rowId === `${symbol}-${key}-all`);
      const oldRow = rows.find(r => r.rowId === `${symbol}-${key}-old`);
      const otherRow = rows.find(r => r.rowId === `${symbol}-${key}-other`);

      // Add row header with proper alignment
      content += `     :                 : ${CotDataKeyMap[key].padEnd(22)}\n`;

      // Adding data rows with alignment
      content += `All  : ${formatValue(allRow?.oi)} : `;
      content += formatValue(allRow?.producerMerchantProcessor.long) + ' : ';
      content += formatValue(allRow?.producerMerchantProcessor.short) + ' : ';
      content += formatValue(allRow?.swapDealer.long) + ' : ';
      content += formatValue(allRow?.swapDealer.short) + ' : ';
      content += formatValue(allRow?.managedMoney.long) + ' : ';
      content += formatValue(allRow?.managedMoney.short) + ' : ';
      content += formatValue(allRow?.otherReportables.long) + ' : ';
      content += formatValue(allRow?.otherReportables.short) + '\n';

      content += `Old  : ${formatValue(oldRow?.oi)} : `;
      content += formatValue(oldRow?.producerMerchantProcessor.long) + ' : ';
      content += formatValue(oldRow?.producerMerchantProcessor.short) + ' : ';
      content += formatValue(oldRow?.swapDealer.long) + ' : ';
      content += formatValue(oldRow?.swapDealer.short) + ' : ';
      content += formatValue(oldRow?.managedMoney.long) + ' : ';
      content += formatValue(oldRow?.managedMoney.short) + ' : ';
      content += formatValue(oldRow?.otherReportables.long) + ' : ';
      content += formatValue(oldRow?.otherReportables.short) + '\n';

      content += `Other: ${key === 'noOfCounterparty' ? formatValue(otherRow?.oi) : formatValue(otherRow?.oi || '0')} : `;
      content += formatValue(otherRow?.producerMerchantProcessor.long) + ' : ';
      content += formatValue(otherRow?.producerMerchantProcessor.short) + ' : ';
      content += formatValue(otherRow?.swapDealer.long) + ' : ';
      content += formatValue(otherRow?.swapDealer.short) + ' : ';
      content += formatValue(otherRow?.managedMoney.long) + ' : ';
      content += formatValue(otherRow?.managedMoney.short) + ' : ';
      content += formatValue(otherRow?.otherReportables.long) + ' : ';
      content += formatValue(otherRow?.otherReportables.short) + '\n';

      // Add an extra newline after each key section
      content += `\n`;
    });

    // Add a newline between symbols
    content += `\n`;
  });

  return content;
};
