import { useTheme } from '@mui/material';
import { useRef } from 'react';

export default function useInput() {
  const canvasRef = useRef<HTMLCanvasElement>();
  const theme = useTheme();

  /**
   *
   * @param text
   * @returns input text width in pixels
   */
  const getTextWidth = (text): number => {
    if (!text) {
      return 0;
    }

    if (!canvasRef.current) {
      canvasRef.current = document.createElement('canvas');
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (!context) {
      return 0;
    }

    context.font = `${theme.typography.fontWeightBold} ${theme.typography.fontSize}px ${theme.typography.fontFamily}`; // ex.: 700 14px 'Noto Sans'
    const metrics = context.measureText(text);
    return metrics.width;
  };

  return { getTextWidth };
}
