import { useAdminApi } from '@hooks/useAdminApi';
import { useApi } from '@hooks/useApi';
import { Box, Button, Divider, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toastifyService } from '@services/ToastifyService';
import theme from '@shared/themes/darkTheme';
import { ResolutionString } from '@tradingview/types';
import { formatDatetime } from '@utils/date';
import { BarData, ColorType, Time, createChart } from 'lightweight-charts';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DateTime } from '../DateTime';

const OUTLIER_CONTAINER_WIDTH = 800;

export const StyledChartBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.background.darker,
}));

const StyledChartLoadingBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.darker,
}));

export interface SimpleChartProps {
  symbol: string | undefined;
  period: ResolutionString;
  start?: string;
  end?: string;
  onClick?: (time: BarData<Time> | undefined) => void;
}

interface SelectedChartPoint extends BarData<Time> {
  startTimestamp: Time;
  endTimestamp: Time;
}

const insertDataPointAtAscTime = (dataPoints: SelectedChartPoint[], dataPoint: SelectedChartPoint) => {
  const index = dataPoints.findIndex(({ time }) => time > dataPoint.time);

  if (index === -1) {
    return [...dataPoints, dataPoint];
  }
  return [...dataPoints.slice(0, index), dataPoint, ...dataPoints.slice(index)];
};

export const SimpleChart = ({ symbol, start, end, period, onClick }: SimpleChartProps) => {
  const { apiClient } = useApi();
  const { apiClient: adminApiClient } = useAdminApi();

  const chartContainerRef = useRef<HTMLDivElement>();
  const chartRef = useRef<any>(null);
  const lineSeriesRef = useRef<any>(null);

  const [chartData, setChartData] = useState<BarData<Time>[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState<SelectedChartPoint[] | undefined>();

  const onDateTimeChange = useCallback(
    debounce((index: number, key: 'start' | 'end', newValue?: string) => {
      if (!newValue) return;
      const updatedDateTimestamp = Date.parse(newValue) / 1000;
      setSelectedPoints(prevState => {
        const updatedPoints = prevState?.map((point, i) => {
          if (i === index) {
            return { ...point, [`${key}Timestamp`]: updatedDateTimestamp };
          }
          return point;
        });
        return updatedPoints;
      });
    }, 500),
    []
  );

  const onCreateOutliers = useCallback(() => {
    if (selectedPoints && selectedPoints.length > 0 && symbol && apiClient) {
      Promise.all(
        selectedPoints.map(point =>
          adminApiClient?.createOutlier({
            symbol,
            period,
            start: new Date(+point.startTimestamp * 1000).toISOString(),
            end: new Date(+point.endTimestamp * 1000).toISOString(),
          })
        )
      ).then(() => {
        setSelectedPoints(undefined);
        toastifyService.showSuccess('Outliers created successfully');
      });
    }
  }, [symbol, period, selectedPoints, adminApiClient]);

  const onRemoveDatapoint = useCallback((dataPointTime: Time) => {
    setSelectedPoints(prevState => {
      const updatedPoints = prevState?.filter(point => point.time !== dataPointTime);
      return updatedPoints;
    });
  }, []);

  useEffect(() => {
    const getChartData = async () => {
      if (!apiClient || !symbol) return;

      const queryParams = {};
      if (start) queryParams['start'] = start;
      if (end) queryParams['end'] = end;

      setIsLoadingData(true);
      const api = apiClient.historicalChartLoader(symbol, period, queryParams);
      let allBars: BarData<Time>[] = [];

      while ((start && end && api.hasMoreData()) || !api.hasLoadedData()) {
        const apiData = await api.loadData();
        const transformedApiData = apiData.map(
          ({ timestamp, low, high, open, close }) =>
            ({
              time: Date.parse(timestamp) / 1000,
              low,
              high,
              open,
              close,
            } as BarData<Time>)
        );
        allBars = [...allBars, ...transformedApiData];
      }

      return allBars.reverse();
    };

    getChartData().then(data => {
      setChartData(data ?? []);
      setIsLoadingData(false);
    });
  }, [apiClient, symbol, start, end, period]);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    chartRef.current = createChart(chartContainerRef.current, {
      width: window.innerWidth >= 50 ? window.innerWidth - 50 : 0,
      height: window.innerHeight / 4,
      layout: { background: { type: ColorType.Solid, color: theme.palette.background.darker }, textColor: 'white' },
      grid: { horzLines: { visible: false }, vertLines: { visible: false } },
      timeScale: { timeVisible: true, secondsVisible: false, borderColor: theme.palette.background.lighter, fixLeftEdge: true, fixRightEdge: true },
    });
    const candlestickSeries = chartRef.current.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });
    lineSeriesRef.current = chartRef.current.addLineSeries();
    const lineData = chartData.map(({ time, close }) => ({ time, value: close }));

    candlestickSeries.setData(chartData);
    lineSeriesRef.current.setData(lineData);
    chartRef.current.timeScale().fitContent();

    const onChartClick = event => {
      const chartContainer = chartContainerRef.current;
      if (chartContainer) {
        const rect = chartContainer.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const clickedTime = chartRef.current.timeScale().coordinateToTime(x);

        const dataPoint = chartData.find(({ time }) => time === clickedTime);
        if (dataPoint) {
          if (!onClick) {
            setSelectedPoints(prevState =>
              insertDataPointAtAscTime(prevState ?? [], { ...dataPoint, startTimestamp: dataPoint.time, endTimestamp: dataPoint.time })
            );
          } else {
            onClick(dataPoint);
          }
        }
      }
    };

    chartContainerRef.current.addEventListener('click', onChartClick);

    return () => {
      chartContainerRef.current?.removeEventListener('click', onChartClick);
      chartRef.current.remove();
    };
  }, [chartData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce(entries => {
        entries.forEach(entry => {
          if (entry.contentBoxSize) {
            const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
            const width = contentBoxSize.inlineSize - (selectedPoints && selectedPoints.length > 0 ? OUTLIER_CONTAINER_WIDTH : 0) - 220;

            chartRef.current.applyOptions({ width: width < 0 ? 0 : width });
          } else {
            const width = entry.contentRect.width - (selectedPoints && selectedPoints.length > 0 ? OUTLIER_CONTAINER_WIDTH : 0) - 220;

            chartRef.current.applyOptions({ width: width < 0 ? 0 : width });
          }
        });
      }, 200)
    );

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [selectedPoints]);

  useEffect(() => {
    const currentChartDateRange = chartRef.current.timeScale().getVisibleRange();
    lineSeriesRef.current.setMarkers(
      (selectedPoints ?? []).flatMap(({ time }, index) => {
        const isTimeInVisibleRange = +time >= +currentChartDateRange.from && +time <= +currentChartDateRange.to;
        if (!isTimeInVisibleRange) return [];

        return {
          time: time,
          position: 'aboveBar',
          color: theme.palette.background.infoRow,
          shape: 'arrowDown',
          text: `Outlier #${index + 1}`,
        };
      })
    );
  }, [selectedPoints]);

  return isLoadingData ? (
    <StyledChartLoadingBox>Loading chart data...</StyledChartLoadingBox>
  ) : (
    <Box display="flex" bgcolor={theme.palette.background.darker} flex={1} maxHeight="60%">
      <Box display="flex" flexDirection="column" flex={1}>
        <List component={Stack} direction="row" alignItems="center" gap={1} minWidth="max-content" maxHeight={35}>
          <ListItem style={{ flex: 0, padding: 0, minWidth: 'max-content', marginLeft: 10 }}>{symbol?.toLocaleUpperCase()}</ListItem>
          {start && end && (
            <>
              <Divider orientation="vertical" style={{ backgroundColor: theme.palette.background.lighter, width: 2 }} />
              <ListItem style={{ flex: 0, padding: 0, minWidth: 'max-content' }}>{`Showing from ${formatDatetime(start)} till ${formatDatetime(
                end
              )}`}</ListItem>
            </>
          )}
          <Divider orientation="vertical" style={{ backgroundColor: theme.palette.background.lighter, width: 2 }} />
          <ListItem style={{ flex: 0, padding: 0, minWidth: 'max-content' }}>{period}</ListItem>
        </List>
        <StyledChartBox ref={chartContainerRef} />
      </Box>
      <Divider orientation="vertical" />
      {selectedPoints && selectedPoints.length > 0 && (
        <Stack display="flex" direction="column" minWidth={OUTLIER_CONTAINER_WIDTH} flex={1} padding={1} gap={1}>
          <Typography variant="h5">Create Outliers</Typography>
          <Divider />
          <List component={Stack} direction="column" gap={1} overflow="auto" maxHeight="100%" alignContent="flex-start">
            {selectedPoints.map(({ startTimestamp, endTimestamp, time }, index) => (
              <List
                key={time.toLocaleString()}
                component={Stack}
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ padding: 10, borderRadius: 5, backgroundColor: theme.palette.background.default, flex: 1, gap: 10 }}
              >
                <ListItem style={{ flex: 1, padding: 0, fontSize: 11 }}>Outlier #{index + 1}</ListItem>
                <DateTime
                  label="Start"
                  value={+startTimestamp * 1000}
                  onChange={newDateTime => onDateTimeChange(index, 'start', newDateTime)}
                  useUtcTime
                />
                <DateTime label="End" value={+endTimestamp * 1000} onChange={newDateTime => onDateTimeChange(index, 'end', newDateTime)} useUtcTime />
                <IconButton onClick={() => onRemoveDatapoint(time)} style={{ fontSize: '22px' }}>
                  <span className="ri-close-line" />
                </IconButton>
              </List>
            ))}
          </List>
          <Stack marginTop="auto" gap={1}>
            <Divider />
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
              <Button variant="contained" color="secondary" size="small" sx={{ fontSize: 11 }} onClick={() => setSelectedPoints(undefined)}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" size="small" sx={{ fontSize: 11 }} onClick={onCreateOutliers}>
                Create
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
