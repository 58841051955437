import { useAdminApi } from '@hooks/useAdminApi';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Divider, List, Typography } from '@mui/material';
import { FilterType } from '@protos/filter';
import { toastifyService } from '@services/ToastifyService';
import { FilterPanel } from '@shared/components/FilterPanel';
import { Pill } from '@shared/components/Pill';
import { useDataLoader } from '@shared/hooks/useDataLoader';
import { ALERT_LEVEL_OPTIONS, Alert } from '@shared/protos/alerts';
import { DataApi } from '@shared/utils/data';
import { useCallback, useEffect, useState } from 'react';
import { useInfiniteScrolling } from '../../../shared/hooks/useInfiniteScrolling';
import { AlertListItem } from './AlertListItem';
import { EventsAlertsDialog } from './EventsAlertsDialog';

export const AlertsList: React.FC<{ alertsLoader: DataApi<Alert> }> = ({ alertsLoader }) => {
  const { apiClient } = useAdminApi();
  const { data, fetchMoreData, fetchData, isLoadingData } = useDataLoader(alertsLoader);
  const { urlParams, setURLSearchParam } = useURLSearchParams();

  const [selectedEventsAlert, setSelectedEventsAlert] = useState<Alert | undefined>();

  const { lastItemRef } = useInfiniteScrolling(() => {
    fetchMoreData();
  });

  const onFilterChange = useCallback(
    (val: Record<string, string | null | undefined>) => {
      const filterKey = Object.keys(val)[0];
      const filterValue = val[filterKey];
      setURLSearchParam(filterKey, filterValue ?? undefined);
    },
    [setURLSearchParam]
  );

  const onAlertDismissed = useCallback(
    async (id: number) => {
      try {
        await apiClient?.deleteAlert(id);
        toastifyService.showAlertDismissedConfirmation();
        const { search, ...filters } = urlParams;
        fetchData(filters, search ?? undefined);
      } catch (err: any) {
        console.error(err.data.error || 'Something went wrong');
      }
    },
    [apiClient, urlParams]
  );

  const onSearch = useCallback(
    (val: string) => {
      const newParams = { ...urlParams, search: val };
      const { search, ...filters } = newParams;
      fetchData(filters, search);
    },
    [fetchData, urlParams]
  );

  useEffect(() => {
    const { search, ...filters } = urlParams;
    fetchData(filters, search);
  }, [urlParams]);

  return (
    <>
      <FilterPanel
        filters={[{ type: FilterType.AUTOCOMPLETE, name: 'level', label: 'Alert Type', options: ALERT_LEVEL_OPTIONS }]}
        onSearch={onSearch}
        loading={isLoadingData}
        sticky
      >
        {urlParams?.group && <Pill value={urlParams.group} onRemove={() => onFilterChange({ group: null })} />}
      </FilterPanel>
      <Divider style={{ height: 3 }} />
      <List style={{ padding: 0 }}>
        {data.map((alert, index) => (
          <AlertListItem
            key={`alert-${alert.id}`}
            alert={alert}
            lastItemRef={index === data.length - 1 ? lastItemRef : undefined}
            onDismissAlert={onAlertDismissed}
            onFilterChange={onFilterChange}
            setSelectedEventsAlert={alert => setSelectedEventsAlert(alert)}
          />
        ))}
        {!data.length && (
          <Box display="flex" justifyContent="center" margin="10px 0">
            <Typography fontSize={11}> No alerts found</Typography>
          </Box>
        )}
      </List>
      {selectedEventsAlert && apiClient && (
        <EventsAlertsDialog selectedEventsAlert={selectedEventsAlert} onClose={() => setSelectedEventsAlert(undefined)} />
      )}
    </>
  );
};
