import { streamProduct } from '@services/context';
import { TickerCallback } from '@services/streamProductService';
import { useEffect, useRef } from 'react';

// hook for subscribing to a list of products
export const useStreamProduct = (callback: TickerCallback, productsToSubscribe: string[]) => {
  const target = useRef<TickerCallback>(callback);
  const subscribingProducts = [...productsToSubscribe];

  useEffect(() => {
    target.current = callback;
    streamProduct.subscribe(subscribingProducts, callback);
    return () => {
      streamProduct.unsubscribe(subscribingProducts, callback);
    };
  }, [subscribingProducts]);
};
