import { EventLogTypeId, UserActivity } from './types';

export default class UserActivityTracker {
  queue: UserActivityQueue;
  client: UserActivityClient;

  constructor() {
    this.queue = new UserActivityQueue();
    this.client = new UserActivityClient(this.queue);

    // before unload the page save
    window.addEventListener('beforeunload', this.client.saveForLater.bind(this.client));
    window.addEventListener('online', this.client.save.bind(this.client));
  }

  public async forceSave(): Promise<void> {
    await this.client.save();
  }

  public signedIn(details: string): void {
    this.queue.enqueue({
      eventLogTypeId: EventLogTypeId.UserSignedInWeb,
      timestamp: this.getTimestamp(),
      details,
    });
  }

  public signedOut(details: string): void {
    this.queue.enqueue({
      eventLogTypeId: EventLogTypeId.UserSignedOutWeb,
      timestamp: this.getTimestamp(),
      details,
    });
  }

  public navigation(details: string): void {
    this.queue.enqueue({
      eventLogTypeId: EventLogTypeId.UserNavigatedToPage,
      timestamp: this.getTimestamp(),
      details,
    });
  }

  public click(details: string): void {
    this.queue.enqueue({
      eventLogTypeId: EventLogTypeId.UserClick,
      timestamp: this.getTimestamp(),
      details,
    });
  }

  private getTimestamp(): Date {
    return new Date();
  }
}

class UserActivityQueue {
  private store: UserActivity[] = [];
  private enqueueSubs: Function[] = [];

  public enqueue(activity: UserActivity) {
    this.store.push(activity);

    // notify
    this.enqueueSubs.forEach(fn => fn(activity));
  }

  public reenqueue(activities: UserActivity[]) {
    this.store.push(...activities);
  }

  public dequeueOne(): UserActivity | undefined {
    return this.store.shift();
  }

  public dequeueAll(): UserActivity[] {
    return this.store.splice(0);
  }

  public subscribeEnqueue(obeserver: Function) {
    this.enqueueSubs.push(obeserver);
  }
}

class UserActivityClient {
  timeout?: NodeJS.Timeout;
  queue: UserActivityQueue;
  static localStorageKey = 'flux-user-activity';

  constructor(queue: UserActivityQueue) {
    this.queue = queue;
    this.queue.subscribeEnqueue(this.saveAsync.bind(this));
  }

  public saveAsync(): void {
    if (this.timeout === undefined) {
      this.timeout = setTimeout(this.save.bind(this), 3000);
    }
  }

  public async save(): Promise<void> {
    this.timeout = undefined;

    const currentActivities = this.queue.dequeueAll();
    const offlineActivities = this.getActivitiesFromLocalStorage();
    const allActivities = [...offlineActivities, ...currentActivities];

    if (navigator.onLine) {
      await this.postApi(allActivities);
    } else {
      // if it's offline, save it to local storage
      this.saveActivitiesToLocalStorage(allActivities);
    }
  }

  public saveForLater(): void {
    const currentActivities = this.queue.dequeueAll();
    const offlineActivities = this.getActivitiesFromLocalStorage();
    const allActivities = [...offlineActivities, ...currentActivities];

    this.saveActivitiesToLocalStorage(allActivities);
  }

  private async postApi(activities: UserActivity[]): Promise<void> {}

  private getActivitiesFromLocalStorage(): UserActivity[] {
    const persistedActivities = localStorage.getItem(UserActivityClient.localStorageKey);
    localStorage.removeItem(UserActivityClient.localStorageKey);
    return persistedActivities ? JSON.parse(persistedActivities) : [];
  }

  private saveActivitiesToLocalStorage(activities: UserActivity[]) {
    localStorage.setItem(UserActivityClient.localStorageKey, JSON.stringify(activities));
  }
}
