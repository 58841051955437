import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useActivePage() {
  const location = useLocation();

  const pageId = useMemo(() => location.pathname.split('/')[1] ?? undefined, [location]);

  return { pageId };
}
