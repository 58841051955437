import { useAdminApi } from '@hooks/useAdminApi';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { IconButton, Tooltip } from '@mui/material';
import { Execution } from '@protos/trading';
import { toastifyService } from '@services/ToastifyService';
import darkTheme from '@shared/themes/darkTheme';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { useCallback } from 'react';

export const ExecutionReconcileCellRenderer = (props: ICellRendererParams<Execution>) => {
  const { apiClient } = useAdminApi();

  const onClick = useCallback(async () => {
    if (!apiClient || !props.data?.id) return;

    try {
      const updatedExecution = await apiClient.reconcileExecution(props.data?.id);
      if (!updatedExecution) {
        toastifyService.showErrorMessage('Failed to reconcile execution');
        return;
      }
      props.api?.applyTransaction({ update: [updatedExecution] });
      toastifyService.showSuccess(`Reconcile for execution id: ${props.data?.id} was successful`);
    } catch (error) {
      console.error('Error during action');
    }
  }, [props.data?.id, apiClient]);

  return (
    <Tooltip title="Reconcile execution" placement="left">
      <IconButton onClick={onClick} style={{ padding: 0, marginBottom: 3 }}>
        <ChangeCircleIcon style={{ fontSize: '16px', color: darkTheme.palette.text.primary }} />
      </IconButton>
    </Tooltip>
  );
};
