import { Paper } from '@mui/material';
import InlineGroup from '@shared/components/InlineGroup';
import MultilineGroup from '@shared/components/MultilineGroup';
import { TextInputReadOnlyBox } from '@shared/components/TextInputReadOnlyBox';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { PRODUCT_GROUP_NAME } from '@shared/protos/options';
import { Product } from '@shared/protos/product';
import { chunkArray } from '@shared/utils/array';
import { ReactNode } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type Props = {
  product: Product;
};

const generateProductInfo = (product: Product, navigate: NavigateFunction) => [
  {
    label: 'Short name',
    value: product.short_name,
  },

  {
    label: 'Product group',
    value: PRODUCT_GROUP_NAME[product.product_group],
  },

  {
    label: 'Currency unit',
    value: product.currency_unit,
  },
  {
    label: 'Calendar type',
    value: product.calendar_type,
  },
  {
    label: 'Product type',
    value: product.product_type,
  },
  {
    label: 'Pricing tenors',
    value: product.pricing_tenors,
  },
  {
    label: 'Tenor frequency',
    value: product.tenor_frequency,
  },
  {
    label: 'MDX Name',
    value: product.mdx_name,
  },
  {
    label: 'Tick Size',
    value: product.tick_size,
  },
  {
    label: 'Underlying symbol',
    value: product.underlying_symbol,
    onClick: () => navigate(`/admin/products/${product.underlying_symbol}`),
    sx: { cursor: 'pointer' },
  },
];

const getInputFields = (product: Product, navigate: NavigateFunction) => {
  return generateProductInfo(product, navigate).map((data, idx) =>
    !data.value ? null : (
      <TextInputReadOnlyBox label={data.label} value={`${data.value}`} key={`user_info_${idx}`} sx={data.sx} onClick={data.onClick} />
    )
  );
};

export const ProductInfo: React.FC<Props> = ({ product }) => {
  const navigate = useNavigate();

  const { isMobile } = useScreenSize();
  const inputs = getInputFields(product, navigate);

  const numberOfColumns = isMobile ? 1 : 4;

  const chunks = chunkArray(inputs, numberOfColumns);

  return (
    <Paper
      style={{
        background: 'transparent',
      }}
    >
      <MultilineGroup>
        {chunks.map((chunk, idx) => {
          if (chunk.every(item => item === null)) return null;
          return <InlineGroup key={`group_${idx}`}>{chunk as ReactNode[]}</InlineGroup>;
        })}
      </MultilineGroup>
    </Paper>
  );
};
