import { addBusinessDays, format, getDay, isAfter, isBefore, isValid, parse, subBusinessDays } from 'date-fns';
class DateService {
  monthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  private isAValidDate(date): boolean {
    return !isNaN(date) && date instanceof Date;
  }

  public getPreviousWorkday(date: Date = new Date()): Date {
    return subBusinessDays(date, 1);
  }

  public getCurrentSettlementDay(): Date {
    const now: Date = new Date();
    const day: number = getDay(now);

    if (day === 0 || day === 6) {
      return this.getPreviousWorkday();
    }

    return now;
  }

  public getFutureWorkday(date: Date = new Date()): Date {
    return addBusinessDays(date, 1);
  }

  public getFormattedDate(dateAsString: string | undefined): string {
    if (dateAsString === undefined) return '';

    const date: Date = new Date(dateAsString);
    if (!this.isAValidDate(date)) {
      return '';
    }

    const fullYear = date.getFullYear().toString();
    return `${date.getDate()}-${this.monthNames[date.getMonth()]}-${fullYear.substring(fullYear.length - 2)}`;
  }

  public getDateOrThrow(date: string | Date): Date {
    const dateObj = date instanceof Date ? date : new Date(date);

    if (!isValid(dateObj)) {
      throw new Error('Invalid date');
    }

    return dateObj;
  }

  public getDateTimeStr(date: string): string {
    const dataObj = this.getDateOrThrow(date);

    return format(dataObj, 'dd-MMM HH:mm');
  }

  public getLocalTimeStr(date: string): string {
    const dataObj = this.getDateOrThrow(date);

    return format(dataObj, 'dd-MMM-yy HH:mm:ss');
  }

  public getTimeStr(date: Date): string {
    return format(date, 'HH:mm');
  }

  public formatDateStr(initialDate: Date): string {
    let dt = initialDate;

    if (!dt) {
      dt = new Date();
    }

    return format(dt, 'yyyy-LL-dd');
  }

  /**
   *
   * @param {*} time 24-Hour Format ["HH:mm"]
   * @returns Current date if time is invalid. Otherwise, updated date and time
   */
  public setTimeToCurrentDate(time: string): Date {
    const today = new Date();
    const regex = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$', 'gm');
    if (!regex.test(time)) return today;

    const [hours, minutes]: string[] = time.split(':');
    today.setHours(Number(hours), Number(minutes), 0);

    return today;
  }

  public isDateWithinRange = (enteredDate: Date, minDate: Date | undefined, maxDate: Date | undefined) => {
    return (!minDate || isAfter(enteredDate, minDate)) && (!maxDate || isBefore(enteredDate, maxDate));
  };

  public removeTimeFromDate = (dateTimeString: string) => {
    const splitDateTime = dateTimeString.split(' ');
    return splitDateTime.slice(0, 3).join(' ');
  };

  public parseDate = (value: string): Date => {
    let enteredDate = parse(value, 'dd MMM yy', new Date());
    if (!isValid(enteredDate)) {
      enteredDate = parse(value, 'dd MMM yy HH:mm', new Date());
    }
    return enteredDate;
  };
}

const dateService = new DateService();
export { dateService };
