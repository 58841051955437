import { IconButton, Tooltip } from '@mui/material';
import { Contract } from '@protos/product';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { useNavigate } from 'react-router-dom';

export const ProductContractsActionsCellRenderer = (props: ICellRendererParams<Contract>) => {
  const navigate = useNavigate();

  return (
    <Tooltip title="View Contract Outliers">
      <IconButton onClick={() => navigate(`/admin/outliers/${props.data?.symbol}`)} style={{ padding: 0, marginBottom: 3 }}>
        <i className="ri-pulse-fill" style={{ fontSize: '16px' }} />
      </IconButton>
    </Tooltip>
  );
};
