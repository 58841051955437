import { Stack, Typography, useMediaQuery } from '@mui/material';
import { ContractRisk } from '@protos/trading';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { useState } from 'react';
import { Bar, BarChart, LabelList, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type Props = {
  isMobileLandscape: boolean;
  metaData?: { version: number; timestamp: string };
  data: ContractRisk[];
};

export const TradingRiskGraph: React.FC<Props> = ({ isMobileLandscape, metaData, data }) => {
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [barProps, setBarProps] = useState<Record<string, string | boolean>>({ tenor: true, exchange: false, hover: '' });

  const onLegendMouseOver = event => {
    if (barProps[event.dataKey]) {
      setBarProps({ ...barProps, hover: event.dataKey });
    }
  };

  const onLegendMouseLeave = event => {
    setBarProps({ ...barProps, hover: '' });
  };

  const onLegendClick = event => {
    setBarProps({ ...barProps, [event.dataKey]: !barProps[event.dataKey], hover: '' });
  };

  return (
    <>
      {!isMobileLandscape && (
        <Stack width="100%">
          {metaData && (
            <Stack>
              <Typography
                style={{ fontSize: 11, fontWeight: 800, alignSelf: 'start', marginLeft: 10, marginTop: 5 }}
              >{`Version: ${metaData.version}`}</Typography>
              <Typography
                style={{ fontSize: 11, fontWeight: 800, alignSelf: 'start', marginLeft: 10, marginBottom: 5 }}
              >{`Date Time: ${formatDatetime(metaData.timestamp)}`}</Typography>
            </Stack>
          )}
          <ResponsiveContainer width="100%" height={isMdScreen ? '100%' : '70%'}>
            {data.length ? (
              <BarChart
                height={100}
                data={data}
                margin={{
                  top: 0,
                  right: 20,
                  left: -30,
                  bottom: 40,
                }}
                barSize={50}
              >
                <XAxis dataKey="tenorDisplay" stroke="#fff" tick={{ fontWeight: '800' }} tickMargin={10} />
                <YAxis
                  stroke="#fff"
                  tick={{ fontWeight: '800' }}
                  tickCount={10}
                  domain={([dataMin, dataMax]) => {
                    const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));
                    return [-absMax - 100, absMax + 100];
                  }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: theme.palette.background.darker, border: 'none' }}
                  itemStyle={{ fontWeight: 800 }}
                  cursor={{ fill: theme.palette.background.darker, fillOpacity: 0.5 }}
                />
                <Legend
                  onMouseOver={onLegendMouseOver}
                  onMouseLeave={onLegendMouseLeave}
                  onClick={onLegendClick}
                  cursor="auto"
                  verticalAlign="top"
                  height={50}
                />
                <ReferenceLine y={0} stroke="#778ca3" />
                <Bar
                  dataKey="tenor"
                  fill="#FFFFFF"
                  name="Total"
                  fillOpacity={Number(barProps.hover === 'tenor' || !barProps.hover ? 1 : 0.6)}
                  hide={!barProps['tenor']}
                  radius={[10, 10, 0, 0]}
                >
                  <LabelList
                    dataKey="tenor"
                    position="top"
                    style={{ fill: 'white', fontSize: '125%', fontWeight: '800' }}
                    offset={3}
                    formatter={(value: number) => (!value ? '' : value)}
                  />
                </Bar>
                {data.some(data => data.cmeTenor) && (
                  <Bar
                    dataKey="cmeTenor"
                    fill="#FFC312"
                    name="CME"
                    fillOpacity={Number(barProps.hover === 'cmeTenor' || !barProps.hover ? 1 : 0.6)}
                    hide={!barProps['cmeTenor']}
                    radius={[10, 10, 0, 0]}
                  >
                    <LabelList
                      dataKey="cmeTenor"
                      position="top"
                      style={{ fill: 'white', fontSize: '125%', fontWeight: '800' }}
                      offset={3}
                      formatter={(value: number) => (!value ? '' : value)}
                    />
                  </Bar>
                )}
                {data.some(data => data.iceTenor) && (
                  <Bar
                    dataKey="iceTenor"
                    fill="#CD6133"
                    name="ICE"
                    fillOpacity={Number(barProps.hover === 'iceTenor' || !barProps.hover ? 1 : 0.6)}
                    hide={!barProps['iceTenor']}
                    radius={[10, 10, 0, 0]}
                  >
                    <LabelList
                      dataKey="iceTenor"
                      position="top"
                      style={{ fill: 'white', fontSize: '125%', fontWeight: '800' }}
                      offset={3}
                      formatter={(value: number) => (!value ? '' : value)}
                    />
                  </Bar>
                )}
              </BarChart>
            ) : (
              <></>
            )}
          </ResponsiveContainer>
        </Stack>
      )}
    </>
  );
};
