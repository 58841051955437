import VisibilityIconButton from '@features/dashboard/widgets/shared/components/VisibilityIconButton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextInput from '@shared/components/TextInput';
import { useState } from 'react';
import { CopyButton } from './CopyButton';

type UserInfoBoxProps = {
  label: string;
  apiToken: string;
};

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ApiTokenBox: React.FC<UserInfoBoxProps> = ({ label, apiToken }) => {
  const [hidden, setHidden] = useState(false);

  return (
    <StyledBox>
      <Box display="flex" flex={1} maxWidth="calc(100% - 80px)">
        <TextInput
          label={label}
          inputProps={{
            name: label,
            readOnly: true,
            type: hidden ? 'text' : 'password',
            value: apiToken,
            sx: { display: 'flex', flex: 1 },
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', marginLeft: 'auto', marginTop: '10px', width: '80px' }}>
        <CopyButton message={'API token copied.'} textToCopied={apiToken} />
        <VisibilityIconButton hidden={hidden} onClick={() => setHidden(!hidden)} />
      </Box>
    </StyledBox>
  );
};
