import { priceFormatterService } from '@services/PriceFormatterService';
import { Product, ProductTenor } from '@shared/protos/product';
import { ColDef } from 'ag-grid-enterprise';
import { TENOR_COL_ID } from '../settlement-prices/utils';
import { getBalDisplayName } from '../shared/utils';
import { PricesRow } from './types';

export const tenorColumn: ColDef<PricesRow> = {
  field: 'tenorName',
  colId: TENOR_COL_ID,
  headerName: '',
  suppressSpanHeaderHeight: true,
  minWidth: 80,
  maxWidth: 120,
  lockPosition: 'left',
  pinned: 'left',
  cellClass: 'ag-tenor-column',
  resizable: true,
};

export const mapColumns = (products: Product[], selectedColumns: string[], columnsOrder: string[], isMobile: boolean): ColDef<any, any>[] =>
  products
    .sort((a, b) => columnsOrder.indexOf(a.symbol) - columnsOrder.indexOf(b.symbol))
    .reduce<ColDef<any, any>[]>(
      (accumulator, { symbol, short_name, full_name }) => [
        ...accumulator,
        {
          colId: symbol,
          headerName: short_name,
          flex: 1,
          minWidth: !isMobile ? 50 : 90,
          cellDataType: 'text',
          valueFormatter: ({ value }) => priceFormatterService.formatProductPrice(value),
          valueGetter: ({ data }) => data?.[symbol],
          valueSetter: ({ data, newValue }) => {
            if (data[symbol] === undefined) {
              return false;
            }

            data[symbol] = newValue;
            return true;
          },
          headerTooltip: full_name,
          hide: !selectedColumns.includes(symbol),
          resizable: !isMobile,
        },
      ],
      [tenorColumn]
    );

export const mapRows = (tenors: ProductTenor[], products: Product[], firstWeekIndex: number, firstMonthIndex: number): PricesRow[] => {
  return tenors.map(({ code, display, frequency }, index) => {
    const newRow = {
      tenorId: code,
      tenorName: getBalDisplayName(firstMonthIndex, firstWeekIndex, index, display),
      tenorFrequency: frequency,
    };
    products.forEach(({ symbol }) => {
      newRow[symbol] = '';
    });

    return newRow as PricesRow;
  });
};
