import { withAuthenticationRequired } from '@auth0/auth0-react';

import PageLoadProgress from '@shared/components/PageLoadProgress';

import { ComponentType, useEffect } from 'react';

interface Props {
  component: ComponentType<object>;
}

/**
 * Wraps a React component with Auth0's authentication.
 *
 * Unauthenticated users are redirected to the login page, with a loading indicator shown during redirection.
 *
 * @see {@link https://auth0.com/docs/libraries/auth0-react#use-with-a-class-component|Auth0 Documentation} for more information on Auth0 integration with React.
 *
 * @props component - React component to enforce authentication on.
 * @returns A component wrapped with Auth0 authentication logic, enforcing user authentication.
 *
 * @example <AuthenticationGuard component={ProtectedComponent} />
 */

export const AuthenticationGuard = ({ component }: Props) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoadProgress />,
  });

  useEffect(() => {
    const authTokenInterval = setInterval(() => {
      window.location.reload();
    }, 40000000);

    return () => clearInterval(authTokenInterval);
  }, []);

  return <Component />;
};
