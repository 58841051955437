import { Box, Typography } from '@mui/material';

interface SettingsFormResultLayoutProps {
  title: string;
  Form?: React.ReactNode;
}

export const SettingsFormResultLayout = ({ title, Form }: SettingsFormResultLayoutProps) => {
  return (
    <Box display="flex" flex={1.5} flexDirection="column" gap={2}>
      <Typography variant="h4">{title}</Typography>
      {Form}
    </Box>
  );
};
