import { Tooltip } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';

type MenuProps = {
  to?: string;
  noAccess?: boolean;
} & IconButtonProps;

type SidebarButtonProps = {
  icon: string;
  title: string;
  to?: string;
} & IconButtonProps;

export const StyledMenuItem = styled(IconButton, { shouldForwardProp: propName => propName !== 'noAccess' })<MenuProps>(({ theme, noAccess }) => ({
  color: noAccess ? alpha(theme.palette.text.secondary, 0.3) : theme.palette.text.secondary,
  fontSize: 23,
  width: 34,
  height: 34,
  borderRadius: '2px',
  justifyItems: 'baseline',
  '&:hover, &.active': {
    color: noAccess ? alpha(theme.palette.text.secondary, 0.3) : theme.palette.text.primary,
    backgroundColor: '#242627',
  },
  '&:not([type=button]) > span': {
    alignSelf: 'flex-end',
  },
}));

export function SidebarButton({ icon, title, to, ...buttonProps }: SidebarButtonProps) {
  return (
    <Tooltip title={title} placement="right" enterDelay={800}>
      <StyledMenuItem to={to} {...buttonProps}>
        <i className={icon} />
      </StyledMenuItem>
    </Tooltip>
  );
}
