import { useEffect, useMemo, useRef } from 'react';

import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Filter } from '@protos/filter';
import { useDataLoader } from '@shared/hooks/useDataLoader';
import { DataApi } from '@utils/data';

interface UseURLFilteredDataParams<T> {
  dataLoader: DataApi<T>;
  filters: Filter[];
  externalSearchValue?: string;
  disableURLSearchParams?: boolean;
}

export const useURLFilteredData = <T>({ dataLoader, filters, externalSearchValue, disableURLSearchParams = false }: UseURLFilteredDataParams<T>) => {
  const { urlParams } = useURLSearchParams();
  const { data, fetchData, fetchMoreData } = useDataLoader(dataLoader);

  const hasFetchedOnceRef = useRef(false);
  const lastParamsRef = useRef<string | null>(null);

  useEffect(() => {
    if (disableURLSearchParams && hasFetchedOnceRef.current) {
      return;
    }

    const { search: urlParamSearch, ...urlParamFilters } = urlParams;
    const searchValue = urlParamSearch || externalSearchValue || '';

    const currentParams = JSON.stringify({ filters, searchValue, urlParamFilters });

    if (lastParamsRef.current === currentParams) {
      return;
    }

    lastParamsRef.current = currentParams;

    if (filters.length > 0) {
      fetchData(urlParamFilters, searchValue);
    } else {
      fetchData();
    }

    if (disableURLSearchParams) {
      hasFetchedOnceRef.current = true;
    }
  }, [urlParams, externalSearchValue, fetchData, filters]);

  return useMemo(
    () => ({
      data,
      fetchMoreData,
    }),
    [data, fetchMoreData]
  );
};
