import { useAdminApi } from '@hooks/useAdminApi';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { TRADING_ADMIN_ABN_REPORT_COLUMNS, TRADING_ADMIN_ABN_REPORT_FILTERS } from '../../tradingAdminProtos';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
  width: 150,
};

export const TradingABNReports = () => {
  const { apiClient } = useAdminApi();
  const dataLoader = apiClient?.executionReportsLoader();

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      dataLoader={dataLoader}
      columnDefs={TRADING_ADMIN_ABN_REPORT_COLUMNS}
      filters={TRADING_ADMIN_ABN_REPORT_FILTERS}
      getRowId={getRowId}
      defaultColDef={defaultColDef}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      maxHeight={1000}
    />
  );
};
