import { dateService } from '@services/DateService';
import { logger } from '@services/context';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { SettlementType } from '@shared/protos/settlementPrices';
import { ReactElement, useCallback, useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { WidgetType } from '../enums';
import { getDataGrid, layoutColumns, responsiveUtils } from '../layoutUtils';
import { DataGrid, Widget, WidgetPayload } from '../types';
import Chart from './chart/Chart';
import { FloatingChart } from './chart/FloatingChart';
import { NewsWidget } from './news/NewsWidget';
import { NewsWidgetPayload } from './news/newsTypes';
import Prices from './prices/Prices';
import { PricesWidgetPayload } from './prices/types';
import { SeasonalChart } from './seasonal-chart/SeasonalChart';
import SettlementPrices from './settlement-prices/SettlementPrices';
import { SettlementPricesWidgetPayload } from './settlement-prices/types';
import { usePricesDefaultFilters } from './shared/hooks/usePricesDefaultFilters';
import { TradingBlotter } from './trading-blotter/TradingBlotter';
import { TradingBlotterWidgetPayload } from './trading-blotter/tradingBlotterTypes';
import { TradingV2 } from './trading-v2/TradingV2';
import { TradingV2WidgetPayload } from './trading-v2/types';
import { Trading } from './trading/Trading';
import { TradingWidgetPayload } from './trading/tradingTypes';

//ICONS
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalanceIcon from '@mui/icons-material/Balance';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import NewsPaperIcon from '@mui/icons-material/Newspaper';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SSIDChartIcon from '@mui/icons-material/SsidChart';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import TableChartIcon from '@mui/icons-material/TableChart';
import TimeLineIcon from '@mui/icons-material/Timeline';

type WidgetDef = {
  type: WidgetType;
  displayName: string;
  displayOrder?: number;
  dataGrid: DataGrid | undefined;
  defaultPayload?: WidgetPayload;
  hidden?: boolean;
  Component: (data: Widget) => ReactElement;
  zIndex?: number;
};

type WidgetMenuItem = {
  type: WidgetType;
  displayName: string;
  defaultPayload?: WidgetPayload;
};

export function useWidgets() {
  const { DEFAULT_ALLOWED_PRICING_PRODUCTS, DEFAULT_ALLOWED_PRICING_TENORS, DEFAULT_ALLOWED_TRADING_TENORS } = usePricesDefaultFilters();
  const { isMobile } = useScreenSize();
  const user = useUserContext();

  const widgets: Omit<WidgetDef, 'dataGrid'>[] = useMemo(
    () => [
      {
        type: WidgetType.Pricing,
        displayName: 'Prices',
        displayOrder: 1,
        defaultPayload: {
          selectedColumns: DEFAULT_ALLOWED_PRICING_PRODUCTS,
          selectedRows: [],
          columnsOrder: [],
          userRollingRowSettings: DEFAULT_ALLOWED_PRICING_TENORS,
          isOverrideRolling: false,
        } as PricesWidgetPayload,
        Component: props => <Prices {...props} title="Prices" />,
        hidden: !user?.access_live_data,
        zIndex: 21,
      },
      {
        type: WidgetType.Chart,
        displayName: 'Chart',
        displayOrder: 2,
        Component: props => <Chart {...props} title="Chart" />,
        hidden: !user?.access_live_data || !user?.access_historical_data,
        zIndex: 20,
      },
      {
        type: WidgetType.Settlements,
        displayName: 'Settlements',
        displayOrder: 3,
        defaultPayload: {
          settlementDate: dateService.getPreviousWorkday(),
          selectedSettlementTypes: [SettlementType.Flux],
          selectedColumns: DEFAULT_ALLOWED_PRICING_PRODUCTS,
          selectedRows: [],
          userRollingRowSettings: DEFAULT_ALLOWED_PRICING_TENORS,
          isOverrideRolling: false,
          columnsOrder: [],
        } as SettlementPricesWidgetPayload,
        Component: props => <SettlementPrices {...props} title="Settlements" />,
        hidden: !user?.snapshot_periods.length,
        zIndex: 20,
      },
      {
        type: WidgetType.FloatingChart,
        displayName: 'Floating Chart',
        displayOrder: 4,
        hidden: true,
        Component: props => <FloatingChart {...props} title="Floating Chart" />,
        zIndex: 20,
      },
      {
        type: WidgetType.Trading,
        displayName: 'Trading',
        displayOrder: 5,
        defaultPayload: {
          selectedColumns: DEFAULT_ALLOWED_PRICING_PRODUCTS,
          selectedRows: [],
          columnsOrder: [],
          userRollingRowSettings: DEFAULT_ALLOWED_TRADING_TENORS,
          isOverrideRolling: false,
        } as TradingWidgetPayload,
        Component: props => <Trading {...props} title="Trading" />,
        hidden: isMobile || !user?.allow_trading,
        zIndex: 20,
      },
      {
        type: WidgetType.TradingBlotter,
        displayName: 'Trading Blotter',
        displayOrder: 6,
        defaultPayload: {
          searchTerm: '',
        } as TradingBlotterWidgetPayload,
        Component: props => <TradingBlotter {...props} title="Trading Blotter" />,
        hidden: isMobile || !user?.allow_trading,
        zIndex: 20,
      },
      {
        type: WidgetType.News,
        displayName: 'News',
        displayOrder: 7,
        defaultPayload: {} as NewsWidgetPayload,
        Component: props => <NewsWidget {...props} title="News" />,
        zIndex: 20,
      },
      {
        type: WidgetType.SeasonalChart,
        displayName: 'Seasonal Chart',
        displayOrder: 8,
        Component: props => <SeasonalChart {...props} title="Seasonal Chart" />,
        zIndex: 20,
      },
      {
        type: WidgetType.TradingV2,
        displayName: 'Trading V2',
        displayOrder: 9,
        defaultPayload: {} as TradingV2WidgetPayload,
        Component: props => <TradingV2 {...props} title="" />,
        hidden: isMobile || !user?.allow_trading,
        zIndex: 20,
      },
    ],
    [DEFAULT_ALLOWED_PRICING_PRODUCTS, DEFAULT_ALLOWED_PRICING_TENORS, DEFAULT_ALLOWED_TRADING_TENORS, user]
  );

  const menuItems: WidgetMenuItem[] = useMemo(
    () =>
      widgets
        .filter(w => !w.hidden)
        .slice()
        .sort((a, b) => (a.displayOrder || Infinity) - (b.displayOrder || Infinity))
        .map(def => ({
          type: def.type,
          displayName: def.displayName,
          defaultPayload: def.defaultPayload,
        })),
    [widgets]
  );

  const getOverridenWidgetMinDimensions = useCallback((type: WidgetType, breakpoint: string) => {
    switch (type) {
      case WidgetType.News:
        return { minW: 1, minH: 3 };
      case WidgetType.TradingV2:
        return { w: 3, h: 6, minW: 3, minH: 6, maxW: 4, maxH: 6 };
      case WidgetType.Pricing:
        return { minW: 6, minH: 6 };
      default:
        return {};
    }
  }, []);

  const findWidget = useCallback(
    ({ id, type }: Widget, breakpoint: string, widgetLayout: Layout | undefined, widgetIndex?: number): WidgetDef | undefined => {
      const widget = widgets.find(w => w.type === type);

      if (!widget) {
        logger.error(`Widget type '${type}' does not exist`);
        return;
      }

      const dataGrid = { ...getDataGrid(breakpoint), ...getOverridenWidgetMinDimensions(widget.type, breakpoint), i: id };
      const widgetXPosition = widgetIndex && widgetIndex % 2 !== 0 ? responsiveUtils.getColsFromBreakpoint(breakpoint, layoutColumns) / 2 : 0;

      if (!widgetLayout || isMobile) {
        return {
          ...widget,
          dataGrid: {
            ...dataGrid,
            x: widgetXPosition,
          },
        };
      }

      return {
        ...widget,
        dataGrid: { ...dataGrid, ...widgetLayout },
      };
    },
    [widgets, getOverridenWidgetMinDimensions, isMobile]
  );

  const getWidgetIcon = (type: WidgetType) => {
    switch (type) {
      case WidgetType.Chart:
        return <ShowChartIcon />;
      case WidgetType.FloatingChart:
        return <TimeLineIcon />;
      case WidgetType.News:
        return <NewsPaperIcon />;
      case WidgetType.Settlements:
        return <BalanceIcon />;
      case WidgetType.SeasonalChart:
        return <SSIDChartIcon />;
      case WidgetType.Trading:
        return <MultipleStopIcon />;
      case WidgetType.TradingV2:
        return <SwapHorizIcon />;
      case WidgetType.Pricing:
        return <TableChartIcon />;
      case WidgetType.TradingBlotter:
        return <AccountBalanceIcon />;
      default:
        return null;
    }
  };
  return { menuItems, findWidget, getWidgetIcon };
}
