export const capitalizeFirstLetter = str => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

export const extractURLPageName = (str: string): string => {
  const segments = str
    .split('/')
    .map(segment => (segment.includes('?') ? segment.split('?')[0] : segment))
    .filter(segment => segment);

  return segments.length > 4 ? segments[segments.length - 2] : segments[segments.length - 1];
};
