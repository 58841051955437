import { Handle } from '@shared/protos/routes';
import { useMemo } from 'react';
import { useLocation, useMatches } from 'react-router-dom';

export type Crumb = {
  name?: string;
  active: boolean;
};

export const useBreadcrumbs = () => {
  const matches = useMatches();
  const location = useLocation();

  const crumbs = useMemo<Crumb[]>(
    () =>
      matches
        .map(match => ({ ...match, handle: match.handle as Handle }))
        .filter(({ handle }) => Boolean(handle?.crumbName))
        .map(({ pathname, handle }, index, arr) => ({ name: handle?.crumbName, active: pathname === location.pathname || arr.length - 1 === index })),
    [matches, location]
  );

  return { crumbs, activeCrumb: crumbs.find(c => c.active) };
};
