import { alpha, styled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';

export const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
  backgroundColor: theme.palette.background.lighter,
  border: 'none',
  color: theme.palette.text.secondary,
  fontSize: 11,
  fontWeight: theme.typography.fontWeightBold,
  borderRadius: 0,
  margin: '0 1.5px',
  width: 30,
  height: 30,
  '&[type=button]': {
    outline: `2px solid #3C3F47`,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:focus': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.Mui-selected': {
    outline: `2px solid ${theme.palette.primary.main}`,
    zIndex: 1,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '&.MuiPickersDay-today': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    '&.Mui-selected': {
      outline: `2px solid ${alpha(theme.palette.border, 0.8)}`,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
}));

export default function CustomDay(props) {
  return <StyledPickersDay {...props} />;
}
