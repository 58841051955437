import { useCallback, useMemo } from 'react';
import { useSearchParams as useSearchParamsReact } from 'react-router-dom';

export const useURLSearchParams = (): {
  urlParams: Record<string, any>;
  setURLSearchParam: (key: string, value: string | undefined) => Record<string, any>;
  resetURLSearchParams: (newParams: Record<string, any>) => Record<string, any>;
} => {
  const [searchParams, setSearchParams] = useSearchParamsReact();

  const urlParams = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  const setURLSearchParam = useCallback(
    (key: string, value: string | undefined) => {
      const currentQueryParameters = new URLSearchParams(searchParams);

      if (value) {
        currentQueryParameters.set(key, value);
      } else {
        currentQueryParameters.delete(key);
      }

      setSearchParams(currentQueryParameters);
      return Object.fromEntries(currentQueryParameters);
    },
    [setSearchParams, searchParams]
  );

  const resetURLSearchParams = useCallback(
    (newParams: Record<string, any>) => {
      const newQueryParameters = new URLSearchParams();

      Object.entries(newParams).forEach(([key, value]) => {
        if (value) {
          newQueryParameters.set(key, value);
        }
      });

      setSearchParams(newQueryParameters);
      return Object.fromEntries(newQueryParameters);
    },
    [setSearchParams]
  );

  return useMemo(
    () => ({
      urlParams,
      setURLSearchParam,
      resetURLSearchParams,
    }),
    [urlParams, setURLSearchParam, resetURLSearchParams]
  );
};
