import { Divider, IconButton } from '@mui/material';
import MUIDialog, { DialogProps as MUIDialogProps, dialogClasses } from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { GrowTransition } from '@shared/components/Transitions';
import { useScreenSize } from '@shared/hooks/useScreenSize';

const StyledDialog = styled(MUIDialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    backgroundImage: 'unset',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    padding: '25px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minWidth: '520px',
    },
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
}));

type DialogProps = Pick<MUIDialogProps, 'open' | 'maxWidth'> & {
  children: React.ReactNode;
  title?: string;
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showClose?: boolean;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
};

export const Dialog: React.FC<DialogProps> = ({ children, open, title, maxWidth, onClose, showClose, fullWidth, sx }) => {
  const { isMobile } = useScreenSize();

  return (
    <StyledDialog
      id={`dialog-${title}`}
      fullScreen={isMobile}
      maxWidth={maxWidth}
      scroll="body"
      open={open}
      onClose={onClose}
      TransitionComponent={GrowTransition}
      fullWidth={fullWidth}
      sx={sx}
    >
      <Box display="flex" justifyContent="space-between" flex={1} alignItems="center">
        {title && <StyledTitle>{title}</StyledTitle>}
        {showClose ? (
          <IconButton onClick={onClose} style={{ fontSize: '22px' }}>
            <span className="ri-close-line" />
          </IconButton>
        ) : null}
      </Box>
      <Divider flexItem style={{ marginBottom: 15, minWidth: '100%' }} />
      <Stack spacing={2} height={600}>
        {children}
      </Stack>
    </StyledDialog>
  );
};
