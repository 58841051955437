class PageTitleService {
  private baseTitle = 'Flux';
  private currentTitle = this.baseTitle;

  public resetToBaseTitle() {
    document.title = this.baseTitle;
  }

  public setTitle(pageName: string) {
    this.currentTitle = this.baseTitle === pageName ? this.baseTitle : `${this.baseTitle} - ${pageName}`;
    document.title = this.currentTitle;
  }

  public setTemporaryMessage(message: string) {
    document.title = message;
  }

  public resetTemporaryMessage() {
    document.title = this.currentTitle;
  }
}

const pageTitleService = new PageTitleService();
export { pageTitleService };
