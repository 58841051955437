import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { DatePicker, DatePickerSlotsComponentsProps } from '@mui/x-date-pickers/DatePicker';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers/models';
import { dateService } from '@services/DateService';
import CalendarProvider from '@shared/components/calendar/CalendarProvider';
import { ReadOnlyDateField } from '@shared/components/calendar/ChipInputDateField';
import CustomDay from '@shared/components/calendar/CustomDay';
import CustomLayout from '@shared/components/calendar/CustomLayout';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { isSameDay, isWeekend } from 'date-fns';
import { useState } from 'react';
import { useSettlementPricesSettings } from '../SettlementPricesContext';

type ArrowDatePickerProps = {
  icon: string;
} & React.ComponentProps<typeof StyledIconButton>;

type ReadOnlyDatePickerProps = {
  value?: Date | null;
  onChange: (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
  minDate?: Date;
  maxDate?: Date;
};

const StyledStack = styled(Stack)({
  backgroundColor: '#2D2F33',
  zIndex: 1,
});

const StyledIconButton = styled(IconButton)({
  padding: '0 2px',
  fontSize: '11px',
});

const slotProps: Pick<DatePickerSlotsComponentsProps<any>, 'openPickerButton' | 'popper' | 'desktopTransition'> = {
  openPickerButton: {
    disabled: true,
    sx: {
      display: 'none',
    },
  },
  popper: {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 16],
        },
      },
    ],
  },
  desktopTransition: {
    timeout: 0,
  },
};

function ArrowDatePicker({ icon, ...buttonProps }: ArrowDatePickerProps) {
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return null;
  }

  return (
    <StyledIconButton {...buttonProps}>
      <i className={icon} />
    </StyledIconButton>
  );
}

function ReadOnlyDatePicker(props: ReadOnlyDatePickerProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopper = () => setOpen(true);

  const handleClosePopper = () => setOpen(false);

  return (
    <Stack direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
      <CalendarProvider>
        <DatePicker
          {...props}
          open={open}
          format="dd MMM yy"
          views={['day']}
          onClose={handleClosePopper}
          shouldDisableDate={isWeekend}
          slotProps={{
            ...slotProps,
            field: {
              onClick: handleOpenPopper,
            } as any,
          }}
          slots={{
            layout: CustomLayout,
            day: CustomDay,
            field: ReadOnlyDateField,
          }}
          reduceAnimations
        />
      </CalendarProvider>
    </Stack>
  );
}

const settlementMinDate = new Date('2-Jan-2020');
const currentDate = new Date();
const settlementMaxDate = isWeekend(currentDate) ? dateService.getPreviousWorkday() : currentDate;

export default function SettlementDatePicker() {
  const { settlementDate, handleSettlementDateChange } = useSettlementPricesSettings();

  const handleDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    handleSettlementDateChange(date);
  };

  const handlePreviousDayClick = () => handleSettlementDateChange(dateService.getPreviousWorkday(settlementDate));

  const handleNextDayClick = () => handleSettlementDateChange(dateService.getFutureWorkday(settlementDate));

  const isSelectorDisabled = (dateToCompare: Date) => (settlementDate ? isSameDay(dateToCompare, settlementDate) : true);

  return (
    <StyledStack direction="row" height="100%" alignItems="center">
      <ArrowDatePicker icon="ri-arrow-left-s-line" onClick={handlePreviousDayClick} disabled={isSelectorDisabled(settlementMinDate)} />
      <ReadOnlyDatePicker value={settlementDate} onChange={handleDateChange} minDate={settlementMinDate} maxDate={settlementMaxDate} />
      <ArrowDatePicker icon="ri-arrow-right-s-line" onClick={handleNextDayClick} disabled={isSelectorDisabled(settlementMaxDate)} />
    </StyledStack>
  );
}
