import { SnapshotCallback } from '@protos/snapshots';
import { streamSnapshots } from '@services/context';
import { useEffect, useRef } from 'react';

export const useSnapshotsStreaming = (callback: SnapshotCallback, isReadyToSubscribe: boolean) => {
  const target = useRef<SnapshotCallback>(callback);

  useEffect(() => {
    if (!isReadyToSubscribe) return;

    target.current = callback;
    streamSnapshots.subscribe(callback);

    return () => {
      streamSnapshots.unsubscribe();
    };
  }, [isReadyToSubscribe]);
};
