import { Paper } from '@mui/material';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

export const DagGraph = ({ data }) => {
  const svgRef = useRef<any>(null);

  useEffect(() => {
    const width = 1200;
    const height = 800;

    d3.select(svgRef.current).selectAll('*').remove();

    const fitView = () => {
      const bounds = svg.node().getBBox();
      const fullWidth = svgRef.current?.clientWidth;
      const fullHeight = svgRef.current?.clientHeight;
      const width = bounds.width;
      const height = bounds.height;
      const midX = bounds.x + width / 2;
      const midY = bounds.y + height / 2;

      if (width === 0 || height === 0) return; // nothing to fit

      const scale = 0.85 / Math.max(width / fullWidth, height / fullHeight);
      const translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

      svg.transition().duration(750).call(d3.zoom().transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    };

    const svg = d3.select(svgRef.current).attr('width', '100%').attr('height', '100%');
    const g = svg.append('g');

    const zoom = d3
      .zoom()
      .scaleExtent([0.3, 3])
      .on('zoom', event => {
        g.attr('transform', event.transform);
      });
    svg.call(zoom);

    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrowhead')
      .attr('viewBox', '-0 -5 10 10')
      .attr('refX', 15)
      .attr('refY', 0)
      .attr('orient', 'auto')
      .attr('markerWidth', 5)
      .attr('markerHeight', 5)
      .attr('xoverflow', 'visible')
      .append('svg:path')
      .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
      .attr('fill', '#999')
      .style('stroke', 'none');

    const simulation = d3
      .forceSimulation(data.nodes)
      .force(
        'link',
        d3
          .forceLink(data.links)
          .id((d: any) => d.id)
          .distance(50)
      )
      .force('charge', d3.forceManyBody().strength(-50))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .on('end', fitView);

    const link = g
      .append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(data.links)
      .enter()
      .append('line')
      .attr('stroke', 'gray')
      .attr('stroke-width', 2)
      .attr('marker-end', 'url(#arrowhead)');

    const node = g.append('g').attr('class', 'nodes').selectAll('g').data(data.nodes).enter().append('g');

    node.append('circle').attr('r', 10).attr('fill', 'white');

    node
      .append('text')
      .attr('x', 15)
      .attr('y', 3)
      .text((d: any) => d.label)
      .style('font-size', '11px')
      .style('fill', 'white');

    simulation.on('tick', () => {
      link
        .attr('x1', (d: any) => d.source.x)
        .attr('y1', (d: any) => d.source.y)
        .attr('x2', (d: any) => d.target.x)
        .attr('y2', (d: any) => d.target.y);

      node.attr('transform', (d: any) => `translate(${d.x},${d.y})`);
    });
  }, [data]);

  return (
    <Paper style={{ width: '100%', height: '100%', minHeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <svg ref={svgRef}></svg>
    </Paper>
  );
};
