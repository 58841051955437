import { Autocomplete, AutocompleteRenderInputParams, Stack, Switch, TextField, Typography } from '@mui/material';
import { Filter, FilterType } from '@shared/protos/filter';
import { useEffect, useMemo, useState } from 'react';
import { DateInput } from './DateInput';
import { DateTime } from './DateTime';
import { DateTimeRange } from './DateTimeRange';
import { IconComponent } from './IconComponent';
import { Select } from './Select';

interface FilterComponentProps {
  filter: Filter;
  filterParams: Record<string, any>;
  onFilterChange: (newFilter: Record<string, any>) => void;
}

export const FilterComponent: React.FC<FilterComponentProps> = ({ filter, filterParams, onFilterChange }) => {
  const { type, name, label, options = [] } = filter;

  const [filterValue, setFilterValue] = useState<any>(filterParams[name] || '');
  const sortedOptions = useMemo(() => [...options].sort((a, b) => a.localeCompare(b)), [options]);

  useEffect(() => {
    setFilterValue(filterParams[name] || '');
  }, [filterParams, name]);

  switch (type) {
    case FilterType.AUTOCOMPLETE:
      return (
        <Autocomplete
          key={`blotter-filter-autocomplete-${label}`}
          disablePortal
          onChange={(_, newValue: string | null) => onFilterChange({ [name]: newValue })}
          id={`blotter-filter-autocomplete-${label}`}
          options={sortedOptions}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              size="small"
              label={label}
              sx={{
                minWidth: 170,
                '& .MuiInputBase-input': {
                  fontSize: 11,
                },
              }}
              slotProps={{
                inputLabel: {
                  style: {
                    fontSize: 11,
                  },
                },
              }}
            />
          )}
          popupIcon={<IconComponent name="ri-arrow-down-s-line" />}
          value={filterValue}
          ListboxProps={{ style: { fontSize: 11 } }}
          isOptionEqualToValue={(option, value) => option === value || value === ''}
          getOptionLabel={option => option}
        />
      );
    case FilterType.TEXTFIELD:
      return (
        <TextField
          id="outlined-basic"
          key={`blotter-filter-textfield-${label}`}
          label={label}
          variant="outlined"
          size="small"
          onChange={e => {
            const newValue = e.target.value;
            onFilterChange({ [name]: newValue });
          }}
          value={filterValue}
          sx={{
            minWidth: 250,
            '& .MuiInputBase-input': {
              fontSize: 11,
            },
          }}
          slotProps={{
            inputLabel: {
              style: {
                fontSize: 11,
              },
            },
          }}
        />
      );
    case FilterType.DATETIME:
      return (
        <DateTime
          key={`blotter-filter-datetime-${label}`}
          label={label}
          value={filterValue}
          onChange={newVal => onFilterChange({ [name]: newVal })}
        />
      );
    case FilterType.DATETIMERANGE:
      return (
        <DateTimeRange
          key={`blotter-filter-datetime-range-${label}`}
          value={{
            start: filterParams.start ? new Date(filterParams.start) : undefined,
            end: filterParams.end ? new Date(filterParams.end) : undefined,
          }}
          onChange={({ start, end }) => onFilterChange({ start, end })}
        />
      );
    case FilterType.DATE:
      return (
        <DateInput
          key={`blotter-filter-date-${label}`}
          value={filterValue}
          onChange={newDate => onFilterChange({ [name]: newDate })}
          label={label}
          clearable
        />
      );
    case FilterType.SELECT:
      return (
        <Select
          key={`blotter-filter-select-${label}`}
          options={sortedOptions}
          label={label}
          value={filterValue}
          onChange={newVal => onFilterChange({ [name]: newVal })}
        />
      );
    case FilterType.SWITCH:
      return (
        <Stack direction="row" alignItems="center" key={`blotter-filter-toggle-${label}`}>
          <Switch checked={filterValue === 'true'} size="small" onChange={(_, checked) => onFilterChange({ [name]: checked ? 'true' : 'false' })} />
          <Typography fontSize={11}>{label}</Typography>
        </Stack>
      );
    default:
      return null;
  }
};
