import { Box } from '@mui/material';
import Form from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import theme from '@shared/themes/darkTheme';
import { useTradingHedgerForm } from './useTradingHedgerForm';

const uiSchema = {
  'ui:title': '',
};

export const TradingHedgerParamsForm: React.FC = () => {
  const { formData, formSchema, onFormChange, onFormSubmit } = useTradingHedgerForm();

  if (!formSchema) return null;

  return (
    <Box display="flex" flex={1} sx={{ backgroundColor: theme.palette.background.darker, padding: 2 }}>
      <Form
        schema={formSchema}
        uiSchema={uiSchema}
        formData={formData}
        validator={validator}
        onChange={onFormChange}
        onSubmit={onFormSubmit}
        templates={MuiTheme.templates}
        widgets={MuiTheme.widgets}
        fields={MuiTheme.fields}
      />
    </Box>
  );
};
