import { Stack, Typography } from '@mui/material';
import { IconComponent } from '@shared/components/IconComponent';

interface TradeConfirmationToastProps {
  symbol: string | { front: string; back: string };
  side: string;
  price: string;
  amount: string;
  exchange: string;
}
export const TradeConfirmationToast = ({ symbol, side, price, amount, exchange }: TradeConfirmationToastProps) => {
  const contract =
    typeof symbol === 'string' ? symbol.toLocaleUpperCase() : `${symbol.front.toLocaleUpperCase()} / ${symbol.back.toLocaleUpperCase()}`;
  const orderSide = side.toLocaleLowerCase().includes('buy') ? 'BUY' : 'SELL';

  return (
    <Stack direction="column">
      <Typography variant="subtitle1" style={{ fontWeight: 600, textAlign: 'center', marginBottom: 5 }}>
        <IconComponent name="ri-checkbox-circle-fill" /> Order Filled
      </Typography>
      <Typography style={{ fontWeight: 600 }}>{contract}</Typography>
      <Typography>
        <strong style={{ color: orderSide === 'BUY' ? '#4189E8' : '#CD4B4B' }}>{orderSide}</strong> amount of <strong>{amount}</strong>{' '}
        <strong>@ {price}</strong> via <strong>{exchange.toLocaleLowerCase()}</strong>
      </Typography>
    </Stack>
  );
};
