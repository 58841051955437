import { IconButton, SxProps, Tooltip } from '@mui/material';

interface FluxIconButtonProps {
  Icon: React.ElementType;
  label?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: SxProps;
}

export const FluxIconButton: React.FC<FluxIconButtonProps> = ({ Icon, label, onClick, sx }) => {
  return (
    <Tooltip title={label}>
      <IconButton aria-label={label} onClick={onClick} edge="start" sx={sx}>
        <Icon sx={sx} />
      </IconButton>
    </Tooltip>
  );
};
