import { Product, ProductTenor } from '@shared/protos/product';
import { RollingRowSettings } from './types';

export const getProducts = (userProducts: Product[], includeProducts?: string[]): Product[] => {
  const include = new Set(includeProducts);

  return userProducts.reduce((acc: Product[], product: Product) => {
    let column_symbol = product.symbol;
    if (product.underlying_symbol && product.calendar_type !== 'spread') column_symbol = product.underlying_symbol;
    if (product.calendar_type === 'spread' && product.tenor_frequency === 'quarterly') {
      const tail = product.symbol.substring(product.underlying_symbol.length, product.symbol.length);
      const productName = product.underlying_symbol.substring(0, product.underlying_symbol.length - 1);
      column_symbol = `${productName}${tail}`;
    }

    if (include.has(column_symbol)) acc.push(product);
    return acc;
  }, []);
};

export const getTenorsFromRollingSettings = (tenors: ProductTenor[], settings: RollingRowSettings | undefined): ProductTenor[] => {
  if (settings) {
    const postSettingsTenorsMap = tenors.reduce((acc, tenor) => {
      const existingFrequency = acc[tenor.frequency];
      if (existingFrequency) {
        if (existingFrequency.length < settings[tenor.frequency]) {
          acc[tenor.frequency] = [...existingFrequency, tenor];
        }
      } else if (settings[tenor.frequency] > 0) {
        acc[tenor.frequency] = [tenor];
      }

      return acc;
    }, {});
    return Object.values(postSettingsTenorsMap).flatMap(elem => elem) as ProductTenor[];
  }

  return [];
};

export const getTenors = (tenors: ProductTenor[], includeTenors: string[]): ProductTenor[] =>
  tenors.filter(tenor => new Set(includeTenors).has(tenor.code));

export const getTenorsFirstIndexes = (selectedTenors: ProductTenor[], allTenors: ProductTenor[]) => {
  const firstWeekTenor = allTenors.find(tenor => tenor.frequency === 'weekly');
  const firstMonthTenor = allTenors.find(tenor => tenor.frequency === 'monthly');

  const firstWeekIndex = selectedTenors.findIndex(tenor => tenor.code === firstWeekTenor?.code);
  const firstMonthIndex = selectedTenors.findIndex(tenor => tenor.code === firstMonthTenor?.code);

  return { firstWeekIndex, firstMonthIndex };
};

export const getBalDisplayName = (firstMonthIndex: number, firstWeekIndex: number, index: number, display: string) => {
  if (index === firstWeekIndex) {
    return 'BALWEEK';
  }

  if (index === firstMonthIndex) {
    return 'BALMO';
  }

  return display.toLocaleUpperCase();
};
