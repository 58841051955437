import { useApi } from '@hooks/useApi';
import { Box, Divider, IconButton, Stack, Switch, Typography } from '@mui/material';
import { SearchSymbolResponse } from '@protos/charts';
import { toastifyService } from '@services/ToastifyService';
import LogoImg from '@shared/assets/onyxlogo-white.png';
import { SearchSymbolAutocomplete } from '@shared/components/SearchSymbolAutocomplete';
import { RefObject, useCallback } from 'react';
import { FormulaInput } from './FormulaInput';

interface SeasonalChartToolbarProps {
  selectedFormula: string;
  onQuerySeasonalFormula: (formula: string, symbolsInFormula: string[], hasRollingSymbolInFormula: boolean) => void;
  useFormulas: boolean;
  onFormulasEnabledChange: (enabled: boolean) => void;
  searchSelectedSymbol: SearchSymbolResponse;
  onSearchSelectedSymbolChange: (symbol: SearchSymbolResponse) => void;
  chartRef: RefObject<any>;
  chartContainerRef: RefObject<any>;
}

export const SeasonalChartToolbar = ({
  selectedFormula,
  onQuerySeasonalFormula,
  useFormulas,
  onFormulasEnabledChange,
  searchSelectedSymbol,
  onSearchSelectedSymbolChange,
  chartRef,
  chartContainerRef,
}: SeasonalChartToolbarProps) => {
  const { apiClient } = useApi();

  const onClickScreenshot = useCallback(() => {
    const chart = chartRef.current;
    const chartContainer = chartContainerRef.current;
    if (!chart || !chartContainer) return;

    const dataURL = chart.takeScreenshot().toDataURL();
    if (dataURL) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const watermarkImageElement = new Image();

      canvas.width = chartContainer.clientWidth;
      canvas.height = chartContainer.clientHeight;
      const image = new Image();
      image.onload = () => {
        if (!context) return;

        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        context.globalAlpha = 0.6;
        watermarkImageElement.onload = () => {
          context.drawImage(watermarkImageElement, canvas.width - 75 - 5, canvas.height - 75 - 30, 75, 75);
          context.globalAlpha = 1;
          context.font = '14px Noto Sans';
          context.fillStyle = 'white';
          context.textAlign = 'left';
          context.textBaseline = 'top';
          const contextTitle = useFormulas ? selectedFormula.replaceAll('#', '') : searchSelectedSymbol.symbol;
          context.fillText(`Seasonal Chart for ${contextTitle.toLocaleUpperCase()}`, 70, 10);

          canvas.toBlob(blob => {
            if (!blob) return;

            const item = new ClipboardItem({ 'image/png': blob });
            navigator.clipboard
              .write([item])
              .then(() => {
                toastifyService.showSuccess('Chart copied to clipboard');
              })
              .catch(() => {
                toastifyService.showError('Failed to copy chart to clipboard');
              });
          }, 'image/png');
        };
        watermarkImageElement.src = LogoImg;
      };
      image.src = dataURL;
    }
  }, [searchSelectedSymbol, selectedFormula, useFormulas]);

  if (!apiClient) return;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginLeft={1}>
      {!useFormulas ? (
        <SearchSymbolAutocomplete value={searchSelectedSymbol} onChange={onSearchSelectedSymbolChange} />
      ) : (
        <FormulaInput apiClient={apiClient} selectedFormula={selectedFormula} onQuerySeasonalFormula={onQuerySeasonalFormula} />
      )}
      <Box display="flex" gap={1}>
        <Stack direction="row" alignItems="center" width="max-content">
          <Switch key="formulas-enabled-switch" size="small" checked={useFormulas} onChange={(_, checked) => onFormulasEnabledChange(checked)} />
          <Typography fontSize={11}>Formula</Typography>
        </Stack>
        <Divider orientation="vertical" flexItem sx={{ marginRight: -1 }} />
        <IconButton onClick={onClickScreenshot} style={{ fontSize: '22px' }}>
          <span className="ri-camera-fill" />
        </IconButton>
      </Box>
    </Box>
  );
};
