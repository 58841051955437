import { Box, Typography, alpha, styled } from '@mui/material';

type Props = {
  value: string;
  onRemove: () => void;
};

const PillBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  width: 'fit-content',
  fontSize: '11px',
  display: 'flex',
  alignItems: 'center',
  gap: '0 5px',
  height: 'calc(100% - 2px)',
  margin: '-2px 0',
}));

export const Pill: React.FC<Props> = ({ value, onRemove }) => {
  return (
    <PillBox>
      <Typography fontSize={11}>{value}</Typography>
      <i className="ri-close-line" onClick={onRemove} style={{ cursor: 'pointer' }} />
    </PillBox>
  );
};
