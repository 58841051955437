import { AlertsList } from '@features/admin/alerts/AlertsList';
import { useAdminApi } from '@shared/hooks/useAdminApi';
import { usePageTitle } from '@shared/hooks/usePageTitle';

export const AlertsPage = () => {
  usePageTitle('Admin alerts');

  const { apiClient } = useAdminApi();
  if (!apiClient) return null;

  const alertList = apiClient.alertsLoader();

  return <AlertsList alertsLoader={alertList} />;
};
