import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection, UseDateFieldProps } from '@mui/x-date-pickers';
import { FieldChangeHandler } from '@mui/x-date-pickers/internals';
import { dateService } from '@services/DateService';
import { format, isValid, set } from 'date-fns';
import { CSSProperties, ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

interface ChipInputFieldProps extends UseDateFieldProps<Date>, BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
  inputFormat?: string;
  placeholder?: string;
  style?: CSSProperties;
  onClick?: () => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onChange?: FieldChangeHandler<Date | null, any>;
  error?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

interface ReadOnlyFieldProps extends UseDateFieldProps<Date>, BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
  onClick?: () => void;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.text.secondary, 0.2),
  borderRadius: 5,
  padding: '0 10px',
  caretColor: theme.palette.primary.main,
  '& .MuiInputBase-root, .MuiInputBase-input': {
    maxWidth: 80,
    height: 30,
    padding: 0,
    textAlign: 'center',
    borderRadius: 5,
    '& .MuiIconButton-root': {
      margin: 0,
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
    },
    '> fieldset': {
      border: 'none',
      padding: 0,
    },
  },
}));

export function ReadOnlyDateField(props: ReadOnlyFieldProps) {
  const { value, format: dateFormat, InputProps: { ref } = {}, onClick } = props;

  return (
    <Box onClick={onClick} ref={ref} width="max-content">
      {value ? (
        <Typography fontSize="11px" margin={1}>
          {format(value, dateFormat || 'PP')}
        </Typography>
      ) : null}
    </Box>
  );
}

export default function ChipInputDateField(props: ChipInputFieldProps) {
  const { disabled, inputFormat = 'PP', placeholder, style, InputProps: { ref } = {}, value, onClick, onKeyDown, onChange } = props;
  const [inputValue, setInputValue] = useState<string>(value ? format(value, inputFormat) : '');
  const [inputError, setInputError] = useState(false);
  const [tempTime, setTempTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const newValue = value ? format(value, inputFormat) : '';
    setInputValue(newValue);
  }, [value, inputFormat]);

  const handleInputFocus = () => {
    if (value) {
      setTempTime({
        hours: value.getHours(),
        minutes: value.getMinutes(),
        seconds: value.getSeconds(),
      });
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    let enteredDate = dateService.parseDate(newValue);
    enteredDate = isValid(enteredDate) ? set(enteredDate, tempTime) : enteredDate;

    if (isValid(enteredDate) && isDateWithinRange(enteredDate)) {
      onChange?.(enteredDate, { validationError: false });
      setInputError(false);
    } else {
      setInputError(true);
    }
  };

  const handleInputBlur = () => {
    let enteredDate = dateService.parseDate(inputValue);
    enteredDate = isValid(enteredDate) ? set(enteredDate, tempTime) : enteredDate;

    if (isValid(enteredDate) && isDateWithinRange(enteredDate)) {
      onChange?.(enteredDate, { validationError: false });
      setInputError(false);
    } else {
      onChange?.(null, { validationError: true });
      setInputError(true);
    }
  };

  const isDateWithinRange = (enteredDate: Date) => {
    return dateService.isDateWithinRange(enteredDate, props.minDate, props.maxDate);
  };

  return (
    <StyledTextField
      error={inputError}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      style={style}
      ref={ref}
      inputProps={{
        disabled,
        value: dateService.removeTimeFromDate(inputValue),
        placeholder,
      }}
    />
  );
}
