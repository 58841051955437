import { ReactNode, createContext, useContext, useState } from 'react';

type Props = {
  children: ReactNode;
};

type FloatingChartStateValue = { value: string | undefined; needsRefresh: boolean; symbol?: string } | undefined;

type FloatingChartContext = {
  currentFloatingChartState: FloatingChartStateValue;
  setCurrentFloatingChartState: (chartState: FloatingChartStateValue) => void;
};

const FloatingChartContext = createContext<FloatingChartContext>({ currentFloatingChartState: undefined, setCurrentFloatingChartState: () => {} });
FloatingChartContext.displayName = 'FloatingChartContext';

export default function FloatingChartProvider({ ...props }: Props) {
  const [currentFloatingChartState, setCurrentFloatingChartState] = useState<FloatingChartStateValue>(undefined);

  return <FloatingChartContext.Provider value={{ currentFloatingChartState, setCurrentFloatingChartState }} {...props} />;
}

export function useFloatingChartState(): FloatingChartContext {
  const chartState = useContext(FloatingChartContext);

  if (!chartState) {
    throw new Error('useChartState must be used within ChartProvider');
  }

  return chartState;
}
