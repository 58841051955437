class GridUpdateService {
  public updatedRow = undefined;

  public setUpdatedRow = (row: any) => {
    this.updatedRow = row;
  };
}

const gridUpdateService = new GridUpdateService();
export { gridUpdateService };
