import { useUserContext } from '@shared/contexts/UserContextProvider';
import { ColDef, GetRowIdParams, ITooltipParams } from 'ag-grid-enterprise';
import { useEffect, useMemo } from 'react';
import { Grid } from '../../grid';
import { TENOR_COL_ID } from '../../settlement-prices/utils';
import { usePricesSettings } from '../PricesContext';
import { PricesRow } from '../types';
import { usePricesGrid } from '../usePricesGrid';
import { PicesAnalyticsTooltip } from './PricesAnalyticsTooltip';

function getRowId({ data: { tenorId } }: GetRowIdParams<PricesRow>): string {
  return tenorId;
}

export default function PricesGrid() {
  const user = useUserContext();
  const { columnDefs, columns, rows, filteredSelectedTenors, onColumnMoved, gridRef, productMap, tenorMap } = usePricesSettings();
  const {
    getRowStyle,
    getContextMenuItems,
    onGridCellClicked,
    isExternalFilterPresent,
    doesExternalFilterPass,
    getAllRows,
    selectedRowIds,
    onContextMenuVisibleChange,
  } = usePricesGrid(rows ?? [], filteredSelectedTenors);

  const priceColumns = useMemo(
    () =>
      columns?.reduce((acc, col: ColDef<PricesRow, any>) => {
        if (col.colId !== TENOR_COL_ID) {
          acc.push({
            ...col,
            ...(col.colId && user?.cot_product_groups.includes(productMap[col.colId]?.product_group)
              ? {
                  tooltipValueGetter: (p: ITooltipParams) => p.value,
                  tooltipComponent: PicesAnalyticsTooltip,
                  tooltipComponentParams: { columnId: col.colId, productMap, tenorMap },
                }
              : {}),
          });
        } else {
          acc.push(col);
        }
        return acc;
      }, [] as ColDef<PricesRow, any>[]),
    [columns, user]
  );

  useEffect(() => {
    // After filters have been changed via API, we must ensure that the grid is refreshed;
    // If ´api.onFilterChanged()´ is not called, the grid will not be refreshed.
    if (gridRef?.current) {
      gridRef.current.getGridApi()?.onFilterChanged();
    }
  }, [selectedRowIds]);

  return (
    <Grid<PricesRow>
      ref={gridRef}
      defaultColDef={columnDefs}
      columnDefs={priceColumns}
      rowData={getAllRows()}
      getRowId={getRowId}
      getContextMenuItems={getContextMenuItems}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      onColumnMoved={onColumnMoved}
      suppressRowVirtualisation
      suppressColumnVirtualisation
      getRowStyle={getRowStyle}
      onCellClicked={onGridCellClicked}
      onContextMenuVisibleChanged={onContextMenuVisibleChange}
      tooltipShowDelay={1000}
      tooltipInteraction={true}
      autoSizeStrategy={undefined}
      domLayout="autoHeight"
    />
  );
}
