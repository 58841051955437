import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Stack from '@mui/material/Stack';
import { IconComponent } from '@shared/components/IconComponent';
import Popup from '@shared/components/Popup';
import { useScreenSize } from '@shared/hooks/useScreenSize';

const HELP_MENU_OPTIONS = [
  { href: 'https://wa.me/447490073589', title: 'Whatsapp', icon: 'ri-whatsapp-line' },
  { href: 'mailto:support@flux.live', title: 'Email', icon: 'ri-mail-line' },
  { href: 'tel:442081549880', title: 'Call', icon: 'ri-phone-line' },
];

export const HelpMenuPopup = props => {
  const { isMobile } = useScreenSize();

  return (
    <Popup isMobile={isMobile} offset={[0, 20]} arrow {...props}>
      <Stack direction="column" style={{ marginTop: 0, marginBottom: 0 }}>
        <List>
          {HELP_MENU_OPTIONS.map(({ href, title, icon }) => (
            <ListItem key={href} disablePadding>
              <ListItemButton onClick={props.onClose} href={href}>
                <ListItemIcon style={{ fontSize: 20 }}>
                  <IconComponent name={icon} />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Popup>
  );
};
