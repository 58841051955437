import { CSSProperties } from 'react';

type IconComponentProps = {
  name: string;
  iconStyle?: CSSProperties;
};

export const IconComponent: React.FC<IconComponentProps> = ({ name, iconStyle }) => {
  return <i className={name} style={iconStyle} />;
};
