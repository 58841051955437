import { Typography } from '@mui/material';
import InlineGroup from '@shared/components/InlineGroup';
import MultilineGroup from '@shared/components/MultilineGroup';
import { TextInputReadOnlyBox } from '@shared/components/TextInputReadOnlyBox';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { FlatProduct } from '@shared/protos/product';
import { chunkArray } from '@shared/utils/array';

type Props = {
  specification: FlatProduct;
};

const generateProductSpecInfo = (specification: FlatProduct) => [
  {
    label: 'Holiday calendar',
    value: specification.holiday_calendar,
  },

  {
    label: 'Contract size',
    value: specification.contract_size,
  },

  {
    label: 'Min size',
    value: specification.min_size,
  },
  {
    label: 'Max size',
    value: specification.max_size,
  },
  { label: 'Barrels per metric ton', value: specification.barrels_per_metric_ton },
  { label: 'Barrels per mmbtu', value: specification.barrels_per_mmbtu },
  { label: 'Option Strike', value: specification.option_strike },
  { label: 'Option Type', value: specification.option_type },
  { label: 'Settlement Code', value: specification.settlement.code },
  { label: 'Settlement Source', value: specification.settlement.source },
];

const getInputFields = (specification: FlatProduct) => {
  return generateProductSpecInfo(specification).map((data, idx) =>
    data.value === null || data.value === '' ? null : <TextInputReadOnlyBox label={data.label} value={`${data.value}`} key={`user_info_${idx}`} />
  );
};

export const SpecificationInfo: React.FC<Props> = ({ specification }) => {
  const { isMobile } = useScreenSize();
  const inputs = getInputFields(specification);

  const numberOfColumns = isMobile ? 1 : 6;

  const chunks = chunkArray(inputs, numberOfColumns);

  return (
    <div style={{ margin: '10px 0' }}>
      <Typography variant="h6" gutterBottom>
        Specification
      </Typography>
      <MultilineGroup title="Specification">
        {chunks.map((chunk, idx) => (
          <InlineGroup key={`group_${idx}`}>{chunk}</InlineGroup>
        ))}
      </MultilineGroup>
    </div>
  );
};
