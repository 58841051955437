import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { SxProps, Theme, styled } from '@mui/material/styles';

import { TextField } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';

type StyledUpdownButtonProps = { sizeSmall?: boolean } & ButtonProps;
const UpDownButton = styled(Button, { shouldForwardProp: propName => propName !== 'sizeSmall' })<StyledUpdownButtonProps>(({ theme, sizeSmall }) => ({
  backgroundColor: `#585858`,
  color: theme.palette.text.primary,
  fontSize: sizeSmall ? '7px' : theme.typography.input.fontSize,
  alignItems: 'center',
  borderRadius: 80,
  minWidth: 'unset',
  width: sizeSmall ? '10px' : '23px',
  height: sizeSmall ? '15px' : '23px',
}));

export type NumberInputProps = {
  label?: string;
  value: number | undefined;
  minValue?: number;
  disabled?: boolean;
  onChange: (event: any, value: number | undefined) => void;
  min?: number;
  max?: number;
  sx?: SxProps<Theme>;
  tabIndex?: number;
  error?: boolean;
  allowClearable?: boolean;
  sizeSmall?: boolean;
};

const NumberOnlyRegEx = new RegExp('^[0-9]*$');

export const NumberInput = forwardRef(
  (
    {
      label,
      value,
      min = Number.NEGATIVE_INFINITY,
      max = Number.POSITIVE_INFINITY,
      disabled,
      onChange,
      sx,
      tabIndex,
      error,
      allowClearable,
      sizeSmall = false,
    }: NumberInputProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const [numberValue, setNumberValue] = useState<number | undefined>(value);

    const onInputChange = event => {
      if (!NumberOnlyRegEx.test(event.target.value)) {
        return;
      } else if (event.target.value && min <= +event.target.value && +event.target.value <= max) {
        setNumberValue(+event.target.value);
      } else if (allowClearable && event.target.value === '') {
        setNumberValue(undefined);
      }
    };

    const onArrowButtonClick = (event, suffix: number) => {
      if (min && suffix < 0) {
        const val = Math.max(min, (value ?? 0) + suffix);
        onChange(event, val);
      } else if (max) {
        const val = Math.min(max, (value ?? 0) + suffix);
        onChange(event, val);
      }
    };

    useEffect(() => {
      setNumberValue(value);
    }, [value]);

    useEffect(() => {
      onChange(null, numberValue);
    }, [numberValue]);

    return (
      <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        sx={{
          ...sx,
        }}
      >
        <UpDownButton
          disabled={disabled || value === min}
          onClick={event => onArrowButtonClick(event, -1)}
          tabIndex={-1}
          data-testid="subtract-button"
          sizeSmall={sizeSmall}
        >
          <i className="ri-subtract-fill ri-lg"></i>
        </UpDownButton>
        <TextField
          id="number-input"
          error={error}
          inputRef={ref}
          label={label}
          disabled={disabled}
          variant="outlined"
          autoComplete="off"
          size="small"
          InputProps={{
            inputProps: {
              max,
              min,
              inputMode: 'numeric',
              tabIndex,
              'data-testid': 'number-input',
              ...(sizeSmall
                ? {
                    style: {
                      fontSize: 11,
                      height: 10,
                    },
                  }
                : {}),
            },
          }}
          sx={
            sizeSmall
              ? {
                  width: '100%',
                  '& .MuiInputLabel-root': {
                    fontSize: '11px',
                  },
                }
              : { minWidth: 70, maxWidth: 70 }
          }
          value={numberValue}
          onChange={onInputChange}
          autoFocus={!!ref}
          {...(ref
            ? {
                onFocus: event => event.target.select(),
                onMouseUp: event => event.preventDefault(),
                onMouseDown: () => (ref as any).current?.select(),
              }
            : {})}
        />
        <UpDownButton
          disabled={disabled || value === max}
          onClick={event => onArrowButtonClick(event, 1)}
          tabIndex={-1}
          data-testid="add-button"
          sizeSmall={sizeSmall}
        >
          <i className="ri-add-fill ri-lg"></i>
        </UpDownButton>
      </Box>
    );
  }
);
