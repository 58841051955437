import AppConfig from './Config';

export class AppLoggerService {
  config: AppConfig;

  constructor(config: AppConfig) {
    this.config = config;
  }

  get enabled(): boolean {
    return !this.config.isProd;
  }

  public log(message: string, ...params: any[]): void {
    if (this.enabled) {
      console.log(message, ...params);
    }
  }

  public info(message: string, ...params: any[]): void {
    if (this.enabled) {
      console.info(message, ...params);
    }
  }

  public warn(message: string, ...params: any[]): void {
    if (this.enabled) {
      console.warn(message, ...params);
    }
  }

  public error(message: string, ...params: any[]): void {
    if (this.enabled) {
      console.error(message, ...params);
    }
  }
}
