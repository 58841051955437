import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useStreamProduct } from '@shared/hooks/useStreamProduct';
import { productMaps } from '@shared/protos/product';
import { Ticker, tickerMeta } from '@shared/protos/ticker';
import { CellClickedEvent, ColumnMovedEvent } from 'ag-grid-enterprise';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridRef } from '../../grid';
import { DefaultGridState, GridSettings } from '../../shared/types';
import { getTenors, getTenorsFirstIndexes, getTenorsFromRollingSettings } from '../../shared/utils';
import { TradingRow, TradingWidgetPayload } from '../tradingTypes';
import { mapColumns, mapRows } from '../utils';

const DEFAULT_TRADING_ROLLING_SETTINGS = { daily: 0, weekly: 0, monthly: 0, yearly: 0, quarterly: 0 };

const TRADABLE_PRODUCTS = ['brt'];

export type SelectedTradingProduct = { rowData: TradingRow | undefined; symbol: string | { front: string; back: string } };

type ExtendedTradingGrid = {
  selectedTradingProduct?: SelectedTradingProduct;
  isBidPopover: boolean;
  anchorEl?: any;
};
export type TradingGridState = DefaultGridState & ExtendedTradingGrid;
export type TradingGrid = GridSettings<TradingRow> &
  ExtendedTradingGrid & {
    onCloseClickedCell: () => void;
    onCellClicked: (event: CellClickedEvent<TradingRow>) => void;
    onColumnMoved: (event: ColumnMovedEvent<TradingRow>) => void;
  };

export const useTradingGrid = ({
  selectedColumns = [],
  selectedRows = [],
  userRollingRowSettings = DEFAULT_TRADING_ROLLING_SETTINGS,
  columnsOrder = [],
  isOverrideRolling = false,
}: TradingWidgetPayload): TradingGrid => {
  const gridRef = useRef<GridRef<TradingRow>>(null);
  const { userProducts, tenors } = useUserProductsAndTenorsContext();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [state, setState] = useState<TradingGridState>({
    selectedColIds: selectedColumns,
    selectedRowIds: selectedRows,
    rollingRowSettings: userRollingRowSettings,
    columnsOrder: columnsOrder,
    selectedTradingProduct: undefined,
    isBidPopover: false,
    isOverrideRolling,
  });

  const selectedUserProducts = useMemo(
    () =>
      userProducts.filter(
        product =>
          TRADABLE_PRODUCTS.includes(product.symbol.toLocaleLowerCase()) || TRADABLE_PRODUCTS.includes(product.underlying_symbol.toLocaleLowerCase())
      ),
    [userProducts]
  );
  const viewableProductColumns = useMemo(
    () =>
      selectedUserProducts.filter(
        product => !product.underlying_symbol || (product.calendar_type === 'spread' && product.tenor_frequency !== 'quarterly')
      ),
    [selectedUserProducts]
  );
  const selectedTenors = useMemo(() => getTenors(tenors, state.selectedRowIds), [tenors, state.selectedRowIds]);
  const { firstWeekIndex, firstMonthIndex } = getTenorsFirstIndexes(selectedTenors, tenors);
  const { productMap, tenorMap } = useMemo(() => productMaps(selectedUserProducts, selectedTenors), [selectedUserProducts, selectedTenors]);

  const onColumnMoved = useCallback(({ api }: ColumnMovedEvent<TradingRow>) => {
    setState(prevState => ({
      ...prevState,
      columnsOrder: api.getColumnState().map(({ colId }) => colId),
    }));
  }, []);

  const onCellClicked = useCallback(
    ({ data, colDef, event, rowIndex }: CellClickedEvent<TradingRow>) => {
      const productID = colDef.colId?.split('-')[0];

      if (event?.target && data && productID && colDef.colId?.split('-')[1] !== 'mid' && rowIndex !== 0 && rowIndex !== 1) {
        const product = productMap[productID];
        const tenor = tenorMap[data.tenorId];

        if (product && tenor) {
          let tenorId = data.tenorId;
          if (product.calendar_type === 'spread' && tenor.frequency !== 'quarterly') {
            const index = tenor.spread_code.length / 2;
            const front = tenor.spread_code.slice(0, index);
            const back = tenor.spread_code.slice(index);

            setState(prevState => ({
              ...prevState,
              selectedTradingProduct: {
                rowData: data,
                symbol: { front: `${product.underlying_symbol}${front}`, back: `${product.underlying_symbol}${back}` },
              },
              isBidPopover: colDef.colId?.split('-')[1] === 'bidBtn',
            }));
          } else if (product.calendar_type !== 'spread') {
            if (tenor.frequency === 'quarterly' || tenor.frequency === 'yearly') {
              tenorId = `${tenor.frequency[0].toLocaleLowerCase()}${tenorId}`;
            }
            setState(prevState => ({
              ...prevState,
              selectedTradingProduct: { rowData: data, symbol: `${productID}${tenorId}` },
              isBidPopover: colDef.colId?.split('-')[1] === 'bidBtn',
            }));
          }

          setAnchorEl(event.target);
        }
      }
    },
    [productMap, tenorMap]
  );

  const onCloseClickedCell = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      selectedTradingProduct: undefined,
      isBidPopover: false,
    }));
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    const { rollingRowSettings, isOverrideRolling } = state;

    if (!isOverrideRolling) {
      const newselectedRowIds = getTenorsFromRollingSettings(tenors, rollingRowSettings).map(elem => elem.code);

      setState(prevState => ({
        ...prevState,
        selectedRowIds: newselectedRowIds,
      }));
    }
  }, [tenors]);

  useStreamProduct(
    (ticker: Ticker) => {
      const meta = tickerMeta(ticker, productMap, tenorMap);
      if (!meta || !gridRef.current) return;
      const gridApi = gridRef.current?.getGridApi();
      const { tenor, productSymbolRoot } = meta;

      const rowNode = gridApi?.getRowNode(tenor.code);
      const midColId = gridApi?.getColumnDef(`${productSymbolRoot}-mid`)?.colId;
      if (!rowNode || !midColId) return;

      rowNode.setDataValue(midColId, ticker.mid);
    },
    selectedUserProducts.map(product => product.symbol)
  );

  return {
    ...state,
    columnDefs: {
      lockPinned: true,
    },
    anchorEl,
    rows: mapRows(selectedTenors, viewableProductColumns, firstMonthIndex, firstWeekIndex),
    columns: mapColumns(viewableProductColumns, state.selectedColIds),
    gridRef,
    onColumnMoved,
    onCellClicked,
    onCloseClickedCell,
    filteredSelectedTenors: [],
    selectedUserProducts: [],
  };
};
