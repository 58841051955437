import { OUTLIER_COLUMNS, OUTLIER_FILTERS, Outlier } from '@features/admin/outliers/outliersProtos';
import { useOutlierActionsColumns } from '@features/admin/outliers/useOutlierActionsColumns';
import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Divider } from '@mui/material';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { SimpleChart, SimpleChartProps } from '@shared/components/simple-chart/SimpleChart';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import theme from '@shared/themes/darkTheme';
import { ResolutionString } from '@tradingview/types';
import { GridApi, RowClickedEvent } from 'ag-grid-enterprise';
import { addHours, addMinutes } from 'date-fns';
import { BarData, Time } from 'lightweight-charts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
  enableCellChangeFlash: true,
  width: 150,
};

const getPaddedStartEndDates = (start: Date, end: Date) => {
  const paddedStart = addMinutes(start, -1440);
  const paddedEnd = addMinutes(end, 1440);

  return { fromDate: paddedStart.toISOString(), toDate: paddedEnd.toISOString() };
};

export const OutlierContractAdminPage = () => {
  usePageTitle('Outliers Contract - Admin');

  let blotterApi: GridApi<Outlier>;
  const { contractSymbol } = useParams();
  const { apiClient } = useAdminApi();
  const dataLoader = apiClient?.outliersLoader(contractSymbol);
  const COLUMNS = useOutlierActionsColumns(OUTLIER_COLUMNS);

  const [simpleChartInfo, setSimpleChartInfo] = useState<SimpleChartProps>({ symbol: contractSymbol, period: '1m' as ResolutionString });

  const onOutlierRowClicked = (event: RowClickedEvent<Outlier, any>) => {
    if (event.data) {
      const { start, end, period } = event.data;
      const { fromDate, toDate } = getPaddedStartEndDates(new Date(start), new Date(end));

      setSimpleChartInfo({ ...simpleChartInfo, start: fromDate, end: toDate, period: period as ResolutionString });
    }
  };

  const onDailyChartClick = (datapoint: BarData<Time> | undefined) => {
    if (datapoint === undefined) return;

    const { time } = datapoint;
    const dateTime = new Date(+time * 1000);

    const { fromDate, toDate } = getPaddedStartEndDates(dateTime, addHours(dateTime, 24));

    setSimpleChartInfo({ ...simpleChartInfo, start: fromDate, end: toDate });
  };

  useEffect(() => {
    setSimpleChartInfo({ ...simpleChartInfo, symbol: contractSymbol });
  }, [contractSymbol]);

  if (!contractSymbol || !dataLoader) return null;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <BlotterWidget
        getGridApi={gridApi => (blotterApi = gridApi)}
        blotterTitle={contractSymbol.toLocaleUpperCase()}
        dataLoader={dataLoader}
        columnDefs={COLUMNS}
        filters={OUTLIER_FILTERS}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        disableSearchFilter
        onRowClicked={onOutlierRowClicked}
        onRefreshClicked={() => window.location.reload()}
        maxHeight={450}
      />
      <Divider style={{ height: 2, backgroundColor: theme.palette.background.lighter }} />
      <SimpleChart {...simpleChartInfo} />
      <Divider style={{ height: 2, backgroundColor: theme.palette.background.lighter }} />
      <SimpleChart symbol={contractSymbol} period={'1d' as ResolutionString} onClick={onDailyChartClick} />
    </Box>
  );
};
