import { Switch, styled } from '@mui/material';
import KillSwitchIcon from '@shared/assets/killSwitch.png';

export const KillSwitchButton = styled(Switch)(({ theme, checked }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#f39c12',
        borderRadius: 20 / 2,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: checked ? '#f39c12' : '#ecf0f1',
    width: 32,
    height: 32,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '90%',
    backgroundImage: `url(${KillSwitchIcon})`,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#ecf0f1',
    borderRadius: 20 / 2,
  },
}));
