import { TradeConfirmationToast } from '@features/dashboard/widgets/trading/TradeConfirmationToast';
import { TradeErrorToast } from '@features/dashboard/widgets/trading/TradeErrorToast';
import { Typography } from '@mui/material';
import { OrderResponse } from '@shared/protos/trading';
import _ from 'lodash';
import { Id, toast } from 'react-toastify';

class ToastifyService {
  private _unauthorizedToastId: Id = 'unauthorized-error';

  public hideUnauthorizedError(): void {
    toast.dismiss(this._unauthorizedToastId);
  }

  public showError(error: any): void {
    const message = this.getErrorMessage(error);

    if (message === null) {
      return;
    }

    toast.error(message);
  }

  public showSuccess(message: string): void {
    toast.success(<Typography>{message}</Typography>, {
      position: 'top-center',
      autoClose: 1500,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
    });
  }

  public showTradeConfirmation(data: OrderResponse): void {
    const { error, message } = data;
    if (!error && !message.Message) {
      const { Order } = message;
      const { symbol, side, executed_avg_price, executed_amount, exchange } = Order;
      toast.success(
        <TradeConfirmationToast side={side} price={executed_avg_price || ''} amount={executed_amount || ''} exchange={exchange} symbol={symbol} />,
        {
          position: 'top-center',
          autoClose: 3000,
          closeButton: false,
          closeOnClick: false,
          draggable: false,
          className: 'flux-trade-confirmation',
          progressClassName: 'flux-trade-confirmation-progress',
        }
      );
    } else if (error && message.Message) {
      toast.error(<TradeErrorToast message={message.Message ?? 'Oops, unable to place trade'} />, {
        position: 'top-center',
        autoClose: 3000,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        className: 'flux-trade-unfilled',
        progressClassName: 'flux-trade-unfilled-progress',
      });
    }
  }

  public showAlertDismissedConfirmation(): void {
    toast.success(<Typography>Alert has been dismissed</Typography>, {
      position: 'top-center',
      autoClose: 1500,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
    });
  }

  public showErrorMessage(message: string): void {
    toast.error(message, { autoClose: 5000, hideProgressBar: true, toastId: 'error-toast' });
  }

  private getErrorMessage(error: any): string | null {
    if (error?.name === 'Cancel') {
      return null;
    }

    const defaultMessage = 'Sorry, something went wrong';

    if (!error) {
      return defaultMessage;
    }

    if (error.status === 401 || error.status === 422) {
      return null;
    }

    if (error.message && _.isString(error.message)) {
      return error.message;
    }

    if (_.isString(error)) {
      return error;
    }

    if (error.response) {
      if (error.response.title) {
        return error.response.title;
      } else if (_.isString(error.response)) {
        return error.response;
      } else if (error.response.errors) {
        const apiErrors = Object.values(error.response.errors);
        if (apiErrors.length > 0) {
          return apiErrors.join(',');
        }
      }
    }

    return defaultMessage;
  }
}

const toastifyService = new ToastifyService();
export { toastifyService };
