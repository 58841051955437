import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Stack, styled } from '@mui/material';
import { LadderSettings } from '@protos/ladders';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { AG_GRID_LICENCE_KEY } from '@shared/utils/grid';

import { Order, Side } from '@protos/trading';
import { ColDef, GridApi, GridReadyEvent, LicenseManager, RowClassParams, ValueFormatterParams, ValueParserParams } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLaddersApi } from '../../hooks/useLaddersApi';
import { OrderInputCellEditor } from '../OrderInputCellEditor';
import { OrderInputCellRenderer } from '../OrderInputCellRenderer';
import { NonSpreadSettings } from './NonSpreadSettings';

export const TENOR_COLUMN: ColDef<any> = {
  headerName: '',
  field: 'name',
  width: 100,
  sortable: false,
  pinned: 'left',
  lockPosition: 'left',
  cellClass: 'ag-tenor-column',
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 26px)',
    height: 'calc(100vh - 250px)',
  },
}));

const isJuneDecRow = (params: RowClassParams<any, any>) => params.data.name.includes('JUN') || params.data.name.includes('DEC');

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

interface OCLGridProps {
  getGridApi?: (api: GridApi) => void;
  getNonSpreadSettings?: (settings: LadderSettings) => void;
  selectedAccount: string;
}

export const OCLGrid = ({ getGridApi, getNonSpreadSettings, selectedAccount }: OCLGridProps) => {
  const { tenors } = useUserProductsAndTenorsContext();
  const { fetchLaddersForContracts } = useLaddersApi();
  const { urlParams } = useURLSearchParams();
  const gridApiRef = useRef<GridApi | null>(null);

  const [nonSpreadSettings, setNonSpreadSettings] = useState<LadderSettings>({
    symbol: urlParams.symbol || '',
    validity: Number(urlParams.validity) || 10,
    size: Number(urlParams.size) || 10,
  });
  const [ladderData, setLadderData] = useState<Order[]>([]);

  const first24MonthlyTenors = useMemo(() => tenors.filter(tenor => tenor.frequency === 'monthly').slice(0, 24), [tenors]);
  const allVisibleTenors = useMemo(() => first24MonthlyTenors.slice(1, first24MonthlyTenors.length - 1), [first24MonthlyTenors]);
  const transformedRowData = useMemo(
    () =>
      allVisibleTenors.map(tenor => {
        const contractSymbol = `${nonSpreadSettings.symbol}${tenor.code}`;
        const foundLadder = ladderData.filter(ladder => ladder.symbol === contractSymbol);
        const bid = foundLadder?.find(order => order.side === Side.BUY)?.price;
        const offer = foundLadder?.find(order => order.side === Side.SELL)?.price;

        return {
          id: tenor.code,
          name: tenor.display.toLocaleUpperCase(),
          symbol: nonSpreadSettings.symbol,
          validity: nonSpreadSettings.validity,
          size: nonSpreadSettings.size.toString(),
          value: { bid: bid ?? '', offer: offer ?? '' },
          account_id: selectedAccount,
        };
      }),
    [allVisibleTenors, nonSpreadSettings, ladderData, selectedAccount]
  );
  const allColumns = useMemo(
    () => [
      TENOR_COLUMN,
      {
        headerName: '',
        field: 'value',
        sortable: false,
        minWidth: 120,
        width: 120,
        editable: () => true,
        cellEditor: OrderInputCellEditor,
        cellRenderer: OrderInputCellRenderer,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            return params.value;
          }
        },
        valueParser: (params: ValueParserParams) => {
          if (params.newValue) {
            return { bid: params.newValue, offer: params.newValue };
          }

          return { bid: null, offer: null };
        },
      },
    ],
    []
  );

  const onCellFocused = useCallback(event => {
    if (!!event.column && event.rowIndex !== null) {
      const focusedCell = event.api.getFocusedCell();
      const currentlyEditingCell = event.api.getEditingCells()[0];

      const isEditing =
        focusedCell?.rowIndex === currentlyEditingCell?.rowIndex && focusedCell?.column.getColId() === currentlyEditingCell?.column.getColId();

      if (!isEditing) {
        const columnDef = event.column.getColDef();
        if (columnDef?.editable) {
          event.api.startEditingCell({
            rowIndex: event.rowIndex,
            colKey: event.column.getColId(),
          });
        }
      }
    }
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    getGridApi?.(params.api);
  }, []);

  const getRowStyle = useCallback(
    (params: RowClassParams<any, any>) => {
      if (isJuneDecRow(params)) {
        return { borderBottom: '1px solid grey', borderTop: '1px solid grey' };
      }

      return undefined;
    },
    [isJuneDecRow]
  );

  useEffect(() => {
    if (!nonSpreadSettings?.symbol) return;

    const debounceTimer = setTimeout(() => {
      getNonSpreadSettings?.(nonSpreadSettings);
      fetchLaddersForContracts([nonSpreadSettings.symbol]).then(allLadders => {
        setLadderData(allLadders);
      });
    }, 250);

    return () => clearTimeout(debounceTimer);
  }, [nonSpreadSettings, fetchLaddersForContracts, getNonSpreadSettings]);

  if (!nonSpreadSettings) return null;

  return (
    <Stack display="flex" height="100%" gap={1}>
      <NonSpreadSettings nonSpreadSettings={nonSpreadSettings} setNonSpreadSettings={setNonSpreadSettings} />
      <StyledBox className="ag-theme-balham-dark ocl-grid">
        <AgGridReact
          autoSizeStrategy={{ type: 'fitCellContents', skipHeader: false }}
          defaultColDef={{ suppressKeyboardEvent: params => params.editing, suppressHeaderMenuButton: true }}
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          rowData={transformedRowData}
          tooltipShowDelay={0}
          onGridReady={onGridReady}
          onCellFocused={onCellFocused}
          domLayout="autoHeight"
          containerStyle={{ height: '100%', width: '100%' }}
          getRowId={({ data }): string => data?.id?.toString()}
          columnHoverHighlight={false}
          getRowStyle={getRowStyle}
          columnDefs={allColumns}
        />
      </StyledBox>
    </Stack>
  );
};
