import AppErrorBoundary from '@features/common/AppErrorBoundary';
import PageLoadProgress from '@shared/components/PageLoadProgress';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppRoutes } from 'src/AppRoutes';

export default function App() {
  return (
    <AppErrorBoundary>
      <Suspense fallback={<PageLoadProgress />}>
        <AppRoutes />
      </Suspense>
      <ToastContainer
        className="flux-notification-container"
        toastClassName="flux-notification-toast"
        bodyClassName="flux-notification-body"
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        icon={false}
        theme="dark"
        pauseOnFocusLoss
        pauseOnHover
        draggable
        style={{ padding: 0 }}
      />
    </AppErrorBoundary>
  );
}
