import { useStreamOrders } from '@hooks/useStreamOrders';
import { Order, OrderState, OrderType } from '@protos/trading';
import { priceFormatterService } from '@services/PriceFormatterService';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { GridApi } from 'ag-grid-enterprise';
import React, { useCallback, useRef, useState } from 'react';
import { ALL_ORDERS_GRID_COLUMNS } from '../constants/AllOrdersGridConsts';
import { useLaddersApi } from '../hooks/useLaddersApi';

const formatOrder = (order: Order) => ({
  ...order,
  amount: priceFormatterService.removeTrailZeros(order.amount),
  executed_amount: order.executed_amount ? priceFormatterService.removeTrailZeros(order.executed_amount) : '',
  price: order.price ? priceFormatterService.removeTrailZeros(order.price) : '',
  executed_avg_price: order.executed_avg_price ? priceFormatterService.removeTrailZeros(order.executed_avg_price) : '',
});

interface AllOrdersGridProps {
  selectedAccount: string;
}

export const AllOrdersGrid = React.memo(({ selectedAccount }: AllOrdersGridProps) => {
  const { getAllOrdersLoader } = useLaddersApi();

  const [isReadyToSubscribe, setIsReadyToSubscribe] = useState<boolean>(false);
  const blotterApiRef = useRef<GridApi<Order> | null>(null);

  const dataLoader = getAllOrdersLoader();

  const onGetGridApi = useCallback((gridApi: GridApi<Order>) => {
    blotterApiRef.current = gridApi;
  }, []);

  const onDataRendered = useCallback(() => {
    setIsReadyToSubscribe(true);
  }, []);

  const allOrdersDataTransformer = useCallback((data: Order[]) => data.filter(order => order.account_id === selectedAccount), [selectedAccount]);

  const onStreamOrder = useCallback((order: Order) => {
    const blotterApi = blotterApiRef.current;
    if (!blotterApi || order.order_type !== OrderType.LIMIT_MAKER) return;

    const isRowInGrid = !!blotterApi.getRowNode(order.id.toString());
    if (isRowInGrid) {
      const transformedOrder = formatOrder(order);
      if (transformedOrder.order_state === OrderState.EXPIRED) {
        blotterApi.applyTransactionAsync({ update: [transformedOrder] });
      }
      return;
    }

    const transformedOrder = formatOrder(order);

    blotterApi.applyTransactionAsync({ add: [transformedOrder] });

    const newRowNode = blotterApi.getRowNode(order.id.toString());
    if (newRowNode) {
      blotterApi.flashCells({ rowNodes: [newRowNode], fadeDuration: 2000 });
    }
  }, []);

  useStreamOrders(onStreamOrder, isReadyToSubscribe);

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      getRowId={params => params.data?.id.toString()}
      getGridApi={onGetGridApi}
      dataLoader={dataLoader}
      columnDefs={ALL_ORDERS_GRID_COLUMNS}
      maxHeight={500}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      onFirstDataRendered={onDataRendered}
      disableURLSearchParams
      dataTransformer={allOrdersDataTransformer}
    />
  );
});
