import { Box, Button, ButtonProps, Typography, styled } from '@mui/material';
import { priceFormatterService } from '@services/PriceFormatterService';
import { streamRFQ } from '@services/context';
import { useCallback } from 'react';
import { useTradingContext } from './TradingContext';
import { buyBlue, sellRed } from './constants';

interface TradingButtonsProps {
  rfqSubscriptionSymbol: string;
  isDisabled: boolean;
  tradingAccount: string;
}

type TradingButtonProps = { isBuy?: boolean } & ButtonProps;
const TradingButton = styled(Button, { shouldForwardProp: propName => propName !== 'isBuy' })<TradingButtonProps>(({ theme, isBuy }) => ({
  borderRadius: 5,
  width: '100%',
  backgroundColor: isBuy ? buyBlue : sellRed,
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  height: 45,
  marginBottom: 5,
  transition: 'background-color 0.3s ease, transform 0.3s ease',

  '&:hover': {
    backgroundColor: isBuy ? '#6096eb !important' : '#c96565 !important',
    transform: 'translateY(5%)',
    cursor: 'pointer',
  },
}));

export const TradingButtons: React.FC<TradingButtonsProps> = ({ rfqSubscriptionSymbol, isDisabled, tradingAccount }) => {
  const { prices, isLoadingPrices } = useTradingContext();

  const onPlaceTrade = useCallback(
    (key: 'buy' | 'sell') => {
      if (!rfqSubscriptionSymbol) return;

      const [front, back, size] = rfqSubscriptionSymbol.split('-');
      const isSpreadSymbol = rfqSubscriptionSymbol.split('-').length === 3 && front && back;
      const tradeAskPrice = prices.ask?.price.toString();
      const tradeBidPrice = prices.bid?.price.toString();
      if (!tradeAskPrice || !tradeBidPrice) return;

      streamRFQ.placeOrder({
        symbol: isSpreadSymbol ? { front, back } : rfqSubscriptionSymbol.split('-')[0],
        amount: isSpreadSymbol ? size.toString() : rfqSubscriptionSymbol.split('-')[1].toString(),
        side: key,
        price: key === 'buy' ? tradeAskPrice : tradeBidPrice,
        timestamp_millis: Date.now(),
        // exchange: selectedExchange,
        account_id: tradingAccount,
      });
    },
    [prices, rfqSubscriptionSymbol, tradingAccount]
  );

  return rfqSubscriptionSymbol ? (
    <Box display="flex" gap={1} width="100%" justifyContent="center">
      <TradingButton
        key="sell"
        variant="contained"
        onClick={() => onPlaceTrade('sell')}
        tabIndex={2}
        disabled={isLoadingPrices || isDisabled || !prices.bid}
        data-testid="place-order-button"
      >
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
          Sell
        </Typography>

        <Typography variant="body2" style={{ fontSize: 11, fontWeight: 800 }} tabIndex={-1}>
          {prices.bid ? priceFormatterService.formatProductPrice(prices.bid.price) : 'N/A'}
        </Typography>
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0 }} tabIndex={-1}>
          {prices.bid ? `${prices.bid.amount} Lots` : ''}
        </Typography>
      </TradingButton>
      <Box
        style={{
          fontSize: 9,
          position: 'absolute',
          color: '#585858',
          backgroundColor: '#FFFFFF',
          borderRadius: 3,
          alignSelf: 'end',
          zIndex: 1,
          paddingLeft: 1,
          paddingRight: 1,
          display: 'flex',
          alignItems: 'center',
          maxHeight: 13,
        }}
      >
        {prices.ask && prices.bid ? `${(Number.parseFloat(prices.ask?.price) - Number.parseFloat(prices.bid?.price)).toFixed(3)}` : 'N/A'}
      </Box>
      <TradingButton
        key="buy"
        variant="contained"
        isBuy
        onClick={() => onPlaceTrade('buy')}
        tabIndex={2}
        disabled={isLoadingPrices || isDisabled || !prices.ask}
        data-testid="place-order-button"
      >
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
          Buy
        </Typography>
        <Typography variant="body2" style={{ fontSize: 11, fontWeight: 800 }} tabIndex={-1}>
          {prices.ask ? priceFormatterService.formatProductPrice(prices.ask.price) : 'N/A'}
        </Typography>
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0 }} tabIndex={-1}>
          {prices.ask ? `${prices.ask.amount} Lots` : ''}
        </Typography>
      </TradingButton>
    </Box>
  ) : null;
};
