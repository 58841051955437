import { Box, Card, useMediaQuery } from '@mui/material';
import { Snapshot } from '@protos/snapshots';
import { priceFormatterService } from '@services/PriceFormatterService';
import theme from '@shared/themes/darkTheme';
import { TradingVarCardContent } from './TradingVarCardContent';

const getVarianceBG = (number: string) => {
  const percentage = +number.replace('%', '');

  switch (true) {
    case percentage >= 60 && percentage < 80:
      return '#EFC94C';
    case percentage >= 80 && percentage < 100:
      return '#E27A3F';
    case percentage >= 100:
      return '#DF4949';
    default:
      return 'none';
  }
};

type Props = {
  snapshot: Snapshot | undefined;
};

export const TradingRiskKillSwitch: React.FC<Props> = ({ snapshot }) => {
  return (
    <Box display="flex" flex={1} minHeight={useMediaQuery(theme.breakpoints.down('md')) ? 'auto' : 130}>
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          bgcolor: snapshot
            ? getVarianceBG(priceFormatterService.formatVariancePercentage(snapshot.json_data.var, snapshot.json_data.max_var))
            : 'none',
          borderRadius: 0,
          height: useMediaQuery(theme.breakpoints.down('md')) ? 'fit-content' : 'auto',
        }}
      >
        <TradingVarCardContent
          header="VaR %"
          value={snapshot ? priceFormatterService.formatVariancePercentage(snapshot.json_data.var ?? 0, snapshot.json_data.max_var ?? 0) : undefined}
        />
      </Card>
      <Card variant="outlined" sx={{ width: '100%', borderRadius: 0, height: useMediaQuery(theme.breakpoints.down('md')) ? 'fit-content' : 'auto' }}>
        <TradingVarCardContent
          header="Max VaR"
          value={snapshot ? priceFormatterService.formatVariance(snapshot.json_data.max_var ?? 0) : undefined}
        />
      </Card>
      <Card variant="outlined" sx={{ width: '100%', borderRadius: 0, height: useMediaQuery(theme.breakpoints.down('md')) ? 'fit-content' : 'auto' }}>
        <TradingVarCardContent header="VaR" value={snapshot ? priceFormatterService.formatVariance(snapshot.json_data.var ?? 0) : undefined} />
      </Card>
    </Box>
  );
};
