import { Box, Divider, alpha, styled } from '@mui/material';
import { convertName } from '../utils';
import { Header } from './Header';

type UserInfoListProps = {
  list: string[];
  header: string;
  showDivider?: boolean;
  helperText?: string;
};

export const UserInfoList: React.FC<UserInfoListProps> = ({ list, header, showDivider = false, helperText }) => {
  return (
    <div>
      <Header header={header} sx={{ marginBottom: '20px' }} helperText={helperText} />
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {list.map((value, idx) => (
          <ProductBox value={convertName(value)} key={idx} />
        ))}
      </div>
      {showDivider && <Divider sx={{ margin: '20px 0' }} />}
    </div>
  );
};

type ProductBoxProps = {
  value: string;
};

const ProductInfoBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  width: 'fit-content',
  fontSize: '14px',
}));

const ProductBox: React.FC<ProductBoxProps> = ({ value }) => {
  return <ProductInfoBox>{value}</ProductInfoBox>;
};
