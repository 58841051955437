import { IconButton, Tooltip } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { toLocaleISOString } from '@utils/date';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import gb from 'date-fns/locale/en-GB';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface DateTimeProps {
  label: string;
  onChange: (newValue: string | undefined) => void;
  value: string | number | Date | undefined;
  minDateTime?: Date;
  maxDateTime?: Date;
  useUtcTime?: boolean;
  showResetButton?: boolean;
  clearable?: boolean;
}

export const DateTime = ({ label, onChange, value, minDateTime, maxDateTime, useUtcTime, showResetButton, clearable = false }: DateTimeProps) => {
  const [dateTimeValue, setDateTimeValue] = useState<Date | null>(null);

  const onDateTimeChange = useCallback(
    debounce((newValue: Date | null) => {
      if (newValue) {
        setDateTimeValue(newValue);
        if (useUtcTime) {
          const utcDateTime = zonedTimeToUtc(newValue, 'UTC');
          onChange(utcDateTime.toISOString());
        } else {
          onChange(toLocaleISOString(newValue));
        }
      } else if (clearable) {
        onChange(undefined);
      }
    }, 500),
    [onChange, useUtcTime, clearable]
  );

  const onResetDateTimeToNow = () => {
    const now = new Date();
    setDateTimeValue(now);
    if (useUtcTime) {
      onChange(now.toISOString());
    } else {
      onChange(toLocaleISOString(now));
    }
  };

  useEffect(() => {
    if (value) {
      if (useUtcTime) {
        const utcDateTime = utcToZonedTime(new Date(value), 'UTC');
        setDateTimeValue(utcDateTime);
      } else {
        setDateTimeValue(new Date(value));
      }
    }
  }, [value, useUtcTime]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={gb}>
        <DateTimePicker
          label={label}
          value={dateTimeValue}
          onChange={onDateTimeChange}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
          minDateTime={minDateTime ? new Date(minDateTime) : undefined}
          maxDateTime={maxDateTime ? new Date(maxDateTime) : undefined}
          slotProps={{
            field: { clearable },
            textField: {
              size: 'small',
              sx: {
                '& .MuiInputBase-input': {
                  fontSize: 11,
                },
              },
              InputLabelProps: { style: { fontSize: 11 } },
            },
          }}
        />
      </LocalizationProvider>
      {showResetButton && (
        <Tooltip title="Reset to now" arrow>
          <IconButton style={{ margin: 0, padding: 0, fontSize: 22 }} onClick={onResetDateTimeToNow}>
            <span className="ri-restart-line" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
