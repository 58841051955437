import { useAdminApi } from '@hooks/useAdminApi';
import { RJSFSchema } from '@rjsf/utils';
import { toastifyService } from '@services/ToastifyService';
import { useCallback, useEffect, useState } from 'react';
import { transformParams } from '../../tradingAdminUtils';

export const useTradingHedgerForm = () => {
  const { apiClient } = useAdminApi();

  const [formSchema, setFormSchema] = useState<RJSFSchema>();
  const [formData, setFormData] = useState<Record<string, any>>();

  const onFormChange = useCallback((data: Record<string, any>) => {
    const { formData } = data;
    if (!formData) return;

    setFormData(formData);
  }, []);

  const onFormSubmit = useCallback(async () => {
    if (!apiClient || !formData) return;

    try {
      const response = await apiClient?.updateTradingHedgerParams(formData);
      if (response) toastifyService.showSuccess('Hedger params updated successfully');
    } catch (error: any) {
      const errorMessage = error.data.detail[0].msg || 'Failed to update hedger params';
      toastifyService.showError(errorMessage);
    }
  }, [apiClient, formData]);

  useEffect(() => {
    const fetchTradingHedgerParamsSchema = async () => {
      const params = await apiClient?.getTradingParamsSchema();
      if (!params) return;

      const { components } = params;
      const { schemas } = components;
      if (!schemas['HedgerParams-Output']) return;

      const transformedParams = transformParams(schemas['HedgerParams-Output'], schemas);
      setFormSchema(transformedParams);
    };

    const fetchCurrentHedgerParams = async () => {
      const hedgerParams = await apiClient?.getTradingHedgerParams();
      if (!hedgerParams) return;

      const { params } = hedgerParams;
      setFormData(params);
    };

    fetchTradingHedgerParamsSchema();
    fetchCurrentHedgerParams();
  }, [apiClient]);

  return { formSchema, formData, onFormChange, onFormSubmit };
};
