import { useScreenSize } from '@shared/hooks/useScreenSize';
import { ReactElement } from 'react';
import DeleteButton from './DeleteButton';
import { SettingsMenu } from './SettingsMenu';

export type SettingsMenuProps = {
  id: string;
  title?: string;
  children?: ReactElement;
  widgetId: string;
  onWidgetClose?: () => void;
};

export default function Settings(props: SettingsMenuProps) {
  const { isMobile } = useScreenSize();
  const { widgetId, onWidgetClose } = props;

  return !isMobile ? <SettingsMenu {...props} /> : <DeleteButton id={widgetId} variant="link" onWidgetClose={onWidgetClose} />;
}
