import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function CalendarProvider({ children }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ monthAndYear: 'MMM yyyy' }}>
      {children}
    </LocalizationProvider>
  );
}
