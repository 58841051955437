import theme from '@shared/themes/darkTheme';

interface CellRendererActionButtonProps {
  onClick: (actionName: string) => void;
  label: string;
  backgroundColor?: string;
  textColor?: string;
  isDisabled?: boolean;
}

export const CellRendererActionButton = ({ onClick, label, backgroundColor, isDisabled, textColor }: CellRendererActionButtonProps) => {
  return (
    <button
      style={{
        backgroundColor: isDisabled ? theme.palette.action.disabled : backgroundColor,
        ...(isDisabled ? { cursor: 'not-allowed', opacity: theme.palette.action.disabledOpacity } : { cursor: 'pointer' }),
        border: 'none',
        textAlign: 'center',
        display: 'inline-block',
        borderRadius: 5,
        color: textColor ?? 'white',
        fontSize: 11,
      }}
      onClick={event => {
        event.stopPropagation();
        onClick(label);
      }}
      disabled={isDisabled}
    >
      {label}
    </button>
  );
};
