import { SxProps } from '@mui/material';
import TextInput from '@shared/components/TextInput';

type Props = {
  label: string;
  value: string;
  onClick?: () => void;
  sx?: SxProps;
};

export const TextInputReadOnlyBox: React.FC<Props> = ({ label, value, onClick, sx }) => {
  return (
    <TextInput
      label={label}
      inputProps={{
        name: label,
        readOnly: true,
        value,
      }}
      onClick={onClick}
      sx={sx}
    />
  );
};
