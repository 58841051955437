import { getLondonEndOfDayLabel, isLondonTimeZone } from '@shared/utils/date';
import { format } from 'date-fns';

function formatExpiryDate(expiry: string, dateFormat: string) {
  const date = new Date(expiry);

  const expiryDateLabel = format(date, dateFormat);

  if (!isLondonTimeZone()) {
    const londonTimeExpiryLabel = getLondonEndOfDayLabel(date, dateFormat);
    return `${expiryDateLabel} ${londonTimeExpiryLabel}`;
  }

  return expiryDateLabel;
}

export function getExpiryDateLabel(expiry: string, isMobile: boolean) {
  return formatExpiryDate(expiry, isMobile ? 'd MMM ‘yy' : 'dd MMM yyyy');
}

export const convertName = (value: string) => {
  const newValue = value.charAt(0).toUpperCase() + value.slice(1);
  return newValue.replace('_', ' ');
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};
