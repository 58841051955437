import { numberService } from './NumberService';

class PriceFormatterService {
  public formatProductPrice = (price: string | number | null | undefined): string => {
    if (!numberService.isNumber(price)) {
      return '';
    }

    return price!.toString();
  };

  public formatDeltaProductPrice = (price: string | number | null | undefined): string => {
    if (!numberService.isNumber(price)) {
      return '';
    }

    return price!.toString();
  };

  public removeTrailZeros = (price: string): string => {
    const number = parseFloat(price);
    return number.toString();
  };

  public formatVariance = (variance: number): string => {
    let formattedNum;
    let suffix = '';

    if (variance >= 1000000) {
      formattedNum = (variance / 1000000).toFixed(2);
      suffix = 'M';
    } else if (variance >= 1000) {
      formattedNum = (variance / 1000).toFixed(2);
      suffix = 'K';
    } else {
      formattedNum = variance.toFixed(2);
      if (suffix) suffix = '';
    }

    // Remove trailing .00 or .X0 only from the numeric portion
    if (formattedNum.includes('.')) {
      formattedNum = formattedNum.replace(/\.?0+$/, '');
    }

    return `$${formattedNum}${suffix}`;
  };

  public formatVariancePercentage = (variance: number, maxVariance: number): string => {
    const variancePercent = variance === 0 || maxVariance === 0 ? 0 : (variance / maxVariance) * 100;
    return `${Math.round(variancePercent)}%`;
  };
}

const priceFormatterService = new PriceFormatterService();
export { priceFormatterService };
