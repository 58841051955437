import { Fade, Stack, Typography } from '@mui/material';
import theme from '@shared/themes/darkTheme';

export default function NotFoundPage() {
  return (
    <Fade in>
      <Stack display="flex" flexDirection="column" flex={1} justifyContent="center" minHeight="100vh">
        <Typography variant="h3" color={theme.palette.text.secondary} textAlign="center">
          404
        </Typography>
        <Typography variant="h6" color={theme.palette.text.secondary} textAlign="center">
          PAGE NOT FOUND
        </Typography>
      </Stack>
    </Fade>
  );
}
