import { FloatingChartPayload } from '@protos/charts';
import { IconComponent } from '@shared/components/IconComponent';
import theme from '@shared/themes/darkTheme';
import { WidgetProps } from '../../types';
import Widget, { Content, Header } from '../Widget';
import Settings from '../settings/Settings';
import { FloatingChartContainer } from './trading-view/FloatingChartContainer';

type FloatingChartProps = WidgetProps & {
  payload?: FloatingChartPayload;
};

export const FloatingChart = ({ payload, ...otherProps }: FloatingChartProps) => {
  const { id, title } = otherProps;

  return (
    <Widget {...otherProps}>
      <Header
        title={title}
        widgetIcon={<IconComponent name="ri-rfid-fill" iconStyle={{ fontSize: 18, color: theme.palette.primary.main, marginLeft: 10 }} />}
      >
        <Settings id={`floating-chart-settings-${id}`} title={title} widgetId={id} />
      </Header>
      <Content>
        <FloatingChartContainer widgetId={id} chartSettings={payload} />
      </Content>
    </Widget>
  );
};
