import { Grid } from '@features/dashboard/widgets/grid';
import { Divider } from '@mui/material';
import { ContractPNL, ContractRisk } from '@protos/trading';
import { TRADING_ADMIN_PNL_BLOTTER_COLUMNS, TRADING_ADMIN_RISK_BLOTTER_COLUMNS } from '../../tradingAdminProtos';

const getRowId = ({ data }): string => data?.tenorCode;
const getPnlRowId = ({ data }): string => data?.period;

type Props = {
  data: ContractRisk[];
  pnlData: ContractPNL[];
};

export const TradingRiskGrid: React.FC<Props> = ({ data, pnlData }) => {
  return (
    <>
      <Grid<ContractRisk>
        rowData={data}
        columnDefs={TRADING_ADMIN_RISK_BLOTTER_COLUMNS}
        getRowId={getRowId}
        tooltipShowDelay={0}
        enableCellTextSelection
        defaultColDef={{ enableCellChangeFlash: true }}
        autoSizeStrategy={{ type: 'fitGridWidth' }}
      />
      <Divider />
      <Grid<ContractPNL>
        rowData={pnlData}
        columnDefs={TRADING_ADMIN_PNL_BLOTTER_COLUMNS}
        getRowId={getPnlRowId}
        tooltipShowDelay={0}
        enableCellTextSelection
        defaultColDef={{ enableCellChangeFlash: true }}
        autoSizeStrategy={{ type: 'fitGridWidth' }}
        maxHeight="40%"
      />
    </>
  );
};
