import { TaskPiority as TaskPiorityType } from '@protos/tasks';
import { IconComponent } from '@shared/components/IconComponent';
import theme from '@shared/themes/darkTheme';

export const TaskPiority: React.FC<{ piority: TaskPiorityType }> = ({ piority }) => {
  switch (piority) {
    case 'high':
      return <IconComponent name={'ri-arrow-up-double-fill'} iconStyle={{ color: theme.palette.error.main }} />;
    case 'low':
      return <IconComponent name={'ri-arrow-down-s-line'} iconStyle={{ color: theme.palette.success.main }} />;
    case 'medium':
      return <IconComponent name={'ri-equal-line'} iconStyle={{ color: theme.palette.warning.light }} />;
    case 'special':
      return <IconComponent name={'ri-circle-line'} iconStyle={{ color: theme.palette.secondary.light }} />;
    default:
      return null;
  }
};
