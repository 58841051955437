import { Filter, FilterType } from '@shared/protos/filter';
import { Product, TenorFrequency } from '@shared/protos/product';
import { ColDef } from 'ag-grid-enterprise';

export const PRODUCT_COLUMNS: ColDef<Product>[] = [
  {
    field: 'symbol',
    headerName: 'Symbol',
  },
  {
    field: 'product_group',
    headerName: 'Product Group',
    valueFormatter: ({ value }) => PRODUCT_GROUP_NAME[value],
  },
  {
    field: 'product_type',
    headerName: 'Product Type',
    valueFormatter: ({ value }) => PRODUCT_TYPE_NAME[value],
  },
  {
    field: 'tenor_frequency',
    headerName: 'Tenor Frequency',
  },
  {
    field: 'calendar_type',
    headerName: 'Calendar Type',
  },
  {
    field: 'full_name',
    headerName: 'Full Name',
  },
  {
    field: 'mdx_name',
    headerName: 'Mdx Name',
  },
  {
    field: 'currency_unit',
    headerName: 'Currency Unit',
  },
  {
    field: 'tick_size',
    headerName: 'Tick Size',
  },
  {
    field: 'underlying_symbol',
    headerName: 'Underlying Symbols',
  },
  {
    field: 'pricing_tenors',
    headerName: 'Pricing Tenors',
  },
  {
    field: 'id',
    headerName: 'ID',
  },
];

export type ProductGroupEnum =
  | 'crude'
  | 'gasoline'
  | 'naphtha'
  | 'distillate'
  | 'jet_fuel'
  | 'fuel_oil'
  | 'petrochemical'
  | 'freight'
  | 'methane'
  | 'ethane'
  | 'propane'
  | 'butane'
  | 'pentane'
  | 'ngl'
  | 'refinery_margin';

export const PRODUCT_GROUP_OPTIONS: ProductGroupEnum[] = [
  'crude',
  'gasoline',
  'naphtha',
  'distillate',
  'jet_fuel',
  'fuel_oil',
  'petrochemical',
  'freight',
  'methane',
  'ethane',
  'propane',
  'butane',
  'pentane',
  'ngl',
  'refinery_margin',
];

export type ProductTypeEnum =
  | 'spot'
  | 'future'
  | 'swap'
  | 'weekly_swap'
  | 'daily_swap'
  | 'future_swap'
  | 'option'
  | 'composite'
  | 'calendar'
  | 'undefined';

const PRODUCT_TYPE_OPTIONS: ProductTypeEnum[] = [
  'spot',
  'future',
  'swap',
  'weekly_swap',
  'daily_swap',
  'future_swap',
  'option',
  'composite',
  'calendar',
];

type CalendarTypeEnum = 'flat' | 'spread' | 'quarter' | 'butterfly' | 'condor' | 'year';
const CALENDAR_TYPE_OPTIONS: CalendarTypeEnum[] = ['flat', 'spread', 'quarter', 'butterfly', 'condor', 'year'];
const TENOR_FREQ_OPTIONS: TenorFrequency[] = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];

export const PRODUCTS_FILTERS: Filter[] = [
  { type: FilterType.AUTOCOMPLETE, name: 'product_group', label: 'Product Group', options: PRODUCT_GROUP_OPTIONS },
  { type: FilterType.AUTOCOMPLETE, name: 'product_type', label: 'Product Type', options: PRODUCT_TYPE_OPTIONS },
  { type: FilterType.AUTOCOMPLETE, name: 'calendar_type', label: 'Calendar Type', options: CALENDAR_TYPE_OPTIONS },
  { type: FilterType.AUTOCOMPLETE, name: 'tenor_frequency', label: 'Tenor Frequency', options: TENOR_FREQ_OPTIONS },
  { type: FilterType.AUTOCOMPLETE, name: 'private', label: 'Private', options: ['true', 'false'] },
  { type: FilterType.SWITCH, name: 'enabled', label: 'Enabled', defaultValue: 'true' },
];

export const MESSAGE_TYPE: Record<number, string> = {
  0: 'unspecified',
  1: 'update',
  2: 'create',
  3: 'delete',
};

export const PRODUCT_GROUP_NAME: {
  readonly [key in ProductGroupEnum]: string;
} = {
  crude: 'Crude',
  gasoline: 'Gasoline',
  naphtha: 'Naphtha',
  distillate: 'Distillate',
  jet_fuel: 'Jet fuel',
  fuel_oil: 'Fuel oil',
  petrochemical: 'Petrochemical',
  freight: 'Freight',
  methane: 'Methane',
  ethane: 'Ethane',
  propane: 'Propane',
  butane: 'Butane',
  pentane: 'Pentane',
  ngl: 'NGL',
  refinery_margin: 'Refinery Margin',
};

export const PRODUCT_TYPE_NAME: {
  readonly [key in ProductTypeEnum]: string;
} = {
  spot: 'Spot',
  future: 'Future',
  swap: 'Swap',
  weekly_swap: 'Weekly Swap',
  daily_swap: 'Daily Swap',
  future_swap: 'Future Swap',
  option: 'Option',
  composite: 'Composite',
  calendar: 'Calendar',
  undefined: 'Undefined',
};
