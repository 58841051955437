import { AppState, Auth0Provider } from '@auth0/auth0-react';

import { config } from '@services/context';
import { localUrl } from '@shared/utils/url';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

/**
 * Provides an Auth0 authentication context integrated with React Router's navigation.
 *
 * @props children - Child components to be rendered within the Auth0Provider context.
 * @returns The Auth0Provider component wrapped around the children, or null if configuration is incomplete.
 */

export const Auth0ProviderWithNavigate = ({ children }: Props) => {
  const navigate = useNavigate();

  const domain = config.env.AUTH0_DOMAIN;
  const clientId = config.env.AUTH0_UI_API_CLIENT_ID;
  const audience = config.env.AUTH0_AUDIENCE_URL;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    throw new Error('Auth0 configuration is incomplete.');
  }

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: localUrl('/dashboard').href,
        audience,
      }}
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
