import { useScreenSize } from '@hooks/useScreenSize';
import { Box, Typography } from '@mui/material';
import theme from '@shared/themes/darkTheme';
import TeamPhoto from '../shared/assets/FluxTeamPhoto.png';

const getImageWidthHeight = (isMobile: boolean, isMobileLandscape: boolean): { width: number; height: number } => {
  if (isMobile && !isMobileLandscape) {
    return { width: 388.8, height: 162 };
  } else if (isMobileLandscape) {
    return { width: 576, height: 240 };
  }
  return { width: 1152, height: 480 };
};

export const HallOfFame: React.FC = () => {
  const { isMobileLandscape, isMobile } = useScreenSize();
  const { width, height } = getImageWidthHeight(isMobile, isMobileLandscape);

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      {...(isMobile ? { paddingTop: '60%' } : {})}
    >
      <img
        src={TeamPhoto}
        alt="Hall of Fame"
        width={width}
        height={height}
        style={{ borderRadius: 30, boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)' }}
      />
      <Box display="flex" alignItems="baseline" mt={isMobileLandscape || isMobile ? 7 : 15} gap={1} mb={1}>
        <i className="ri-heart-fill" style={{ color: theme.palette.background.sell, fontSize: '24px' }} />
        <Typography variant="h4" align="center">
          Made with Love
        </Typography>
        <i className="ri-heart-fill" style={{ color: theme.palette.background.sell, fontSize: '24px' }} />
      </Box>
      <Typography variant="caption" align="center">
        Luca Sbardella - Shaurya Puri - Ishaan Hemnani - Anthony Dang - Rohit Mungre - Mohit Sonania
      </Typography>
      <Typography variant="caption" align="center">
        & an amazing team at Onyx Capital Group
      </Typography>
    </Box>
  );
};
