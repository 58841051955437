import CircularProgress from '@mui/material/CircularProgress';

export default function PageLoadProgress() {
  return (
    <CircularProgress
      size={24}
      sx={{
        right: 'calc(50% - 12px)',
        position: 'absolute',
        top: '10%',
      }}
    />
  );
}
