import { useAsync } from 'react-use';
import { useApi } from './useApi';

export function useProductBySymbol(symbol: string) {
  const { apiClient } = useApi();

  const state = useAsync(async () => {
    const newProduct = await apiClient?.getProductBySymbol(symbol);
    return newProduct;
  }, [apiClient, symbol]);

  return {
    product: state.value,
    ...state,
  };
}
