import { Order } from '@protos/trading';
import { Stream, StreamEvent, Subscription } from './Stream';

export type OrderCallback = (order: Order) => void;

export const streamOrderService = (stream: Stream) => {
  let eventCallback: OrderCallback | undefined;

  stream.onEvent('orders', (event: StreamEvent) => {
    const order = event.asOrder();
    if (!order || !eventCallback) return;

    eventCallback(order);
  });

  const subscribe = (callback: OrderCallback) => {
    stream.subscribe(Subscription.order());
    eventCallback = callback;
  };

  const unsubscribe = () => {
    eventCallback = undefined;
    stream.unsubscribe(Subscription.order());
  };

  return {
    subscribe,
    unsubscribe,
  };
};
