import { Box, Button, Chip, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert } from '@shared/protos/alerts';
import { getAlertLevelStyles } from '@shared/styles/alertStyles';
import darkTheme from '@shared/themes/darkTheme';
import { formatDatetime } from '@shared/utils/date';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertLevelIcon } from './AlertLevelIcon';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.darker,
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.lighter,
  },
  alignSelf: 'center',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.background.lightHover,
  },
}));

const StyledListItemDismissButton = styled(Button)(({ theme }) => ({
  fontSize: 11,
  marginLeft: 'auto',
  color: darkTheme.palette.text.primary,
  '&:hover': {
    backgroundColor: `${theme.palette.background.sell} !important`,
    color: darkTheme.palette.text.dark,
  },
}));

const CounterBox = styled(Chip)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  fontSize: 11,
}));

type AlertListItemProps = {
  alert: Alert;
  onDismissAlert: (id: number) => Promise<void>;
  lastItemRef?: (node: HTMLElement | null) => void;
  onFilterChange: (newFilterValue: Record<string, string | null>) => void;
  setSelectedEventsAlert: (alert: Alert) => void;
};

export const AlertListItem: React.FC<AlertListItemProps> = ({ alert, lastItemRef, onDismissAlert, onFilterChange, setSelectedEventsAlert }) => {
  const { color } = getAlertLevelStyles(alert.level);
  const navigate = useNavigate();

  return (
    <StyledListItem ref={lastItemRef} onClick={() => setSelectedEventsAlert(alert)}>
      <Box display="flex" gap={5} alignItems="center">
        <AlertLevelIcon level={alert.level} />
        <Box display="flex" flexDirection="column" alignItems="start" gap={0.5}>
          <Typography variant="h6" style={{ fontSize: 12 }}>
            {alert.message}
          </Typography>
          <Box display="flex" gap={1} alignItems="center">
            <CounterBox
              onClick={event => {
                event?.stopPropagation();
                setSelectedEventsAlert(alert);
              }}
              label={alert.counter}
              variant="outlined"
              size="small"
            />
            <Chip label={alert.level} size="small" variant="outlined" style={{ color, borderColor: color, fontSize: 11 }} />
            {alert.group && (
              <Chip
                label={alert.group}
                size="small"
                variant="outlined"
                onClick={event => {
                  event.stopPropagation();
                  onFilterChange({ group: alert.group });
                }}
                style={{ fontSize: 11 }}
              />
            )}
            {alert.product_symbol && (
              <Chip
                label={alert.product_symbol}
                size="small"
                variant="outlined"
                style={{ fontSize: 11 }}
                onClick={event => {
                  event.stopPropagation();
                  navigate(`/admin/products/${alert.product_symbol}`);
                }}
              />
            )}
            {alert.symbol && <Chip label={alert.symbol} size="small" variant="outlined" style={{ fontSize: 11 }} />}
            <Typography variant="caption" style={{ fontSize: 11 }}>
              {formatDatetime(alert.timestamp)}
            </Typography>
          </Box>
        </Box>
      </Box>

      {!alert.dismissed && (
        <StyledListItemDismissButton
          onClick={event => {
            event?.stopPropagation();
            onDismissAlert(alert.id);
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Dismiss
        </StyledListItemDismissButton>
      )}
    </StyledListItem>
  );
};
