import MUIButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Button } from '@shared/components/Button';
import { IconComponent } from '@shared/components/IconComponent';
import Popup from '@shared/components/Popup';
import { UserContext } from '@shared/contexts/UserContextProvider';
import { useAppAuthentication } from '@shared/hooks/useAppAuthentication';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as OnyxLogo } from 'src/shared/assets/onyx-logo.svg';

const StyledLinkButton = styled(MUIButton)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '14px',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  lineHeight: '19px',
  minWidth: 0,
  marginBottom: '20px',
  '&:hover': {
    backgroundColor: 'transparent !important',
    color: theme.palette.text.primary,
  },
})) as typeof MUIButton;

const StyledNameTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '18px',
  lineHeight: '25px',
  paddingTop: '10px',
}));

const StyledEmailTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '14px',
  lineHeight: '19px',
  color: theme.palette.text.secondary,
}));

const StyledCompanyTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '14px',
  lineHeight: '19px',
  paddingBottom: '10px',
}));

export const UserAccountPopup = props => {
  const { handleLogout, isLoading } = useAppAuthentication();

  const { isMobile } = useScreenSize();
  const user = useContext(UserContext);

  if (!user) return null;

  return (
    <Popup isMobile={isMobile} offset={[0, 20]} arrow {...props}>
      <>
        <OnyxLogo />
        <StyledNameTypography id="profile-name-lbl">{user.full_name}</StyledNameTypography>
        <StyledEmailTypography id="profile-email-lbl">{user.email}</StyledEmailTypography>
        <StyledCompanyTypography id="profile-company-lbl"></StyledCompanyTypography>
        <Stack direction="row" alignItems="center">
          <StyledLinkButton id="manage-account-btn" variant="text" component={Link} to="/manage-account" onClick={props.onClose}>
            <IconComponent name={'ri-settings-2-line'} /> {`Manage Account`}
          </StyledLinkButton>
        </Stack>
        <Button id="logout-btn" variant="outlined" fullWidth onClick={handleLogout} disabled={isLoading} color="error">
          {isLoading ? <CircularProgress size={24} /> : 'Logout'}
        </Button>
      </>
    </Popup>
  );
};
