export enum PageId {
  dashboard = 'dashboard',
  manageAccount = 'manage-account',
  admin = 'admin',
  help = 'help',
}

export type Handle = {
  pageId: PageId;
  crumbName?: string;
  trackPage?: boolean;
};
