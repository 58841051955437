export type SettlementPriceResponse = {
  symbol: string;
  product_symbol: string;
  tenor_Code: string;
  snapshot_period: string;
  date: string;
  timestamp: string;
  value: string;
  delta: string;
};

export enum SettlementType {
  Sing = 'sing',
  Euro = 'euro',
  Flux = 'flux',
}

export const SETTLEMENT_PERIOD = {
  [SettlementType.Sing]: 'singapore_1630',
  [SettlementType.Euro]: 'london_1630',
  [SettlementType.Flux]: 'london_1930',
};

export const SETTLEMENT_TYPE = {
  singapore_1630: SettlementType.Sing,
  london_1630: SettlementType.Euro,
  london_1930: SettlementType.Flux,
};
