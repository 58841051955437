import { WidgetProps } from '@features/dashboard/types';
import { Search } from '@shared/components/Search';
import { useState } from 'react';
import Widget, { Header } from '../Widget';
import Settings from '../settings/Settings';
import { TradingBlotterGrid } from './TradingBlotterGrid';
import { TradingBlotterWidgetPayload } from './tradingBlotterTypes';

type TradingBlotterProps = WidgetProps & {
  payload?: TradingBlotterWidgetPayload;
};

export const TradingBlotter = ({ payload, ...otherProps }: TradingBlotterProps) => {
  const { id, title } = otherProps;
  const { searchTerm: initialSearchTerm } = payload ?? { searchTerm: '' };

  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm);

  const onSearch = (text: string) => {
    setSearchTerm(text);
  };

  return (
    <Widget {...otherProps}>
      <Header title={title}>
        <Search value={searchTerm} onSearch={onSearch} hideLabel miniSearch maxWidth="160px" />
        <Settings id={`trading-settings-${id}`} title={title} widgetId={id} />
      </Header>
      <TradingBlotterGrid widgetId={id} searchTerm={searchTerm} />
    </Widget>
  );
};
