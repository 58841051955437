import { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

type Props = {
  children: ReactElement | ReactElement[];
};

type ActionHeaderProps = Props & {
  onBack: () => void;
};

function ActionHeader({ onBack, children }: ActionHeaderProps) {
  return (
    <Stack position="relative">
      <IconButton onClick={onBack} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
        <i className="ri-arrow-left-line" style={{ fontSize: '16px' }} />
      </IconButton>
      {children}
    </Stack>
  );
}

function ActionFooter({ children }: Props) {
  return (
    <Stack borderTop="2px solid #0E0F1440" padding="12px">
      {children}
    </Stack>
  );
}

function ActionMenu({ children }: Props) {
  return <Stack direction="column">{children}</Stack>;
}

export { ActionHeader, ActionFooter };
export default ActionMenu;
