import { useAdminApi } from '@hooks/useAdminApi';
import { CreateLadderRequest } from '@protos/ladders';
import { Order } from '@protos/trading';
import { useCallback } from 'react';

export const useLaddersApi = () => {
  const { apiClient } = useAdminApi();

  const createLadder = useCallback(
    async (payload: CreateLadderRequest): Promise<Order[] | undefined> => {
      if (!apiClient) return undefined;

      try {
        const { symbol, expiry_timestamp, orders, account_id } = payload;
        const ladderRequest: CreateLadderRequest = {
          symbol,
          expiry_timestamp,
          orders,
          account_id,
        };

        const ladders = await apiClient.createNewLadder(ladderRequest);
        return ladders;
      } catch (error) {
        console.error('Error creating ladder:', error);
        return undefined;
      }
    },
    [apiClient]
  );

  const expireLadder = useCallback(
    async (symbol: string, account_id: string): Promise<void> => {
      if (!apiClient) return;

      try {
        await apiClient.expireExistingLadder(symbol, account_id);
      } catch (error) {
        console.error('Error expiring ladder:', error);
      }
    },
    [apiClient]
  );

  const fetchLaddersForContracts = useCallback(
    async (symbols: string[]): Promise<Order[]> => {
      if (!apiClient) return [];

      try {
        const ladders = await apiClient.getLaddersForContracts(symbols);
        return ladders;
      } catch (error) {
        console.error('Error fetching ladders:', error);
        return [];
      }
    },
    [apiClient]
  );

  const getAllOrdersLoader = useCallback(() => {
    if (!apiClient) return;

    return apiClient.allLaddersLoader();
  }, [apiClient]);

  return { createLadder, fetchLaddersForContracts, getAllOrdersLoader, expireLadder };
};
