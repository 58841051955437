import { TaskLastRunState } from '@protos/tasks';
import theme from '@shared/themes/darkTheme';

export const getStateColor = (state: TaskLastRunState) => {
  switch (state) {
    case 'init':
      return {
        color: theme.palette.secondary.light,
      };
    case 'running':
      return {
        color: theme.palette.primary.dark,
      };
    case 'success':
      return {
        color: theme.palette.success.dark,
      };

    case 'failure':
      return {
        color: theme.palette.error.dark,
      };
    case 'queued':
      return {
        color: theme.palette.secondary.light,
      };
    case 'aborted':
      return {
        color: theme.palette.error.dark,
      };
    case 'rate_limited':
      return {
        color: theme.palette.secondary.light,
      };
    default:
      return {
        color: theme.palette.secondary.light,
      };
  }
};
