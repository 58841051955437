import IconButton, { IconButtonProps, iconButtonClasses } from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';

type Props = {
  hidden?: boolean;
} & IconButtonProps;

interface StyledIconButtonProps extends IconButtonProps {
  hidden?: boolean;
}

export const StyledIconButton = styled((props: StyledIconButtonProps) => <IconButton {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'hidden',
})(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.text.secondary,
}));

const StyledEyeIconButton = styled(StyledIconButton)(({ theme, hidden }) => ({
  color: hidden ? theme.palette.text.secondary : theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.text.primary,
  },
  [`&.${iconButtonClasses.disabled}`]: {
    color: `${alpha(theme.palette.primary.main, 0.25)} !important`,
  },
}));

export default function VisibilityIconButton({ hidden, ...buttonProps }: Props) {
  return (
    <StyledEyeIconButton hidden={hidden} {...buttonProps}>
      <i className={hidden ? 'ri-eye-off-line' : 'ri-eye-line'} />
    </StyledEyeIconButton>
  );
}
