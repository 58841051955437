import { GetRowIdParams, IRowNode, RowClassParams } from 'ag-grid-enterprise';
import { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '../../grid';
import { useSettlementPricesSettings } from '../SettlementPricesContext';
import { SettlementPricesRow } from '../types';

const isJuneDecRow = (params: RowClassParams<SettlementPricesRow, any>) =>
  params.data?.tenorFrequency === 'monthly' && (params.data.tenorName.includes('JUN') || params.data.tenorName.includes('DEC'));

const getRowId = ({ data }: GetRowIdParams<SettlementPricesRow>): string => data.tenorId.toString();

export default function SettlementPricesGrid() {
  const { columnDefs, columns, rows, filteredSelectedTenors, onColumnMoved, gridRef } = useSettlementPricesSettings();

  const selectedRowIds = useMemo(() => filteredSelectedTenors.map(elem => elem.code), [filteredSelectedTenors]);

  const firstFrequencyRows = useMemo(() => {
    return filteredSelectedTenors.reduce((acc, tenor, index) => {
      if (index === 0) {
        const nextTenor = filteredSelectedTenors[index + 1];
        if (nextTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      } else {
        const prevTenor = filteredSelectedTenors[index - 1];
        if (prevTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      }

      return acc;
    }, [] as string[]);
  }, [filteredSelectedTenors]);

  const isFirstFrequencyRow = useCallback(
    (params: RowClassParams<SettlementPricesRow, any>) => {
      if (params.rowIndex === 0) return false;

      if (params.data?.tenorId && firstFrequencyRows.includes(params.data?.tenorId)) return true;
      return false;
    },
    [firstFrequencyRows]
  );

  const getRowStyle = useCallback(
    (params: RowClassParams<SettlementPricesRow, any>) => {
      if (isFirstFrequencyRow(params)) {
        return { borderBottom: 'none', borderTop: '1px solid #f1c40f', ...(isJuneDecRow(params) ? { borderBottom: '1px solid grey' } : {}) };
      }

      if (isJuneDecRow(params)) {
        return { borderBottom: '1px solid grey', borderTop: '1px solid grey' };
      }

      return undefined;
    },
    [isFirstFrequencyRow, isJuneDecRow]
  );

  const isExternalFilterPresent = useCallback(() => selectedRowIds.length > 0, [selectedRowIds]);

  const doesExternalFilterPass = useCallback(
    (rowNode: IRowNode<SettlementPricesRow>) => (rowNode.data ? selectedRowIds.includes(rowNode.data.tenorId) : true),
    [selectedRowIds]
  );

  useEffect(() => {
    // After filters have been changed via API, we must ensure that the grid is refreshed;
    // If ´api.onFilterChanged()´ is not called, the grid will not be refreshed.
    if (gridRef.current) {
      gridRef.current.getGridApi()?.onFilterChanged();
    }
  }, [selectedRowIds]);

  return (
    <Grid<SettlementPricesRow>
      ref={gridRef}
      defaultColDef={columnDefs}
      columnDefs={columns}
      rowData={rows}
      getRowId={getRowId}
      onColumnMoved={onColumnMoved}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      suppressRowVirtualisation
      suppressColumnVirtualisation
      getRowStyle={getRowStyle}
      headerHeight={0}
      groupHeaderHeight={20}
      tooltipShowDelay={0}
    />
  );
}
