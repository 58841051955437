import { AdminMenu } from '@features/admin/AdminMenu';
import { MobileNavBarButton } from '@features/base/private/MobileNavBar';
import { SidebarButton } from '@features/base/private/Sidebar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { toastifyService } from '@services/ToastifyService';
import { logger } from '@services/context';
import { IconComponent } from '@shared/components/IconComponent';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { Dashboard } from '@shared/protos/dashboard';
import { PageId } from '@shared/protos/routes';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import DeleteDashboard from './DeleteDashboard';
import EditDashboard from './EditDashboard';
import SidebarMenu, { SidebarListItem, SidebarListItemWithActions } from './SidebarMenu';

type Props = {
  mobile?: boolean;
};

type DashboardsProps = {
  onClose?: () => void;
  isMobile?: boolean;
};

type Mode = 'view' | 'edit' | 'delete';

const UserDashboards = ({ onClose, isMobile }: DashboardsProps) => {
  const [mode, setMode] = useState<Mode>('view');
  const [loading, setLoading] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>();
  const { addDashboard, allDashboards } = useDashboardContext();
  const navigate = useNavigate();

  const handleOpenDashboard = ({ id }: Dashboard) => {
    onClose?.();
    navigate(`/dashboard/${id}`);
  };

  const handleNewDashboardClick = useCallback(async () => {
    try {
      setLoading(true);
      const dashboard = await addDashboard();
      onClose?.();
      navigate(`/dashboard/${dashboard.id}`);
    } catch (error: any) {
      toastifyService.showErrorMessage('Unable to create dashboard, please try again');
      logger.error('Error creating dashboard', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleModeChange = (mode: Mode, dashboard: Dashboard) => {
    setMode(mode);
    setSelectedDashboard(dashboard);
  };

  const onDelete = (close?: boolean) => {
    setMode('view');
    close && onClose?.();
  };

  const handleBack = () => setMode('view');

  if (mode === 'edit' && selectedDashboard) {
    return <EditDashboard dashboard={selectedDashboard} onBack={handleBack} />;
  }

  if (mode === 'delete' && selectedDashboard) {
    return <DeleteDashboard dashboard={selectedDashboard} onDelete={onDelete} onBack={handleBack} />;
  }

  return (
    <SimpleBar style={{ maxHeight: '275px' }}>
      <Stack padding="5px" paddingBottom="10px">
        <List disablePadding style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <SidebarListItem
            primary={
              <Stack direction="row" gap={1} style={{ alignItems: 'center' }}>
                <IconComponent name="ri-add-circle-line" />
                New Dashboard
              </Stack>
            }
            primaryTypographyProps={{
              fontWeight: 'fontWeightBold',
              color: 'primary.main',
            }}
            onClick={handleNewDashboardClick}
            loading={loading}
          />
          <Divider style={{ marginBottom: 5 }} />
          {allDashboards.map(dashboard => (
            <SidebarListItemWithActions
              key={dashboard.id}
              dashboardId={dashboard.id}
              label={dashboard.name}
              onClick={() => handleOpenDashboard(dashboard)}
              onEdit={() => handleModeChange('edit', dashboard)}
              onDelete={() => handleModeChange('delete', dashboard)}
              isMobile={isMobile}
            />
          ))}
        </List>
      </Stack>
    </SimpleBar>
  );
};

export default function DashboardNavItem({ mobile }: Props) {
  const user = useUserContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {mobile ? (
        <MobileNavBarButton
          onClick={handleClick}
          icon="ri-dashboard-line"
          label="Dashboards"
          pageId={PageId.dashboard}
          data-testid="dashboard-nav-item-mobile"
        />
      ) : (
        <SidebarButton title="Dashboard" icon="ri-dashboard-line" onClick={handleClick} data-testid="dashboard-nav-item" />
      )}
      {user?.is_flux_admin && !mobile && <AdminMenu />}
      <SidebarMenu id="sidebar-dashboards-menu" anchorEl={anchorEl} onClose={handleClose} isMobile={mobile}>
        <UserDashboards onClose={handleClose} isMobile={mobile} />
      </SidebarMenu>
    </>
  );
}
