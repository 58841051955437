import { useApi } from '@shared/hooks/useApi';
import { Product, ProductTenor } from '@shared/protos/product';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface UserProductStore {
  userProducts: Product[];
  tenors: ProductTenor[];
  loaded: boolean;
}

const EMPTY_STORE: UserProductStore = { userProducts: [], tenors: [], loaded: false };
export const UserProductsAndTenorsContext = createContext<UserProductStore>(EMPTY_STORE);
UserProductsAndTenorsContext.displayName = 'UserContext';

export default function UserProductsAndTenorsContextProvider(props: any) {
  const { apiClient } = useApi();
  const [data, setData] = useState<UserProductStore>(EMPTY_STORE);

  useEffect(() => {
    async function fetchUserProductsAndTenors() {
      if (!apiClient || data.loaded) return;
      if (!data.loaded) {
        const [products, tenors] = await Promise.all([apiClient.getUserProducts(), apiClient.getProductTenors()]);
        setData({ userProducts: products || [], tenors: tenors || [], loaded: true });
      }
    }

    fetchUserProductsAndTenors();
  }, [apiClient, setData]);

  return <UserProductsAndTenorsContext.Provider value={data} {...props} />;
}

export function useUserProductsAndTenorsContext(): UserProductStore {
  const data = useContext(UserProductsAndTenorsContext);

  return useMemo(() => data, [data]);
}
