import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Theme, alpha, lighten, styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

interface StyledInputBaseProps extends InputBaseProps {
  sticky?: boolean;
}

type StyledTooltipProps = TooltipProps & {
  error?: boolean;
};

export const StyledButton = styled(Button)<ButtonProps>(({ theme, color }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  border: '3px solid',
  borderColor: theme.palette.primary.main,
  ...(color === 'secondary' && {
    borderColor: alpha(theme.palette.primary.main, 0.1),
  }),
  ...(color === 'error' && {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  }),
  padding: '4px',
  fontSize: theme.typography.fontSize + 2,
  '& span': {
    fontWeight: theme.typography.fontWeightExtraBold,
  },
  '&:hover': {
    color: theme.palette.text.primary,
    border: '3px solid',
    borderColor: theme.palette.primary.main,
    ...(color === 'secondary' && {
      borderColor: alpha(theme.palette.primary.main, 0.1),
    }),
    ...(color === 'error' && {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      backgroundColor: `${alpha(theme.palette.error.main, 0.1)} !important`,
    }),
  },
  '&:active': {
    borderColor: theme.palette.text.primary,
    ...(color === 'error' && {
      borderColor: lighten(theme.palette.error.main, 0.2),
    }),
  },
  '&.Mui-disabled': {
    color: alpha(theme.palette.text.primary, 0.5),
    border: '3px solid',
    borderColor: alpha(theme.palette.text.secondary, 0.5),
    ...(color === 'secondary' && {
      borderColor: theme.palette.action.hover,
    }),
  },
}));

export const StyledSearchInput = styled((props: StyledInputBaseProps) => <InputBase fullWidth {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'sticky',
})(({ theme, sticky }) => ({
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  paddingBottom: 5,
  caretColor: theme.palette.primary.main,
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.fontFamily,
    },
    padding: 0,
  },
  '& .MuiInputAdornment-root': {
    color: theme.palette.text.secondary,
    fontSize: 19,
  },
  ...(sticky && {
    position: 'sticky',
    top: 0,
    backgroundColor: alpha(theme.palette.background.default, 0.9),
    zIndex: theme.zIndex.mobileStepper,
    padding: '5px 16px 10px',
  }),
}));

export const StyledDivider = styled(Divider)({
  width: 'auto',
  margin: '0 -13px' /* Remove container padding */,
});

export const StyledToggleButtonGroup = styled((props: ToggleButtonGroupProps) => <ToggleButtonGroup fullWidth {...props} />)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    height: 48,
    textTransform: 'none',
    backgroundColor: theme.palette.background.lighter,
    border: `1px solid ${alpha(theme.palette.border, 0.05)}`,
    padding: 13,
    position: 'relative',
    color: theme.palette.text.secondary,
    '> p': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: 3,
    borderLeft: `1px solid ${alpha(theme.palette.border, 0.05)}`,
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)::before': {
    content: '""',
    width: 3,
    height: 'inherit',
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    left: -4,
    zIndex: -1,
  },
  '& .MuiToggleButtonGroup-grouped.Mui-selected:not(:first-of-type)::before': {
    backgroundColor: theme.palette.primary.main,
    height: 'inherit',
  },
  '& .MuiToggleButtonGroup-grouped.Mui-selected:first-of-type': {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    paddingLeft: 11,
  },
  '& .MuiToggleButtonGroup-grouped.Mui-selected:last-of-type': {
    borderRight: `3px solid ${theme.palette.primary.main}`,
    paddingRight: 11,
  },
  '& .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped::before': {
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
  },
  '& .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected': {
    marginLeft: 3,
  },
  '& .MuiToggleButtonGroup-grouped.Mui-disabled': {
    color: `${alpha(theme.palette.text.secondary, 0.5)} !important`,
  },
  '& .MuiToggleButtonGroup-grouped.Mui-selected': {
    borderTop: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    borderRight: 0,
    borderLeft: 0,
    paddingLeft: 14,
    paddingRight: 14,
    backgroundColor: theme.palette.background.lighter,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&::before': {
      left: -3,
      zIndex: 1,
    },
    '&.Mui-disabled': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 10,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
}));

export const StyledMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: 10,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
  zIndex: 2,
}));

export const StyledOptionalMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 10,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
  transform: 'none',
  right: 0,
  position: 'absolute',
  opacity: 0.5,
  zIndex: 2,
}));

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  borderTop: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  backgroundColor: theme.palette.background.default,
  '&:first-of-type': {
    borderTop: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<i className="ri-arrow-right-s-line"></i>} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  flexDirection: 'row-reverse',
  alignItems: 'center',
  padding: '0px 10px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    padding: '0px 0px 1px 10px',
  },
  '& i': {
    fontSize: 20,
    color: theme.palette.text.secondary,
    paddingTop: 1,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.default,
    '& i': {
      color: theme.palette.text.primary,
    },
    '& p': {
      color: theme.palette.text.primary,
    },
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0 13px 13px',
  borderTop: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  ...getScrollbarStyles(theme),
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightExtraBold,
  '&:hover': {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightExtraBold,
  },
}));

export const getScrollbarStyles = (theme: Theme): any => ({
  /* Works on Firefox */
  scrollbarWidth: 'thin',
  scrollbarColor: `${alpha(theme.palette.text.secondary, 0.5)} ${theme.palette.background.default}` /* scroll thumb and track */,
  scrollBehavior: 'smooth',

  /* Works on Chrome, Edge, and Safari */
  '&::-webkit-scrollbar': {
    width: 10,
  },
  '&::-webkit-scrollbar-button': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.text.secondary, 0.5),
    border: `2px solid ${theme.palette.background.default}`,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.text.secondary,
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: theme.palette.text.secondary,
  },
  [theme.breakpoints.up('lg')]: {
    /* Prevent `scroll-chain` to the body or parent element if the user reaches the end of the scoll of an element */
    overscrollBehavior: 'contain',
  },
});

export const getCenteredAbsoluteElStyles = (): React.CSSProperties => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: 'auto',
});

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip classes={{ popper: className }} {...props} />, {
  shouldForwardProp: propName => propName !== 'error',
})<StyledTooltipProps>(({ theme, error }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.lighter,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.lighter,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
    color: error ? theme.palette.error.main : theme.palette.text.primary,
    padding: 15,
  },
}));

export const BlurLayer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  background: alpha(theme.palette.background.darker, 0.75),
  filter: 'blur(5px)',
  userSelect: 'none',
  pointerEvents: 'none',
}));
