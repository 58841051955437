import { SidebarButton } from '@features/base/private/Sidebar';
import SidebarMenu, { SidebarListItem } from '@features/dashboard/navbar/SidebarMenu';
import { List, Stack } from '@mui/material';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AdminMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const user = useUserContext();

  const adminProductsList = [
    { name: 'Products', path: 'products', disabled: !user?.is_flux_admin },
    { name: 'Alerts', path: 'alerts', disabled: !user?.is_flux_admin },
    { name: 'Settlements', path: 'settlements', disabled: !user?.is_flux_admin },
    { name: 'Trading', path: 'trading', disabled: !user?.is_trading_admin },
    { name: 'Outliers', path: 'outliers' },
    { name: 'Tasks', path: 'tasks' },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleRedirect = useCallback(
    (path: string) => {
      navigate(`/admin/${path}`);
    },
    [navigate]
  );

  if (!user?.is_flux_admin) return null;

  return (
    <>
      <SidebarButton title="Admin" icon="ri-admin-line" onClick={handleClick} data-testid="admin-nav-item" />
      <SidebarMenu id="sidebar-admin-menu" anchorEl={anchorEl} onClose={handleClose}>
        <Stack padding="10px">
          <List disablePadding>
            {adminProductsList
              .filter(item => !item.disabled)
              .map(item => (
                <SidebarListItem
                  key={item.name}
                  onClick={() => {
                    handleRedirect(item.path);
                    handleClose();
                  }}
                  primary={item.name}
                />
              ))}
          </List>
        </Stack>
      </SidebarMenu>
    </>
  );
};
