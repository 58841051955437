import { useAdminApi } from '@hooks/useAdminApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button, styled, Tooltip } from '@mui/material';
import darkTheme from '@shared/themes/darkTheme';
import { CSSProperties, useCallback, useState } from 'react';

type Props = {
  csvUrl: string;
  style?: CSSProperties;
};

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.8),
  width: 'fit-content',
  color: darkTheme.palette.border,
  borderColor: darkTheme.palette.border,
  opacity: 0.3,
  minWidth: 'unset',
  borderRadius: '2',
  border: '1px solid',
  '&:hover': {
    borderColor: darkTheme.palette.border,
    backgroundColor: `${darkTheme.palette.background.default} !important`,
  },
}));

export const DownloadCSV = ({ csvUrl, style }: Props) => {
  const { apiClient } = useAdminApi();
  const [loading, setLoading] = useState(false);

  const downloadCsvFile = useCallback(async () => {
    if (!apiClient) return;

    try {
      setLoading(true);
      const response = await fetch(csvUrl, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${apiClient.getToken()}`,
          accept: 'text/csv',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error downloading the file:', error);
    }
  }, [apiClient, csvUrl]);

  return (
    <Tooltip title={`Download CSV file`}>
      <StyledButton onClick={downloadCsvFile} variant="outlined" disabled={loading} style={style}>
        <FileDownloadIcon style={{ fontSize: '14px' }} />
      </StyledButton>
    </Tooltip>
  );
};
