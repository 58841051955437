import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import { PickersLayoutContentWrapper, PickersLayoutRoot, usePickerLayout } from '@mui/x-date-pickers';
import { getScrollbarStyles } from '../StyledComponents';

const StyledPaperContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.popover,
  border: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  position: 'relative',
  '& .MuiPickersCalendarHeader-root': {
    padding: 0,
    paddingLeft: 2,
    margin: 5,
    '& + div': {
      borderTop: '1px solid #2E3138',
    },
  },
  '& .MuiPickersCalendarHeader-label': {
    fontSize: theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  '& .MuiDayCalendar-weekDayLabel': {
    fontSize: theme.typography.body2,
    fontWeight: theme.typography.fontWeightRegular,
    width: 31,
    margin: '0 1px',
  },
  '& .MuiDayCalendar-weekContainer': {
    margin: '3px 0',
  },
  '& .MuiDayCalendar-monthContainer': {
    padding: 5,
  },
  '& .MuiDateCalendar-root': {
    width: '280px',
    height: '280px',
  },
  '& .MuiYearCalendar-root': {
    ...getScrollbarStyles(theme),
    padding: 5,
    '& button': {
      borderRadius: 0,
      border: `3px solid #3C3F47`,
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSize,
      margin: '2px 0',
      width: 74,
      height: 42,
      '&.Mui-selected': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        border: `3px solid ${theme.palette.primary.main}`,
      },
    },
  },
  '& .MuiClock-root': {
    '& .MuiClock-clock': {
      backgroundColor: theme.palette.background.darker,
      '& .MuiClock-pin': {
        width: 10,
        height: 10,
      },
      '& span.MuiClockNumber-root': {
        backgroundColor: theme.palette.background.lighter,
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-selected': {
          border: `3px solid ${theme.palette.primary.main}`,
          color: theme.palette.text.primary,
        },
      },
    },
    '& .MuiClock-amButton, .MuiClock-pmButton': {
      bottom: 20,
      '> span': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  '& .MuiDialogActions-root': {
    '& .MuiButtonBase-root': {
      fontSize: theme.typography.body1,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
    },
  },
}));

export default function CustomLayout(props) {
  const { tabs, content, actionBar } = usePickerLayout(props);

  return (
    <PickersLayoutRoot ownerState={props}>
      <StyledPaperContent>
        <PickersLayoutContentWrapper>
          {tabs}
          {content}
        </PickersLayoutContentWrapper>
        {actionBar}
      </StyledPaperContent>
    </PickersLayoutRoot>
  );
}
