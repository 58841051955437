import { Autocomplete, Popper, styled, TextField } from '@mui/material';

const StyledMuiAutocomplete = styled(Autocomplete)({
  width: '100%',
  borderRadius: 0,
  '& .MuiAutocomplete-inputRoot': {
    padding: 0,
    borderRadius: 0,
  },
  '& .MuiInputBase-input': {
    fontSize: '11px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '11px',
    '&.Mui-focused, &.MuiFormLabel-filled': {
      fontSize: '13px',
    },
  },
});

const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: 11,
  },
});

interface StyledAutocompleteProps {
  options: any[];
  value: any;
  onChange: (event: React.SyntheticEvent, value: any) => void;
  label?: string;
  isOptionEqualToValue: (option: unknown, value: unknown) => boolean;
  getOptionLabel?: (option: unknown) => string;
}

export const StyledAutocomplete = ({ options, value, onChange, label, isOptionEqualToValue, getOptionLabel }: StyledAutocompleteProps) => {
  return (
    <StyledMuiAutocomplete
      size="small"
      disableClearable
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      autoHighlight
      PopperComponent={props => <StyledPopper {...props} />}
      renderInput={params => <TextField {...params} variant="outlined" tabIndex={-1} label={label} />}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
