import '@features/dashboard/styles/custom-ag-grid.scss';
import { Box } from '@mui/material';
import { AG_GRID_LICENCE_KEY } from '@shared/utils/grid';
import { GridApi, GridReadyEvent, LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

type GridProps<T> = AgGridReactProps<T> & { maxHeight?: string };

export interface GridRef<T> {
  getGridApi: () => GridApi<T> | null;
}

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

const GridComponent = <T,>(props: GridProps<T>, ref: ForwardedRef<GridRef<T>>) => {
  const gridApiRef = useRef<GridApi<T> | null>(null);
  const { maxHeight } = props;

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api as GridApi<T>;
  };

  const memoizedProps = useMemo(
    () => ({
      ...props,
      onGridReady,
    }),
    [props]
  );

  useImperativeHandle(ref, () => ({
    getGridApi: () => gridApiRef.current,
  }));

  return (
    <Box className="ag-theme-balham-dark custom-grid" width="100%" height={maxHeight ?? '100%'}>
      <AgGridReact<T>
        autoSizeStrategy={{
          type: 'fitCellContents',
        }}
        getContextMenuItems={() => ['copy']}
        {...memoizedProps}
        onGridReady={onGridReady}
        suppressDragLeaveHidesColumns
        suppressColumnMoveAnimation
        defaultColDef={{ suppressHeaderMenuButton: true }}
        statusBar={{ statusPanels: [{ statusPanel: 'agAggregationComponent' }] }}
        cellSelection
      />
    </Box>
  );
};

export const Grid = forwardRef(GridComponent) as <T>(props: GridProps<T> & { ref?: ForwardedRef<GridRef<T>> }) => JSX.Element;
