import { AlertLevelEnum } from '@shared/protos/alerts';
import { getAlertLevelStyles } from '@shared/styles/alertStyles';
import * as React from 'react';

type Props = {
  level: AlertLevelEnum;
};

export const AlertLevelIcon: React.FC<Props> = ({ level }) => {
  const { className, color } = getAlertLevelStyles(level);

  return <i className={className} style={{ color, fontSize: '22px' }} />;
};
