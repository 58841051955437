import { dateService } from '@services/DateService';
import { toastifyService } from '@services/ToastifyService';
import { logger } from '@services/context';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useApi } from '@shared/hooks/useApi';
import { SETTLEMENT_PERIOD, SettlementPriceResponse, SettlementType } from '@shared/protos/settlementPrices';
import { ColumnMovedEvent } from 'ag-grid-enterprise';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridRef } from '../grid';
import { DefaultGridState, GridHandlers, GridSettings, RollingRowSettings } from '../shared/types';
import { getProducts, getTenors, getTenorsFirstIndexes, getTenorsFromRollingSettings } from '../shared/utils';
import { SettlementPricesRow } from './types';
import { mapSettlementColumns, mapSettlementRows } from './utils';

type ExtendedSettlementPriceGridState = {
  selectedSettlementTypes: SettlementType[];
  settlementDate: Date;
};
export type SettlementPriceGridState = DefaultGridState & ExtendedSettlementPriceGridState;

export type SettlementPriceGrid = GridSettings<SettlementPricesRow> &
  GridHandlers<SettlementPricesRow> & {
    toggleSettlementTypes: (settlementTypes: SettlementType[]) => void;
    handleSettlementDateChange: (settlementDate: Date) => void;
  } & ExtendedSettlementPriceGridState;

export function useSettlementGridState(
  selectedColumns: string[],
  selectedRows: string[],
  columnsOrder: string[],
  settlementTypes: SettlementType[],
  settlementDate: Date | string,
  userRollingRowSettings: RollingRowSettings,
  isOverrideRolling: boolean,
  widgetId: string
): SettlementPriceGrid {
  const gridRef = useRef<GridRef<SettlementPricesRow>>(null);
  const { apiClient } = useApi();
  const { editWidgetPayloadById } = useDashboardContext();
  const { userProducts, tenors } = useUserProductsAndTenorsContext();

  const [rowsData, setRowsData] = useState<SettlementPriceResponse[]>([]);
  const [state, setState] = useState<SettlementPriceGridState>({
    settlementDate: typeof settlementDate === 'string' ? new Date(settlementDate) : settlementDate,
    selectedSettlementTypes: settlementTypes,
    selectedColIds: selectedColumns,
    selectedRowIds: selectedRows,
    rollingRowSettings: userRollingRowSettings,
    isOverrideRolling,
    columnsOrder,
  });

  const selectedUserProducts = useMemo(() => getProducts(userProducts, state.selectedColIds), [userProducts, state.selectedColIds]);
  const viewableProductColumns = useMemo(
    () =>
      selectedUserProducts.filter(
        product => !product.underlying_symbol || (product.calendar_type === 'spread' && product.tenor_frequency !== 'quarterly')
      ),
    [selectedUserProducts]
  );
  const selectedTenors = useMemo(() => getTenors(tenors, state.selectedRowIds), [tenors, state.selectedRowIds]);
  const { firstWeekIndex, firstMonthIndex } = useMemo(() => getTenorsFirstIndexes(selectedTenors, tenors), [selectedTenors, tenors]);

  const onColumnMoved = useCallback(
    debounce(({ api }: ColumnMovedEvent<SettlementPricesRow>) => {
      const newColumnsOrder = [...new Set(api.getColumnState().map(({ colId }) => colId.split('|')[0]))];

      if (JSON.stringify(state.columnsOrder) === JSON.stringify(newColumnsOrder)) return;
      setState(prevState => ({
        ...prevState,
        columnsOrder: newColumnsOrder,
      }));
    }, 1500),
    [state.columnsOrder]
  );

  const toggleColumn = useCallback(
    (id: string) =>
      setState(prevState => ({
        ...prevState,
        selectedColIds: prevState.selectedColIds.includes(id)
          ? prevState.selectedColIds.filter(colId => colId !== id)
          : [...prevState.selectedColIds, id],
      })),
    []
  );

  const toggleRow = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      selectedRowIds: prevState.selectedRowIds.includes(id)
        ? prevState.selectedRowIds.filter(rowId => rowId !== id)
        : [...prevState.selectedRowIds, id],
    }));
  }, []);

  const addRow = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      selectedRowIds: prevState.selectedRowIds.includes(id) ? prevState.selectedRowIds : [...prevState.selectedRowIds, id],
    }));
  }, []);

  const updateRollingSettings = useCallback(
    (newSettings: RollingRowSettings) => {
      const newSelectedRowIds = getTenorsFromRollingSettings(tenors, newSettings).map(elem => elem.code);

      setState(prevState => ({
        ...prevState,
        rollingRowSettings: newSettings,
        selectedRowIds: newSelectedRowIds,
      }));
    },
    [tenors]
  );

  const addColumn = useCallback(
    (id: string) =>
      setState(prevState => ({
        ...prevState,
        selectedColIds: prevState.selectedColIds.includes(id) ? prevState.selectedColIds : [...prevState.selectedColIds, id],
      })),
    []
  );

  const toggleSettlementTypes = useCallback(
    (settlementTypes: SettlementType[]) => setState(prevState => ({ ...prevState, selectedSettlementTypes: settlementTypes })),
    []
  );

  const handleSettlementDateChange = useCallback(
    (settlementDate: Date) =>
      setState(prevState => ({
        ...prevState,
        settlementDate,
      })),
    []
  );

  const toggleIsOverrideRolling = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isOverrideRolling: !prevState.isOverrideRolling,
    }));
  }, []);

  useEffect(() => {
    editWidgetPayloadById(widgetId, {
      selectedColumns: state.selectedColIds,
      selectedRows: state.selectedRowIds,
      columnsOrder: state.columnsOrder,
      settlementDate: state.settlementDate,
      selectedSettlementTypes: state.selectedSettlementTypes,
      userRollingRowSettings: state.rollingRowSettings,
      isOverrideRolling: state.isOverrideRolling,
    });
  }, [state, widgetId]);

  useEffect(() => {
    const refreshSettlementPrices = async () => {
      if (selectedUserProducts.length === 0) return;

      try {
        await Promise.allSettled(
          state.selectedSettlementTypes.map(settlement =>
            apiClient?.getSettlementPrices({
              delta: '1',
              snapshot_date: dateService.formatDateStr(state.settlementDate),
              snapshot_period: SETTLEMENT_PERIOD[settlement],
              product_symbols: selectedUserProducts.map(product => product.symbol).join(','),
            })
          )
        ).then(res => {
          const allSettlements = res
            .map(result => {
              if (result.status === 'rejected') {
                toastifyService.showErrorMessage('Something went wrong fetching settlement prices');
                logger.error(result.reason);
              } else {
                return result.value;
              }
              return [];
            })
            .flatMap(value => value)
            .filter(Boolean) as SettlementPriceResponse[];

          setRowsData(allSettlements);
        });
      } catch (error: any) {
        toastifyService.showErrorMessage('Something went wrong fetching settlement prices');
        logger.error(error);
      }
    };

    refreshSettlementPrices();
  }, [state.settlementDate, state.selectedSettlementTypes, apiClient, selectedUserProducts]);

  useEffect(() => {
    const { rollingRowSettings, isOverrideRolling } = state;

    if (!isOverrideRolling) {
      const newSelectedRowIds = getTenorsFromRollingSettings(tenors, rollingRowSettings).map(elem => elem.code);

      setState(prevState => ({
        ...prevState,
        selectedRowIds: newSelectedRowIds,
      }));
    }
  }, [tenors, state.rollingRowSettings, state.isOverrideRolling]);

  return useMemo(
    () => ({
      ...state,
      columnDefs: {
        lockPinned: true,
      },
      gridRef,
      rows: mapSettlementRows(selectedTenors, selectedUserProducts, rowsData, firstMonthIndex, firstWeekIndex),
      columns: mapSettlementColumns(viewableProductColumns, state.selectedSettlementTypes, state.selectedColIds, state.columnsOrder),
      onColumnMoved,
      toggleColumn,
      toggleRow,
      toggleSettlementTypes,
      handleSettlementDateChange,
      addColumn,
      addRow,
      updateRollingSettings,
      toggleIsOverrideRolling,
      filteredSelectedTenors: selectedTenors,
      selectedUserProducts,
    }),
    [state, selectedTenors, selectedUserProducts, rowsData, firstMonthIndex, firstWeekIndex, viewableProductColumns]
  );
}
