import { WidgetProps } from '@features/dashboard/types';
import { ChartWidgetPayload } from '@protos/charts';
import Widget, { Content, Header } from '../Widget';
import Settings from '../settings/Settings';
import ChartProvider from './ChartContext';
import { ChartContainer } from './trading-view/ChartContainer';

type ChartWidgetProps = WidgetProps & {
  payload?: ChartWidgetPayload;
};

export default function Chart({ payload, ...otherProps }: ChartWidgetProps) {
  const { id, title } = otherProps;

  return (
    <Widget {...otherProps}>
      <ChartProvider widgetId={id} initialValues={payload ?? {}}>
        <Header title={title}>
          <Settings id={`chart-settings-${id}`} title={title} widgetId={id} />
        </Header>
        <Content>
          <ChartContainer widgetId={id} chartSettings={payload} />
        </Content>
      </ChartProvider>
    </Widget>
  );
}
