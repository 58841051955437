import { stream } from '@services/context';
import { useAsync } from 'react-use';
import { useAppAuthentication } from './useAppAuthentication';

export const useStream = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAppAuthentication();

  return useAsync(async () => {
    if (stream.isAuthenticated) return;
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      if (stream.authToken != token) await stream.authenticate(token);
    } else {
      throw new Error('Not authenticated');
    }
  }, [isAuthenticated]);
};
