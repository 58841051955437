import { DataApi } from '@shared/utils/data';
import { useCallback, useMemo, useState } from 'react';

export const useDataLoader = <T>(loader: DataApi<T>) => {
  const [data, setNewData] = useState<T[]>([]);
  const [dataLoader, setDataLoader] = useState<DataApi<T>>(loader);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const fetchData = useCallback(
    async (newFilters?: Record<string, any>, searchText?: string): Promise<void> => {
      setIsLoadingData(true);
      if (newFilters) dataLoader.resetQuery(newFilters);
      if (searchText) dataLoader.addSearchFilter(searchText);
      else if (searchText === '') dataLoader.addFilter({ search: undefined });

      if (!dataLoader.isDataLoading()) {
        await dataLoader.loadData();
        setNewData(dataLoader.data);
        setDataLoader(dataLoader);
      }
      setIsLoadingData(false);
    },
    [setNewData, setDataLoader]
  );

  const fetchMoreData = useCallback(async (): Promise<void> => {
    if (dataLoader && !dataLoader.isDataLoading() && dataLoader.hasMoreData()) {
      setIsLoadingData(true);
      fetchData();
      setIsLoadingData(false);
    }
  }, [dataLoader]);

  const resetFilters = useCallback(
    (newFilters: Record<string, any>) => {
      setIsLoadingData(true);
      if (dataLoader) dataLoader.resetQuery(newFilters);
      setIsLoadingData(false);
    },
    [dataLoader]
  );

  const simpleFetchData = useCallback(async () => {
    setIsLoadingData(true);
    if (!dataLoader.isDataLoading()) {
      await dataLoader.simpleLoadData();
      setNewData(dataLoader.data);
      setDataLoader(dataLoader);
    }
    setIsLoadingData(false);
  }, [setNewData, setDataLoader]);

  return useMemo(
    () => ({ data, resetFilters, fetchData, fetchMoreData, simpleFetchData, isLoadingData }),
    [data, resetFilters, fetchData, fetchMoreData, simpleFetchData, isLoadingData]
  );
};
