import { CircularProgress } from '@mui/material';
import theme from '@shared/themes/darkTheme';

type Props = {
  loading?: boolean;
};

export const CircularLoader = ({ loading }: Props) => {
  if (!loading) return null;

  return (
    <CircularProgress
      disableShrink
      sx={{
        color: theme.palette.background.buy,
        animationDuration: '600ms',
      }}
      size={15}
      thickness={2}
    />
  );
};
