import ManageAccountNavItem from '@features/base/private/components/ManageAccountNavItem';
import DashboardNavItem from '@features/dashboard/navbar/DashboardNavItem';
import WidgetNavItem from '@features/dashboard/navbar/WidgetNavItem';
import { Typography } from '@mui/material';
import Button, { ButtonProps, buttonClasses } from '@mui/material/Button';
import { LinkProps } from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import { PageId } from '@shared/protos/routes';
import { useActivePage } from '../../../shared/hooks/useActivePage';
import HelpMenuNavItem from './components/HelpMenuNavItem';

type MobileNavBarButtonProps = {
  icon: string;
  label: string;
  pageId?: PageId;
  target?: LinkProps['target'];
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: '2%',
  left: 10,
  right: 10,
  backgroundColor: theme.palette.background.lighter,
  borderRadius: 15,
  zIndex: 1000,
  width: '80%',
  maxWidth: 400,
  margin: '0 auto',
  border: `1px solid ${theme.palette.divider}`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: 55,
  width: 55,
  dispaly: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 0,
  margin: 0,
  padding: 0,
  lineHeight: 1,
  fontSize: 18,
  [`${buttonClasses.disabled}`]: {
    color: alpha(theme.palette.text.secondary, 0.3),
  },
}));

export function MobileNavBarButton({ icon, label, pageId, ...buttonProps }: MobileNavBarButtonProps & ButtonProps) {
  const { pageId: activePageId } = useActivePage();

  return (
    <StyledButton
      {...buttonProps}
      disableRipple
      disableTouchRipple
      disableFocusRipple
      disableElevation
      sx={{ color: pageId === activePageId ? 'text.primary' : 'text.secondary' }}
    >
      <i className={icon} />
      <Typography fontSize={10} fontWeight={700}>
        {label}
      </Typography>
    </StyledButton>
  );
}

export default function MobileNavBar() {
  return (
    <StyledPaper>
      <Stack direction="row" justifyContent="space-around" alignItems="center">
        <DashboardNavItem mobile />
        <WidgetNavItem mobile />
        <HelpMenuNavItem mobile />
        <ManageAccountNavItem mobile />
      </Stack>
    </StyledPaper>
  );
}
