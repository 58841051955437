import { FULLMO_TENOR_ID, TENOR_COL_ID } from '@protos/grids';
import { Product, ProductTenor } from '@protos/product';

export const getContractForSymbolInfo = (
  symbolId: string,
  rowData: any,
  productMap: Record<string, Product>,
  tenorMap: Record<string, ProductTenor>
): string | undefined => {
  const productId = symbolId.toLocaleLowerCase();
  const product = productMap[productId];
  const tenor = tenorMap[rowData.tenorId];

  let tenorId = rowData.tenorId;
  if (product?.calendar_type === 'spread') {
    tenorId = tenor.spread_code;
  } else if (tenor?.frequency === 'quarterly' || tenor?.frequency === 'yearly') {
    tenorId = tenor.frequency[0] + rowData.tenorId;
  }

  if (productId && tenorId && tenorId !== FULLMO_TENOR_ID && productId !== TENOR_COL_ID.toLocaleLowerCase()) {
    const isQuarterlySpread = product?.calendar_type === 'spread' && tenor?.frequency === 'quarterly';
    let contractId = `${productId}${tenorId}`;

    if (isQuarterlySpread) {
      const tail = product.symbol.substring(product.underlying_symbol.length, product.symbol.length);
      contractId = `${product.underlying_symbol}q${tail}${tenorId}`;
    }

    return contractId;
  }

  return undefined;
};
