import { Stack, StackProps, styled } from '@mui/material';
import React from 'react';
import { InlineGroupClassNames } from './InlineGroup';

type MultilineGroupProps = {
  children: React.ReactNode;
};

const StyledStack = styled(Stack)({
  [`& > .${InlineGroupClassNames.root}`]: {
    '&:not(:first-of-type):not(:last-of-type)': {
      borderRadius: 0,

      [`& > .${InlineGroupClassNames.child}`]: {
        borderRadius: 0,
      },
    },
    '&:first-of-type:not(:last-of-type)': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      [`& > .${InlineGroupClassNames.child}`]: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '&:last-of-type:not(:first-of-type)': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      [`& > .${InlineGroupClassNames.child}`]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },
});

export default function MultilineGroup({ children, ...rest }: MultilineGroupProps & StackProps) {
  return <StyledStack {...rest}>{children}</StyledStack>;
}
