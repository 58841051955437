import { useMediaQuery } from 'react-responsive';

export function useScreenSize() {
  // this should be in-sync with the specification in _variables.scss
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isMobileS = useMediaQuery({ query: '(max-width: 320px)' });
  const isMobileLandscape = useMediaQuery({ query: '(max-height: 500px)', orientation: 'landscape' });
  const isMediumDesktop = useMediaQuery({ query: '(max-width: 1240px)' });
  const isSmallTablet = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  return {
    isMobile,
    isMobileS,
    isMobileLandscape,
    isMediumDesktop,
    isSmallTablet,
    isTablet,
  };
}
