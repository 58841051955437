import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { useTradingKillSwitch } from '../../hooks/useTradingKillSwitch';
import { KillSwitchButton } from '../KillSwitchButton';
import { TradingOtcParamsForm } from './TradingOtcParamsForm';

export const TradingOtc = () => {
  const { killSwitch, onToggleKillSwitch } = useTradingKillSwitch('otc');

  return (
    <Stack gap={2} width="100%" p={2}>
      {killSwitch ? (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
          alignItems="end"
          sx={{ backgroundColor: theme.palette.background.darker, padding: 1, borderRadius: 1 }}
        >
          <FormControlLabel
            control={
              <KillSwitchButton
                sx={{ m: 1, right: -12 }}
                checked={killSwitch.active}
                onChange={(event, checked) => onToggleKillSwitch(event, checked)}
              />
            }
            label={`Kill Switch: ${killSwitch.active ? 'On' : 'Off'}`}
          />
          <Typography style={{ fontSize: 11, fontWeight: 800, textAlign: 'center' }}>
            Last triggered by {!killSwitch.user.full_name ? killSwitch.user.id : killSwitch.user.full_name} at {formatDatetime(killSwitch.timestamp)}
          </Typography>
        </Box>
      ) : null}
      <TradingOtcParamsForm />
    </Stack>
  );
};
