import { Box, debounce } from '@mui/material';
import { toLocaleISOString } from '@utils/date';
import { useCallback, useEffect, useState } from 'react';
import { DateTime } from './DateTime';

interface DateTimeRangeProps {
  value: { start: Date | undefined; end: Date | undefined };
  onChange: (newValue: { start: string | undefined; end: string | undefined }) => void;
}

export const DateTimeRange = ({ value, onChange }: DateTimeRangeProps) => {
  const [startDateTime, setStartDateTime] = useState<Date | undefined>(value?.start ? new Date(value.start) : undefined);
  const [endDateTime, setEndDateTime] = useState<Date | undefined>(value?.end ? new Date(value.end) : undefined);

  const onStartDateTimeChange = useCallback(
    debounce((newValue?: string) => {
      const updatedDateTimestamp = newValue ? new Date(Date.parse(newValue)) : undefined;
      setStartDateTime(updatedDateTimestamp);

      onChange({ start: newValue ?? undefined, end: endDateTime ? toLocaleISOString(endDateTime) : undefined });
    }, 500),
    [endDateTime]
  );

  const onEndDateTimeChange = useCallback(
    debounce((newValue?: string) => {
      setEndDateTime(newValue ? new Date(Date.parse(newValue)) : undefined);
      onChange({ start: startDateTime ? toLocaleISOString(startDateTime) : undefined, end: newValue ?? undefined });
    }, 500),
    [startDateTime]
  );

  useEffect(() => {
    setStartDateTime(value?.start ? new Date(value.start) : undefined);
    setEndDateTime(value?.end ? new Date(value.end) : undefined);
  }, [value]);

  return (
    <Box display="flex" gap={1}>
      <DateTime
        label="Start"
        value={startDateTime}
        maxDateTime={endDateTime}
        onChange={newDateTime => onStartDateTimeChange(newDateTime)}
        clearable
      />
      <DateTime label="End" value={endDateTime} minDateTime={startDateTime} onChange={newDateTime => onEndDateTimeChange(newDateTime)} clearable />
    </Box>
  );
};
