import { MobileNavBarButton } from '@features/base/private/MobileNavBar';
import { SidebarButton } from '@features/base/private/Sidebar';
import { HelpMenuPopup } from '@features/help-menu/HelpMenuPopup';
import { PageId } from '@shared/protos/routes';
import { useState } from 'react';

type Props = {
  mobile?: boolean;
};

export default function HelpMenuNavItem({ mobile }: Props) {
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openHelpMenuPopover = Boolean(helpMenuAnchorEl);
  const helpMenuPopoverId = openHelpMenuPopover ? 'help-menu-popover' : undefined;

  const handleOpenHelpMenu = (event: React.MouseEvent<HTMLButtonElement>) => setHelpMenuAnchorEl(event.currentTarget);

  const handleCloseHelpMenu = () => setHelpMenuAnchorEl(null);

  return (
    <>
      {mobile ? (
        <MobileNavBarButton
          onClick={handleOpenHelpMenu}
          icon="ri-question-line"
          label="Help"
          pageId={PageId.help}
          data-testid="help-menu-nav-item-mobile"
        />
      ) : (
        <SidebarButton title="Help" icon="ri-question-line" onClick={handleOpenHelpMenu} data-testid="help-menu-nav-item" />
      )}
      <HelpMenuPopup id={helpMenuPopoverId} anchorEl={helpMenuAnchorEl} open={openHelpMenuPopover} onClose={handleCloseHelpMenu} />
    </>
  );
}
