import { Paper, Stack, Tab, Tabs } from '@mui/material';
import NotFoundPage from '@pages/NotFoundPage';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AdvancedOCLTrading } from './AdvancedOCLTrading';
import { SimpleOCLTrading } from './SimpleOCLTrading';
import { AllOrdersGrid } from './components/AllOrdersGrid';
import { StyledAutocomplete } from './components/StyledAutocomplete';

type Tab = { label: string; value: string; Component: React.ComponentType<any> };
const OCLTradingTabs: Tab[] = [
  { label: 'Advanced', value: 'advanced', Component: AdvancedOCLTrading },
  { label: 'Simple', value: 'simple', Component: SimpleOCLTrading },
];

export const OCLTrading = () => {
  const user = useUserContext();

  const allowedOCLTrading = useMemo(() => {
    if (!user?.trade_limits.length) return false;
    return user.trade_limits.some(limit => limit.exchange.includes('onyx'));
  }, [user?.trade_limits]);

  const { activeTab: tabInURL } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState<Tab>(OCLTradingTabs[0]);
  const [selectedAccount, setSelectedAccount] = useState<string | ''>(user?.abn_trad_accounts?.[0] || '');

  const onTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      const tab = OCLTradingTabs.find(tab => tab.value === newValue);
      if (tab && tab.value !== tabInURL) {
        setSelectedTab(tab);
        setSearchParams({});
        navigate(`/ocl-trading/${tab.value}`);
      }
    },
    [tabInURL]
  );

  useEffect(() => {
    if (!tabInURL && selectedTab) {
      const params = searchParams.toString();
      navigate(`/ocl-trading/${selectedTab.value}${params ? `?${params}` : ''}`);
    } else if (!tabInURL && !selectedTab) {
      setSelectedTab(OCLTradingTabs[0]);
    } else if (tabInURL && !selectedTab) {
      const tab = OCLTradingTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    } else if (tabInURL && selectedTab && tabInURL !== selectedTab.value) {
      const tab = OCLTradingTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, [tabInURL, selectedTab, searchParams]);

  if (!allowedOCLTrading) return <NotFoundPage />;

  return (
    <Stack display="flex" flexDirection="column" flex={1} gap={1} padding={2} height="100%">
      <Stack display="flex" flexDirection="row" gap={1} alignItems="center">
        <Paper style={{ flex: 1, maxWidth: 'calc(100vw - 400px)', minWidth: 'calc(100vw - 400px)' }}>
          <Tabs value={selectedTab.value} onChange={onTabChange} variant="scrollable">
            {OCLTradingTabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} style={{ fontSize: 11 }} />
            ))}
          </Tabs>
        </Paper>
        <StyledAutocomplete
          options={user?.abn_trad_accounts || []}
          getOptionLabel={(option: unknown) => (option as string).toUpperCase()}
          value={selectedAccount}
          onChange={(_, newValue) => newValue && setSelectedAccount(newValue as string)}
          label="Account"
          isOptionEqualToValue={(option, value) => (option as string).toUpperCase() === (value as string).toUpperCase()}
        />
      </Stack>

      <Paper style={{ display: 'flex', flex: 1 }} key={selectedTab.value}>
        {React.createElement(selectedTab.Component, { selectedAccount, setSelectedAccount })}
      </Paper>

      <Paper style={{ height: '100%' }}>
        <AllOrdersGrid selectedAccount={selectedAccount} />
      </Paper>
    </Stack>
  );
};
