import { Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { StyledTitle } from '@shared/components/StyledComponents';

export const Header: React.FC<{ header: string; sx?: SxProps; helperText?: string }> = ({ header, sx, helperText }) => {
  return (
    <Stack direction="row" sx={sx} alignItems="center" gap={0.5}>
      <StyledTitle>{header}</StyledTitle>
      {helperText && (
        <Tooltip title={helperText} placement="right">
          <i className="ri-question-fill" />
        </Tooltip>
      )}
    </Stack>
  );
};
