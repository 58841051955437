import datafeed from '@features/dashboard/widgets/chart/trading-view/datafeed';
import { ChartContainerProps, INTERVAL_BACK_PERIODS, TimeUnit } from '@protos/charts';
import { ResolutionString, Timezone } from '@tradingview/types';

const timezone: Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;

export const chartDefaults: Omit<ChartContainerProps, 'container'> = {
  interval: '60' as ResolutionString,
  symbol: 'TICKER', // loading a fake symbol to simulate no product selected
  datafeed: datafeed,
  libraryPath: '/charting_library/',
  fullscreen: false,
  autosize: true,
  auto_save_delay: undefined,
  custom_css_url: '/charting_library/custom.css',
  studiesOverrides: {},
  symbol_search_request_delay: 1000,
  studies_access: {
    type: 'black',
    tools: [
      {
        name: '52 Week High/Low',
      },
      {
        name: 'Accelerator Oscillator',
      },
      {
        name: 'Average Price',
      },
      {
        name: 'Donchian Channels',
      },
      {
        name: 'Ichimoku Cloud',
      },
      {
        name: 'Parabolic SAR',
      },
      {
        name: 'Pivot Points Standard',
      },
      {
        name: 'Price Channel',
      },
      {
        name: 'Typical Price',
      },
      {
        name: 'Volatility O-H-L-C',
      },
    ],
  },
  theme: 'dark',
  timezone,
  debug: false,
  overrides: {
    // Main Series
    'mainSeriesProperties.showCountdown': false,
    'mainSeriesProperties.statusViewStyle.fontSize': 11,

    // Scales
    'scalesProperties.crosshairLabelBgColorDark': '#12141A',
    'scalesProperties.lineColor': '#999',
    'scalesProperties.textColor': '#999',
    'scalesProperties.fontSize': 11,

    // Pane
    'paneProperties.legendProperties.showBarChange': true,
    'paneProperties.legendProperties.showVolume': false,
    'paneProperties.background': '#12141A',
    'paneProperties.backgroundType': 'solid',
    'paneProperties.legendProperties.backgroundTransparency': 80,

    // Area
    'mainSeriesProperties.areaStyle.linecolor': '#ffffff',
    'mainSeriesProperties.areaStyle.transparency': 50,
    'mainSeriesProperties.areaStyle.color1': 'rgba(156, 240, 250, 1)',
    'mainSeriesProperties.areaStyle.color2': '#000',

    // Line
    'mainSeriesProperties.lineStyle.color': 'rgba(156, 240, 250, 50)',

    // Line with markers
    'mainSeriesProperties.lineWithMarkersStyle.color': 'rgba(156, 240, 250, 50)',

    // Step Line
    'mainSeriesProperties.steplineStyle.color': 'rgba(156, 240, 250, 50)',

    // Columns
    'mainSeriesProperties.columnStyle.upColor': 'rgba(156, 240, 250, 50)',
    'mainSeriesProperties.columnStyle.downColor': '#fff',
  },
  enabled_features: [
    'hide_left_toolbar_by_default',
    'side_toolbar_in_fullscreen_mode',
    'fix_left_edge',
    'seconds_resolution',
    'secondary_series_extend_time_scale',
  ],
  disabled_features: [
    'items_favoriting',
    'show_object_tree',
    'timezone_menu',
    'use_localstorage_for_settings',
    'save_chart_properties_to_local_storage',
    'header_saveload',
    'go_to_date',
  ],
};

export const getPeriodBackValue = (interval: ResolutionString): string => {
  const { period, unit } = INTERVAL_BACK_PERIODS[interval];

  switch (unit) {
    case TimeUnit.Second:
      return `${period * 1}S`;
    case TimeUnit.Minute:
      return `${period * 1}`;
    case TimeUnit.Hour:
      return `${period * 3}D`;
    case TimeUnit.Day:
      return `${period * 1}D`;
    case TimeUnit.Month:
      return `${period * 30}D`;
    default:
      return '1D';
  }
};
