import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useMemo } from 'react';
import { RollingRowSettings } from '../types';

const HIDDEN_FREQUENCIES = ['daily', 'weekly'];

export const DEFAULT_ALLOWED_PRICING_TENORS: RollingRowSettings = { daily: 0, weekly: 0, monthly: 12, quarterly: 4, yearly: 2 };
export const DEFAULT_ALLOWED_TRADING_TENORS: RollingRowSettings = { daily: 0, weekly: 0, monthly: 12, quarterly: 0, yearly: 0 };

export const usePricesDefaultFilters = () => {
  const { userProducts, tenors } = useUserProductsAndTenorsContext();

  const DEFAULT_ALLOWED_ALL_PRODUCTS = useMemo(
    () => userProducts.filter(product => !HIDDEN_FREQUENCIES.includes(product.tenor_frequency)),
    [userProducts]
  );

  const DEFAULT_ALLOWED_CRUDE_PRODUCTS = useMemo(
    () => DEFAULT_ALLOWED_ALL_PRODUCTS.filter(product => product.product_group === 'crude').slice(0, 20),
    [DEFAULT_ALLOWED_ALL_PRODUCTS]
  );

  const DEFAULT_ALLOWED_PRICING_PRODUCTS = useMemo(() => {
    const hasCrudeProducts = !!DEFAULT_ALLOWED_CRUDE_PRODUCTS.length;

    return hasCrudeProducts
      ? DEFAULT_ALLOWED_CRUDE_PRODUCTS.map(product => product.symbol)
      : DEFAULT_ALLOWED_ALL_PRODUCTS.slice(0, 20).map(product => product.symbol);
  }, [tenors, DEFAULT_ALLOWED_ALL_PRODUCTS]);

  return {
    DEFAULT_ALLOWED_PRICING_PRODUCTS,
    DEFAULT_ALLOWED_PRICING_TENORS,
    DEFAULT_ALLOWED_TRADING_TENORS,
  };
};
