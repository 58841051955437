import { useApi } from '@hooks/useApi';
import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import { SearchSymbolResponse } from '@protos/charts';
import { useCallback, useEffect, useState } from 'react';

interface SearchSymbolAutocompleteProps {
  value: SearchSymbolResponse;
  onChange: (value: SearchSymbolResponse) => void;
  label?: string;
}
export const SearchSymbolAutocomplete = ({ value, onChange, label }: SearchSymbolAutocompleteProps) => {
  const { apiClient } = useApi();
  const [options, setOptions] = useState<SearchSymbolResponse[]>([]);
  const [inputValue, setInputValue] = useState<string>(value.symbol || '');
  const [loading, setLoading] = useState(false);

  const fetchOptions = useCallback(
    async (searchQuery: string) => {
      if (!apiClient) return;

      const symbolsLoader = apiClient.symbolsLoader();

      try {
        setLoading(true);
        symbolsLoader.resetQuery({
          search: searchQuery || undefined,
        });
        const symbols = await symbolsLoader.loadData();
        setOptions(symbols);
      } catch (error) {
        console.error('Error fetching options:', error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    },
    [apiClient]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchOptions(inputValue);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  useEffect(() => {
    if (value.symbol) {
      fetchOptions(value.symbol);
    }
  }, []);

  return (
    <Autocomplete
      disableClearable
      options={options}
      role="autocomplete"
      filterOptions={() => options}
      getOptionLabel={option => option.symbol?.toLocaleUpperCase()}
      loading={loading}
      value={value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.symbol.toLocaleLowerCase() === value.symbol.toLocaleLowerCase()}
      ListboxProps={{ style: { fontSize: 11 } }}
      renderInput={params => (
        <TextField
          {...params}
          label={label || 'Search Symbol'}
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { fontSize: 11 } }}
          sx={{
            minWidth: 170,
            '& .MuiInputBase-input': {
              fontSize: 11,
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      componentsProps={{ popper: { style: { width: 'fit-content' } } }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.symbol} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} data-testid={option.symbol}>
            <Typography variant="body">{option.symbol?.toLocaleUpperCase()}</Typography>
            <Typography variant="inherit" color="GrayText">
              {option.description}
            </Typography>
          </li>
        );
      }}
    />
  );
};
