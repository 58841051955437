import { chartDefaults } from '@features/dashboard/widgets/chart/trading-view/chartDefaults';
import { DataFeedPermissions, RESOLUTION_STRING_MAP } from '@protos/charts';
import { config } from '@services/context';
import theme from '@shared/themes/darkTheme';
import {
  ChartingLibraryWidgetOptions,
  IBasicDataFeed,
  IDatafeedChartApi,
  IDatafeedQuotesApi,
  IExternalDatafeed,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
  ResolutionString,
  SeriesType,
  StudyLinePlotInfo,
  StudyLinePlotPreferences,
} from '@tradingview/types';

const DEFAULT_SERIES_STYLE: SeriesType = 3;

export function getWidgetOptions(
  symbol: string | undefined,
  chartType: SeriesType | undefined,
  interval: ResolutionString | undefined,
  container: HTMLDivElement | undefined,
  datafeed: IBasicDataFeed | (IDatafeedChartApi & IExternalDatafeed & IDatafeedQuotesApi),
  permissions: DataFeedPermissions
): ChartingLibraryWidgetOptions {
  return {
    symbol: symbol || chartDefaults.symbol,
    datafeed: datafeed,
    interval: interval || (chartDefaults.interval as ResolutionString),
    container: container as HTMLDivElement,
    library_path: chartDefaults.libraryPath as string,
    custom_css_url: chartDefaults.custom_css_url,
    locale: 'en',
    timezone: chartDefaults.timezone,
    disabled_features: chartDefaults.disabled_features,
    enabled_features: chartDefaults.enabled_features,
    fullscreen: chartDefaults.fullscreen,
    autosize: chartDefaults.autosize,
    studies_overrides: chartDefaults.studiesOverrides,
    symbol_search_request_delay: chartDefaults.symbol_search_request_delay,
    studies_access: chartDefaults.studies_access,
    theme: chartDefaults.theme,
    debug: chartDefaults.debug,
    charts_storage_url: config.apiUrl,
    time_frames: [
      { text: '5Y', resolution: RESOLUTION_STRING_MAP.OneDay, description: '5 year', title: '5Y' },
      { text: '1Y', resolution: RESOLUTION_STRING_MAP.OneDay, description: '1 year', title: '1Y' },
      { text: '1D', resolution: RESOLUTION_STRING_MAP.OneHour, description: '1 day', title: '1D' },
      { text: '12H', resolution: RESOLUTION_STRING_MAP.OneMinute, description: '12 hours', title: '12H' },
      { text: '6H', resolution: RESOLUTION_STRING_MAP.OneMinute, description: '6 hours', title: '6H' },
      { text: '1H', resolution: RESOLUTION_STRING_MAP.OneMinute, description: '1 hour', title: '1H' },
    ],
    overrides: {
      ...chartDefaults.overrides,
      'mainSeriesProperties.style': chartType || DEFAULT_SERIES_STYLE,
    },
    custom_font_family: 'Noto Sans',
    custom_indicators_getter:
      permissions.cot_product_groups.length > 0
        ? (PineJS: PineJS) =>
            Promise.resolve([
              {
                name: 'Onyx Entry Price',
                metainfo: {
                  id: 'Onyx Entry Price@tv-basicstudies-1' as RawStudyMetaInfoId,
                  description: 'Onyx Entry Price',
                  shortDescription: 'Onyx Entry Price (90D)',

                  isCustomIndicator: true,
                  is_price_study: true,
                  linkedToSeries: true,

                  format: { type: 'inherit', precision: 2 },

                  plots: [
                    {
                      id: 'plot_0',
                      type: 'line',
                    } as StudyLinePlotInfo,
                    {
                      id: 'plot_1',
                      type: 'line',
                    } as StudyLinePlotInfo,
                  ],
                  defaults: {
                    styles: {
                      plot_0: {
                        linestyle: 0,
                        visible: true,
                        linewidth: 2,
                        plottype: 2 as StudyLinePlotPreferences['plottype'],
                        trackPrice: true,
                        color: theme.palette.background.buy,
                        zorder: 1,
                      },
                      plot_1: {
                        linestyle: 0,
                        visible: true,
                        linewidth: 2,
                        plottype: 2 as StudyLinePlotPreferences['plottype'],
                        trackPrice: true,
                        color: theme.palette.background.sell,
                        zorder: 1,
                      },
                    },
                    inputs: {},
                  },
                  styles: {
                    plot_0: {
                      title: 'Long Entry Price',
                      histogramBase: 0,
                    },
                    plot_1: {
                      title: 'Short Entry Price',
                      histogramBase: 0,
                    },
                  },
                  inputs: [],
                },
                constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
                  this.main = function (context, inputCallback) {
                    this._context = context;
                    this._input = inputCallback;
                    if (!this._context.symbol.info.analytics) return null;

                    return [this._context.symbol.info.analytics.longEntry, this._context.symbol.info.analytics.shortEntry];
                  };
                },
              },
            ])
        : undefined,
  };
}
