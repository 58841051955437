export interface CounterParty {
  CounterParty: string;
  BlotteredMarketLongPosition: number;
  BlotteredMarketShortPosition: number;
  BlotteredMarketLongPosition7DayChange: number;
  BlotteredMarketShortPosition7DayChange: number;
  CPLongMS: number;
  CPShortMS: number;
  MarketLongPositionExtrap: number;
  MarketShortPositionExtrap: number;
  MarketLongPositionExtrap7DayChange: number;
  MarketShortPositionExtrap7DayChange: number;
  CPLongMSExtrap: number;
  CPShortMSExtrap: number;
  CounterPartyCount: number;
}

export interface CotDataResponse {
  AverageMS: number;
  FullName: string;
  OI: number;
  OI7DayChange: number;
  ProductSymbol: string;
  ReportDate: string;
  Exchanges: string[];
  CounterPartyData: CounterParty[];
}

interface CounterPartyValue {
  long: number;
  short: number;
}

export type CotDataRowKey = 'onekBarrels' | 'commitmentChange' | 'percentageOfOI' | 'noOfCounterparty';
export type CotDataRowSubKey = 'all' | 'old' | 'other';

export interface CotDataRow {
  rowId: string;
  symbol: string;
  key: CotDataRowKey;
  subKey: CotDataRowSubKey;
  oi: number | null;
  managedMoney: CounterPartyValue;
  swapDealer: CounterPartyValue;
  otherReportables: CounterPartyValue;
  producerMerchantProcessor: CounterPartyValue;
}

export const CotDataRowKeys: CotDataRowKey[] = ['onekBarrels', 'commitmentChange', 'percentageOfOI', 'noOfCounterparty'];

export const CotDataKeyMap: Record<CotDataRowKey, string> = {
  onekBarrels: '1000 Barrels',
  commitmentChange: 'Changes in Commitments from previous report',
  percentageOfOI: 'Percentage of Open Interested Represented by Each Category of Counterparty',
  noOfCounterparty: 'Number of Counterparty Types',
};

export interface CotData {
  rows: CotDataRow[];
  symbol: string;
  fullName: string;
  exchanges: string[];
  reportDate: string;
}
