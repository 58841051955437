import { Handle } from '@shared/protos/routes';
import { useMemo } from 'react';
import { useLocation, useMatches } from 'react-router-dom';

export function useTrackPage() {
  const matches = useMatches();
  const location = useLocation();

  const trackPage = useMemo(() => {
    const pageMatches = matches.map(match => ({ ...match, handle: match.handle as Handle })).filter(({ handle }) => handle?.trackPage !== undefined);

    const pageMatch = pageMatches.find(({ pathname }) => pathname === location.pathname);
    return pageMatch ? pageMatch.handle.trackPage : pageMatches.length > 0 ? pageMatches[pageMatches.length - 1].handle.trackPage : undefined;
  }, [matches, location]);

  return { trackPage: trackPage ?? true };
}
