import { streamRFQ } from '@services/context';
import { TickerCallback } from '@services/streamRFQService';
import { useCallback, useEffect, useRef } from 'react';

export const useStreamRFQ = (callback: TickerCallback, rfqSymbolToSubscribe: string, selectedExchange: string) => {
  const currentSubscribedSymbol = useRef<string>();

  const unsubscribeSymbol = useCallback(
    (symbolToUnsubscribe: string) => {
      const currentTradingSize = currentSubscribedSymbol.current?.split('-').at(-1);
      if (symbolToUnsubscribe && currentTradingSize) {
        streamRFQ.unsubscribe(`${symbolToUnsubscribe}-${currentTradingSize}`, selectedExchange);
      }
    },
    [selectedExchange]
  );

  useEffect(() => {
    const isValidSubscriptionSymbol = rfqSymbolToSubscribe.split('-').every(elem => !!elem);
    if (!rfqSymbolToSubscribe || !isValidSubscriptionSymbol) return;
    currentSubscribedSymbol.current = rfqSymbolToSubscribe;

    streamRFQ.subscribe(rfqSymbolToSubscribe, selectedExchange, callback);
  }, [rfqSymbolToSubscribe, selectedExchange, callback]);

  return { unsubscribeSymbol };
};
