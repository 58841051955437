import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ChartWidgetPayload, DataFeedPermissions } from '@protos/charts';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useApi } from '@shared/hooks/useApi';
import { useCallback, useEffect, useRef } from 'react';
import { useChartState } from '../ChartContext';
import TradingViewChart from './TradingViewChart';
import chartDatafeed from './datafeed';

export const StyledChartBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

type ChartProps = {
  widgetId: string;
  chartSettings?: ChartWidgetPayload;
};

export function ChartContainer({ widgetId, chartSettings }: ChartProps) {
  const user = useUserContext();
  const { apiClient } = useApi();
  const { editWidgetPayloadById } = useDashboardContext();
  const { userProducts, tenors } = useUserProductsAndTenorsContext();
  const { currentChartState, setCurrentChartState } = useChartState();

  const { chartState } = chartSettings ?? { chartState: undefined };

  const chartContainerRef = useRef<HTMLDivElement>();

  const handleChartSettingChange = useCallback(
    <T extends keyof ChartWidgetPayload>(key: T, newValue: ChartWidgetPayload[T]) => {
      setCurrentChartState({ value: newValue, needsRefresh: false });

      if (newValue) {
        editWidgetPayloadById(widgetId, { [key]: newValue });
      }
    },
    [chartState, currentChartState, widgetId]
  );

  useEffect(() => {
    if (currentChartState?.value !== chartState) {
      setCurrentChartState({ value: chartState, needsRefresh: true });
    }
  }, [chartState]);

  useEffect(() => {
    if (!apiClient || (currentChartState && !currentChartState?.needsRefresh) || !user) return;

    const dataFeedPermissions: DataFeedPermissions = { cot_product_groups: user.cot_product_groups ?? [] };

    const datafeed = chartDatafeed(widgetId, apiClient, userProducts, tenors, dataFeedPermissions);
    const chart = new TradingViewChart({
      containerRef: chartContainerRef.current,
      chartState: currentChartState?.value,
      datafeed,
      permissions: dataFeedPermissions,
    });

    if (!currentChartState && !chartSettings?.symbol) {
      chart.openSymbolSearch();
    }

    if (chartSettings?.symbol) {
      chart.addSymbolToChart(chartSettings.symbol);
    }

    chart.onAutoSaveNeeded(widgetId, newChartState => {
      handleChartSettingChange('chartState', newChartState);
    });
  }, [widgetId, apiClient, userProducts, tenors, currentChartState, user]);

  return <StyledChartBox ref={chartContainerRef} />;
}
