import { forwardRef } from 'react';
import Grow from '@mui/material/Grow';
import { TransitionProps } from '@mui/material/transitions';

export const GrowTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});
