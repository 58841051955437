import { WidgetType } from '@features/dashboard/enums';
import { Widget } from '@features/dashboard/types';
import { FULLMO_TENOR_ID } from '@protos/grids';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useFloatingChartState } from '@shared/contexts/FloatingChartContext';
import { convertIconToHTMLString } from '@utils/contextMenuUtils';
import { getContractForSymbolInfo } from '@utils/symbol';
import {
  CellClickedEvent,
  ContextMenuVisibleChangedEvent,
  GetContextMenuItemsParams,
  IRowNode,
  MenuItemDef,
  RowClassParams,
} from 'ag-grid-enterprise';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useWidgets } from '../useWidgets';
import { usePricesSettings } from './PricesContext';
import { FULLMO_TENOR_ROW, PricesRow } from './types';

export function usePricesGrid(rows: PricesRow[], filteredSelectedTenors: any[]) {
  const { addNewWidgets, currentDashboard } = useDashboardContext();
  const { productMap, tenorMap } = usePricesSettings();
  const { getWidgetIcon } = useWidgets();
  const { setCurrentFloatingChartState } = useFloatingChartState();
  const [recentlyClosedMenu, setRecentlyClosedMenu] = useState(false);

  const FullMoRow = useMemo(() => {
    if (productMap) {
      const fullMoRow = { ...FULLMO_TENOR_ROW };
      Object.keys(productMap).forEach(val => {
        fullMoRow[val] = '';
      });

      return fullMoRow;
    }
    return undefined;
  }, [productMap]);

  const getAllRows = useCallback(() => {
    if (rows && FullMoRow) {
      const BalMoIndex = rows.findIndex(row => row.tenorName.includes('BALMO'));
      if (BalMoIndex === -1) return rows;

      const allRows = [...rows];
      allRows.splice(BalMoIndex, 0, FullMoRow);
      return allRows;
    }

    return rows ?? [];
  }, [rows, FullMoRow]);

  const isFullMoRowPresentInBlotter = useMemo(() => {
    const allRows = getAllRows();
    if (FullMoRow) {
      return allRows?.some(row => row.tenorName.includes('FULLMO'));
    }

    return false;
  }, [getAllRows, FullMoRow]);

  const firstFrequencyRows = useMemo(() => {
    return filteredSelectedTenors.reduce((acc, tenor, index) => {
      if (index === 0) {
        const nextTenor = filteredSelectedTenors[index + 1];
        if (nextTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      } else {
        const prevTenor = filteredSelectedTenors[index - 1];
        if (prevTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      }

      return acc;
    }, [] as string[]);
  }, [filteredSelectedTenors]);
  const isJuneDecRow = (params: RowClassParams<PricesRow, any>) => {
    return params.data?.tenorFrequency === 'monthly' && (params.data.tenorName.includes('JUN') || params.data.tenorName.includes('DEC'));
  };

  const isFirstFrequencyRow = useCallback(
    (params: RowClassParams<PricesRow, any>) => {
      if (params.rowIndex === 0) return false;
      if (params.data?.tenorId === FULLMO_TENOR_ID) return true;

      if (isFullMoRowPresentInBlotter) {
        if (params.data?.tenorId && firstFrequencyRows.includes(params.data?.tenorId) && params.data?.tenorFrequency !== 'monthly') return true;
        return false;
      } else {
        if (params.data?.tenorId && firstFrequencyRows.includes(params.data?.tenorId)) return true;
        return false;
      }
    },
    [firstFrequencyRows, isFullMoRowPresentInBlotter]
  );

  const CONTEXT_MENU_COOLDOWN = 500;
  const onContextMenuVisibleChange = useCallback((event: ContextMenuVisibleChangedEvent) => {
    if (!event.visible) {
      setRecentlyClosedMenu(true);
      setTimeout(() => {
        setRecentlyClosedMenu(false);
      }, CONTEXT_MENU_COOLDOWN);
    }
  }, []);

  const getRowStyle = useCallback(
    (params: RowClassParams<PricesRow, any>) => {
      if (isFirstFrequencyRow(params)) {
        return { borderBottom: 'none', borderTop: '1px solid #f1c40f', ...(isJuneDecRow(params) ? { borderBottom: '1px solid grey' } : {}) };
      }

      if (isJuneDecRow(params)) {
        return { borderBottom: '1px solid grey', borderTop: '1px solid grey' };
      }

      return undefined;
    },
    [isFirstFrequencyRow, isJuneDecRow]
  );

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const colId = params.column?.getColId();
      if (params.value && productMap && tenorMap && colId) {
        const contractSymbol = getContractForSymbolInfo(colId, params.node?.data, productMap, tenorMap);

        if (contractSymbol) {
          const clickedSymbol = {
            symbol: contractSymbol,
            tenor_code: params.node?.data.tenorId,
          };

          const result: (string | MenuItemDef)[] = [
            'copy',
            {
              name: 'Plot Chart',
              icon: convertIconToHTMLString(WidgetType.Chart, getWidgetIcon),
              action: () => {
                addNewWidgets([
                  {
                    id: uuidv4(),
                    type: WidgetType.Chart,
                    payload: { symbol: clickedSymbol.symbol },
                  },
                ]);
              },
            },
            {
              name: 'Plot Seasonal Chart',
              action: () => {
                addNewWidgets([
                  {
                    id: uuidv4(),
                    type: WidgetType.SeasonalChart,
                    payload: { chartSymbol: clickedSymbol, chartFormula: '', isFormulasEnabled: false },
                  },
                ]);
              },
              icon: convertIconToHTMLString(WidgetType.SeasonalChart, getWidgetIcon),
            },
          ];
          return result;
        }
      }
      return ['copy'];
    },
    [addNewWidgets]
  );

  const onGridCellClicked = useCallback(
    (params: CellClickedEvent) => {
      const { data, colDef } = params;
      if (recentlyClosedMenu) {
        return;
      }
      // Check if a cell with a price is clicked
      if (params.value && productMap && tenorMap && colDef.colId) {
        const contractSymbol = getContractForSymbolInfo(colDef.colId, data, productMap, tenorMap);

        // Open floating chart widget
        if (contractSymbol) {
          const existingFloatingWidget = currentDashboard?.json?.widgets?.find((widget: Widget) => widget.type === WidgetType.FloatingChart);
          if (existingFloatingWidget) {
            setCurrentFloatingChartState({
              value: undefined,
              needsRefresh: true,
              symbol: contractSymbol,
            });
          } else {
            addNewWidgets([{ id: uuidv4(), type: WidgetType.FloatingChart, payload: { symbol: contractSymbol, chartState: undefined } }]);
          }
        }
      }
    },
    [productMap, tenorMap, setCurrentFloatingChartState, currentDashboard, addNewWidgets, recentlyClosedMenu]
  );

  const selectedRowIds = useMemo(() => filteredSelectedTenors.map(elem => elem.code), [filteredSelectedTenors]);

  const isExternalFilterPresent = useCallback(() => selectedRowIds.length > 0, [selectedRowIds]);
  const doesExternalFilterPass = useCallback(
    (rowNode: IRowNode<PricesRow>) => (rowNode.data ? [...selectedRowIds, FULLMO_TENOR_ID].includes(rowNode.data.tenorId) : true),
    [selectedRowIds]
  );

  return {
    getRowStyle,
    getContextMenuItems,
    onGridCellClicked,
    isExternalFilterPresent,
    doesExternalFilterPass,
    getAllRows,
    selectedRowIds,
    onContextMenuVisibleChange,
  };
}
