import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, styled, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'white',
  },
}));

interface FluxIconMenuProps {
  tooltip: string;
  width?: number;
  height?: number;
  menuList: { Icon?: React.ReactNode; label: string; onMenuItemClick: () => void }[];
  CustomAvatar?: React.ReactNode;
}

export const FluxIconMenu: React.FC<FluxIconMenuProps> = ({ tooltip, width, height, menuList, CustomAvatar }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = useMemo(() => !!anchorEl, [anchorEl]);

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton
          onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
          size="small"
          aria-controls={open ? `${tooltip}-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            '&:hover': {
              color: 'white',
            },
            fontSize: width || height || 20,
          }}
        >
          {CustomAvatar ? (
            <StyledAvatar sx={{ width: width || 20, height: height || 20 }}>{CustomAvatar}</StyledAvatar>
          ) : (
            <StyledAvatar sx={{ width: width || 20, height: height || 20 }} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id={`${tooltip}-menu`}
        open={open}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuList.map(({ Icon, label, onMenuItemClick }, index) => (
          <Stack key={`${label}-${index}`}>
            <MenuItem
              onClick={() => {
                onMenuItemClick();
                setAnchorEl(null);
              }}
              sx={{
                '&:hover': {
                  backgroundColor: '#142535 !important',
                },
              }}
            >
              {Icon && <ListItemIcon sx={{ fontSize: 15 }}>{Icon}</ListItemIcon>}
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                {label}
              </Typography>
            </MenuItem>
            {index !== menuList.length - 1 && <Divider style={{ marginTop: 0, marginBottom: 0 }} />}
          </Stack>
        ))}
      </Menu>
    </>
  );
};
