import { WidgetProps } from '@features/dashboard/types';
import { Box, Divider } from '@mui/material';
import { SettlementType } from '@shared/protos/settlementPrices';
import { useMemo } from 'react';
import Widget, { Content, Header } from '../Widget';
import Settings from '../settings/Settings';
import { SettingsFormResultLayout } from '../settings/SettingsFormResultLayout';
import { SettlementsProductsSettingsForm } from '../settings/products/SettlementsProductsSettingsForm';
import { SettlementsTenorsSettingsForm } from '../settings/tenors/SettlementsTenorsSettingsForm';
import SettlementPricesProvider from './SettlementPricesContext';
import SettlementDatePicker from './components/SettlementDatePicker';
import SettlementPricesGrid from './components/SettlementPricesGrid';
import ToggleSettlements from './components/ToggleSettlements';
import { SettlementPricesWidgetPayload } from './types';

const DEFAULT_PRICES_ROLLING_SETTINGS = { daily: 0, weekly: 0, monthly: 0, yearly: 0, quarterly: 0 };

type Props = WidgetProps & {
  payload?: SettlementPricesWidgetPayload;
};

export default function SettlementPrices({ payload, ...otherProps }: Props) {
  const { id, title } = otherProps;

  if (!payload) {
    return;
  }

  const {
    settlementDate,
    selectedSettlementTypes = [SettlementType.Flux],
    selectedColumns = [],
    selectedRows = [],
    columnsOrder = [],
    userRollingRowSettings = DEFAULT_PRICES_ROLLING_SETTINGS,
    isOverrideRolling = false,
  } = payload;

  const initialValues = useMemo(
    () => ({
      settlementDate,
      selectedSettlementTypes,
      selectedColumns,
      selectedRows,
      columnsOrder,
      userRollingRowSettings,
      isOverrideRolling,
    }),
    [settlementDate, selectedSettlementTypes, selectedColumns, selectedRows, columnsOrder, userRollingRowSettings, isOverrideRolling]
  );

  return (
    <Widget {...otherProps}>
      <SettlementPricesProvider widgetId={id} initialValues={initialValues}>
        <Header title={title}>
          <ToggleSettlements />
          <SettlementDatePicker />
          <Settings id={`settlement-prices-settings-${id}`} widgetId={id}>
            <Box display="flex" flex={1} flexDirection="row" gap={2} minHeight={800}>
              <SettingsFormResultLayout title="Products" Form={<SettlementsProductsSettingsForm />} />
              <Divider orientation="vertical" flexItem />
              <SettingsFormResultLayout title="Tenors" Form={<SettlementsTenorsSettingsForm />} />
            </Box>
          </Settings>
        </Header>
        <Content>
          <SettlementPricesGrid />
        </Content>
      </SettlementPricesProvider>
    </Widget>
  );
}
