import { WidgetProps } from '@features/dashboard/types';
import { MenuItem, Select } from '@mui/material';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import Widget, { Content, Header } from '../Widget';
import Settings from '../settings/Settings';
import TradingProvider from './TradingContext';
import { TradingPanelComponent } from './TradingPanelComponent';
import { TradingV2WidgetPayload } from './types';
import { useTradingAccounts } from './useTradingAccounts';

type TradingV2Props = WidgetProps & {
  payload?: TradingV2WidgetPayload;
};

export const TradingV2 = ({ payload, ...otherProps }: TradingV2Props) => {
  const { id, title } = otherProps;

  const user = useUserContext();
  const { tradingAccounts, selectedTradingAccount, setSelectedTradingAccount } = useTradingAccounts(user, payload?.selectedTradingAccount);

  if (!payload) return null;

  return (
    <Widget {...otherProps}>
      <TradingProvider>
        <Header title={title}>
          {selectedTradingAccount && tradingAccounts.length > 1 ? (
            <Select
              type=""
              size="small"
              autoWidth
              sx={{
                fontSize: 11,
                height: 20,
                width: '100%',
                borderRadius: 0,
                '& .MuiSelect-select': {
                  padding: 0,
                  paddingLeft: 0.5,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 20,
                  padding: 0,
                },
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 100, width: 70 } } }}
              value={selectedTradingAccount}
              onChange={(event: any) => setSelectedTradingAccount(event.target.value)}
              tabIndex={-1}
            >
              {tradingAccounts.map((account: string) => (
                <MenuItem key={account} value={account} sx={{ fontSize: 11, padding: 0, paddingLeft: 1.5 }}>
                  {account.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <></>
          )}
          <Settings id={`trading-settings-${id}`} title={title} widgetId={id} />
        </Header>
        {selectedTradingAccount && (
          <Content>
            <TradingPanelComponent id={id} payload={{ ...payload, selectedTradingAccount }} />
          </Content>
        )}
      </TradingProvider>
    </Widget>
  );
};
