import { MobileNavBarButton } from '@features/base/private/MobileNavBar';
import { SidebarButton } from '@features/base/private/Sidebar';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useCallback, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { WidgetType } from '../enums';
import { WidgetPayload } from '../types';
import { useWidgets } from '../widgets/useWidgets';
import SidebarMenu, { SidebarListItem } from './SidebarMenu';

type Props = {
  mobile?: boolean;
};

export function AvailableWidgets({ onClose }: { onClose?: () => void }) {
  const { menuItems } = useWidgets();
  const { addNewWidgets, currentDashboard } = useDashboardContext();

  const handleAddWidget = useCallback(
    async (type: WidgetType, defaultPayload?: WidgetPayload) => {
      addNewWidgets([{ id: uuidv4(), type, payload: defaultPayload }]);
      onClose?.();
    },
    [addNewWidgets, onClose]
  );

  if (!currentDashboard) return null;

  const dashboardWidgets = currentDashboard?.json?.widgets || [];
  const hasTradingWidget = dashboardWidgets.some(widget => widget.type === WidgetType.Trading);
  const hasTradingV2Widget = dashboardWidgets.some(widget => widget.type === WidgetType.TradingV2);
  const filteredMenuItems = menuItems.filter(item => {
    if (item.type === WidgetType.Trading && hasTradingV2Widget) return false;
    if (item.type === WidgetType.TradingV2 && hasTradingWidget) return false;
    return true;
  });

  return (
    <Stack padding="10px">
      <List disablePadding>
        {filteredMenuItems.map(item => (
          <SidebarListItem key={item.type} onClick={() => handleAddWidget(item.type, item.defaultPayload)} primary={item.displayName} />
        ))}
      </List>
    </Stack>
  );
}

export default function WidgetNavItem({ mobile }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onDashboard = useMatch('/dashboard/:id');

  return (
    <>
      {mobile ? (
        <MobileNavBarButton
          onClick={handleClick}
          icon="ri-add-circle-line"
          label="Widgets"
          data-testid="widget-nav-item-mobile"
          disabled={!onDashboard}
        />
      ) : (
        <SidebarButton title="Add widget" icon="ri-add-circle-line" onClick={handleClick} data-testid="widget-nav-item" disabled={!onDashboard} />
      )}
      <SidebarMenu id="sidebar-widgets-menu" anchorEl={anchorEl} onClose={handleClose} isMobile={mobile}>
        <AvailableWidgets onClose={handleClose} />
      </SidebarMenu>
    </>
  );
}
