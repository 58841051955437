export enum WidgetType {
  Pricing = 1,
  Chart = 2,
  Settlements = 3,
  FloatingChart = 4,
  Trading = 5,
  TradingBlotter = 6,
  News = 7,
  SeasonalChart = 8,
  TradingV2 = 9,
}

export enum WidgetBreakpoint {
  sm = 0,
  md = 1,
  lg = 2,
}

export enum SettingsTab {
  Tenors = 'tenors',
  Products = 'products',
}

export enum PriceSettlementType {
  Flux = 0,
  Singapore = 1,
  European = 2,
}
