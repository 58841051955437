import { addBusinessDays, addMilliseconds, addMinutes, format, parse, parseISO } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

const LONDON_TIMEZONE = 'Europe/London';

export const DATETIME_FORMAT = 'dd.MM.yyy HH:mm:ss';
export const DATETIME_FORMAT_YYYY_MM_DD = 'yyy-MM-dd HH:mm:ss';
export const DATETIME_FORMAT_WITH_MILLISECONDS = 'dd/MM/yyyy HH:mm:ss:SSS';

export const formatDatetime = (date: string) => format(parseISO(date), DATETIME_FORMAT);

export const formatDatetimeWithMilliseconds = (date?: string) => (date ? format(parseISO(date), DATETIME_FORMAT_WITH_MILLISECONDS) : '');

export const formatDatetimeYYYYMMDD = (date: string) => format(parseISO(date), DATETIME_FORMAT_YYYY_MM_DD);

export const formatToTimezone = (date: Date, timezone: string, formatStr = 'yyyy-MM-dd HH:mm:ss'): Date => {
  const zonedDate = formatInTimeZone(date, timezone, formatStr);
  return new Date(zonedDate);
};

export const formatDateTimeUTC = (date: string) => formatInTimeZone(date, 'UTC', DATETIME_FORMAT);

export const isLondonTimeZone = () => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return localTimezone === LONDON_TIMEZONE;
};

export const getLondonEndOfDayLabel = (date: Date, dateFormat = 'dd MMM yyyy'): string | undefined => {
  const londonDate = formatToTimezone(date, LONDON_TIMEZONE);
  return `(London: ${format(londonDate, dateFormat)} EOD)`;
};

export const getDateTimeDifference = (date1: string | number, date2: string | number): string => {
  const diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
  const diffMinutes = Math.ceil(diff / (1000 * 60));

  if (diffMinutes < 1) return '<1m';
  if (diffMinutes >= 60 && diffMinutes < 1440) return `${Math.floor(diffMinutes / 60)}h`;
  if (diffMinutes >= 1440) return `${Math.floor(diffMinutes / 1440)}d`;

  return `${diffMinutes}m`;
};

export const getDateInLondonTimezone = (date: string, format = 'yyyy-MM-dd HH:mm:ss'): string => {
  const attachedDate = parse(date, format, new Date());
  const offset = getTimezoneOffset(LONDON_TIMEZONE);
  const newDate = addMilliseconds(attachedDate, offset);

  return formatInTimeZone(newDate, LONDON_TIMEZONE, format);
};

export const getNow = () => new Date().toISOString();

export const getYesterday = (date: string) => addBusinessDays(new Date(date), -1).toISOString();

export const getStartOfDay = (date: string) => format(parseISO(date), 'yyyy-MM-dd 00:00:00');

export const getEndOfDay = (date: string) => format(parseISO(date), 'yyyy-MM-dd 23:59:59');

export const addMinutesToDateTime = (date: string, value: number) => addMinutes(new Date(date), value);

export const getDateYYYYMMDD = (date: Date) =>
  date.getFullYear() +
  '-' +
  (date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1) +
  '-' +
  (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate());

export const toLocaleISOString = (date: Date) => {
  const pad = (number: number) => (number < 10 ? '0' + number : number);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const addSecondsToNow = (seconds: number): string => {
  const now = Date.now();
  const futureTimestamp = now + seconds * 1000;

  return new Date(futureTimestamp).toISOString();
};
