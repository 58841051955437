import { WidgetProps } from '@features/dashboard/types';
import { useApi } from '@hooks/useApi';
import Widget, { Header } from '../Widget';
import Settings from '../settings/Settings';
import { NewsList } from './NewsList';
import { NewsWidgetPayload } from './newsTypes';

type NewsProps = WidgetProps & {
  payload?: NewsWidgetPayload;
};

export const NewsWidget = ({ payload, ...otherProps }: NewsProps) => {
  const { id, title } = otherProps;
  const { apiClient } = useApi();
  const newsDataLoader = apiClient?.newsLoader();

  if (!newsDataLoader) return null;

  return (
    <Widget {...otherProps}>
      <Header title={title}>
        <Settings id={`trading-settings-${id}`} title={title} widgetId={id} />
      </Header>
      <NewsList dataLoader={newsDataLoader} />
    </Widget>
  );
};
