import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { toastifyService } from '@services/ToastifyService';
import { logger } from '@services/context';
import { ErrorButton } from '@shared/components/Button';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { Dashboard } from '@shared/protos/dashboard';
import { useCallback } from 'react';
import ActionMenu, { ActionFooter, ActionHeader } from './DashboardActionsMenu';

type Props = {
  dashboard: Dashboard;
  onBack: () => void;
  onDelete: (close?: boolean) => void;
};

const StyledErrorButton = styled(ErrorButton)(({ theme }) => ({
  height: '34px',
  '& span': {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function DeleteDashboard({ dashboard, onBack, onDelete }: Props) {
  const { deleteDashboard, isDashboardLoading } = useDashboardContext();

  const handleDelete = useCallback(() => {
    try {
      deleteDashboard(dashboard.id.toString());
      onDelete(true);
    } catch (error: any) {
      toastifyService.showErrorMessage('Unable to delete dashboard, please try again');
      logger.error('Error deleting dashboard', error);
    }
  }, []);

  return (
    <ActionMenu>
      <ActionHeader onBack={onBack}>
        <Typography sx={{ padding: '12px 6px', paddingLeft: '32px' }}>{`Delete '${dashboard.name}'?`}</Typography>
      </ActionHeader>
      <ActionFooter>
        <StyledErrorButton text="Yes, Delete" onClick={handleDelete} isLoading={isDashboardLoading} />
      </ActionFooter>
    </ActionMenu>
  );
}
