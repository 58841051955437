import { User } from '@protos/user';
import { useEffect, useState } from 'react';

export const useTradingAccounts = (user: User | undefined, payloadValue?: string) => {
  const [tradingAccounts, setTradingAccounts] = useState<string[]>([]);
  const [selectedTradingAccount, setSelectedTradingAccount] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user) {
      setTradingAccounts(user.abn_trad_accounts);
    }
  }, [user]);

  useEffect(() => {
    if (tradingAccounts.length > 0) {
      setSelectedTradingAccount(payloadValue || tradingAccounts[0]);
    }
  }, [tradingAccounts]);

  return {
    tradingAccounts,
    selectedTradingAccount,
    setSelectedTradingAccount,
  };
};
