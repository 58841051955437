import { Box, styled } from '@mui/material';
import { RefObject, useCallback } from 'react';

const StyledChartBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

interface SeasonalChartContentProps {
  allLineSeries: Record<string, { label: string; isSelected: boolean; value: string }>;
  setAllLineSeries: (allLineSeries: Record<string, { label: string; isSelected: boolean; value: string }>) => void;
  isLoading: boolean;
  hasError: boolean;
  hasNoData: boolean;
  chartContainerRef: RefObject<any>;
  chartLineColorMap: Record<string, string>;
}

export const SeasonalChartContent = ({
  allLineSeries,
  setAllLineSeries,
  isLoading,
  hasError,
  hasNoData,
  chartContainerRef,
  chartLineColorMap,
}: SeasonalChartContentProps) => {
  const toggleSeriesVisibility = useCallback(
    (seriesKey: string) => {
      const newAllLineSeries = { ...allLineSeries };
      newAllLineSeries[seriesKey].isSelected = !newAllLineSeries[seriesKey].isSelected;
      setAllLineSeries(newAllLineSeries);
    },
    [allLineSeries]
  );

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom={1}>
        {Object.keys(allLineSeries).map(key => {
          const { value, isSelected, label } = allLineSeries[key];

          return (
            <Box key={key} display="flex" alignItems="center" marginLeft={1}>
              <input type="checkbox" checked={isSelected} onChange={() => toggleSeriesVisibility(key)} size={11} />
              <span style={{ color: chartLineColorMap[value], marginLeft: 5, fontSize: 11 }}>{label}</span>
            </Box>
          );
        })}
      </Box>
      {!isLoading ? (
        hasError ? (
          <Box color="error.main" display="flex" flex={1} alignItems="center" justifyContent="center">
            Symbol not found
          </Box>
        ) : !hasNoData ? (
          <StyledChartBox ref={chartContainerRef} />
        ) : (
          <Box display="flex" flex={1} alignItems="center" justifyContent="center">
            No data available
          </Box>
        )
      ) : (
        <Box display="flex" flex={1} alignItems="center" justifyContent="center">
          Loading seasonal chart data...
        </Box>
      )}
    </>
  );
};
