import { forwardRef, ReactNode, Ref } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { getScrollbarStyles, StyledMessage, StyledTitle } from './StyledComponents';

interface StyledRoundedBoxProps extends BoxProps {
  hiddenBorder?: boolean;
  error?: boolean;
}

const StyledRoundedBox = styled((props: StyledRoundedBoxProps) => <Box {...props} />, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'error' && propName !== 'hiddenBorder',
})(({ theme, hiddenBorder, error }) => ({
  position: 'relative',
  overflow: 'hidden',
  outline: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  ...(hiddenBorder && {
    outline: 'none',
  }),
  ...(error && {
    outline: `3px solid ${theme.palette.error.main}`,
  }),
}));

const RoundedBoxContent = styled(Box)(({ theme }) => ({
  padding: 13,
  paddingTop: 10,
  overflow: 'overlay',

  ...getScrollbarStyles(theme),
}));

type Props = {
  error?: boolean;
  hiddenBorder?: boolean;
  children?: ReactNode;
  header?: ReactNode;
  roundedBoxProps?: BoxProps;
  contentBoxProps?: BoxProps;
};

type RoundedBoxTitleProps = {
  label?: string;
  optionalText?: string;
};

const RoundedBox = forwardRef(
  ({ children, header, contentBoxProps, roundedBoxProps, ...otherProps }: Props, contentRef: Ref<unknown> | undefined) => {
    return (
      <StyledRoundedBox {...roundedBoxProps} {...otherProps}>
        {typeof header === 'string' ? <RoundedBoxHeader label={header} /> : header}
        <RoundedBoxContent height={header ? 'calc(100% - 30px)' : '100%'} {...contentBoxProps} ref={contentRef}>
          {children}
        </RoundedBoxContent>
      </StyledRoundedBox>
    );
  }
);

export function RoundedBoxHeader({ label, optionalText }: RoundedBoxTitleProps) {
  return (
    <Box display="flex" flexDirection="row" p="13px" pb="3px">
      {label ? <StyledTitle sx={{ flex: 1 }}>{label}</StyledTitle> : null}
      {optionalText ? <StyledMessage>{optionalText}</StyledMessage> : null}
    </Box>
  );
}

export default RoundedBox;
