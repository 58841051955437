import { useAdminApi } from '@hooks/useAdminApi';
import { useSnapshotsStreaming } from '@hooks/useSnapshotsStreaming';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { Snapshot } from '@protos/snapshots';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { useCallback, useState } from 'react';
import ReactJson from 'react-json-view';
import { useTradingKillSwitch } from '../../hooks/useTradingKillSwitch';
import { KillSwitchButton } from '../KillSwitchButton';
import { TradingHedgerParamsForm } from './TradingHedgerParamsForm';

export const TradingHedger = () => {
  const { apiClient } = useAdminApi();
  const { killSwitch, onToggleKillSwitch } = useTradingKillSwitch('hedger');

  const [snapshot, setSnapshot] = useState<Snapshot>();

  const onSnapshotStreaming = useCallback((snap: any) => {
    if (snap) {
      setSnapshot(snap);
    }
  }, []);

  useSnapshotsStreaming(onSnapshotStreaming, !!apiClient);

  return (
    <Stack gap={2}>
      {killSwitch ? (
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          width="100%"
          justifyContent="center"
          alignItems="end"
          sx={{ backgroundColor: theme.palette.background.darker, padding: 1 }}
        >
          <FormControlLabel
            control={
              <KillSwitchButton
                sx={{ m: 1, right: -12 }}
                checked={killSwitch.active}
                onChange={(event, checked) => onToggleKillSwitch(event, checked)}
              />
            }
            label={`Kill Switch: ${killSwitch.active ? 'On' : 'Off'}`}
          />
          <Typography style={{ fontSize: 11, fontWeight: 800, textAlign: 'center' }}>
            Last triggered by {!killSwitch.user.full_name ? killSwitch.user.id : killSwitch.user.full_name} at {formatDatetime(killSwitch.timestamp)}
          </Typography>
        </Box>
      ) : null}
      <Box display="flex" flex={1} justifyContent="center" gap={2}>
        <TradingHedgerParamsForm />
        <Box style={{ padding: 10, backgroundColor: theme.palette.background.darker, minWidth: '50%' }}>
          {snapshot ? <ReactJson src={snapshot} theme="google" style={{ fontSize: 11 }} name="snapshot" /> : 'Loading snapshot...'}
        </Box>
      </Box>
    </Stack>
  );
};
