import { useAuth0 } from '@auth0/auth0-react';
import { trackLogout } from '@services/user-activity';

/**
 * Custom React hook for handling authentication processes using Auth0.
 *
 * Provides methods for login and logout, along with tracking the authentication, loading state and other properties from `useAuth0`.
 *
 * @returns An object containing methods for login (`handleLogin`), logout (`handleLogout` and `handleLogoutRedirect`),
 * an `isLoading` flag indicating the loading state, and other properties inherited from `useAuth0`.
 *
 * @example
 * // Usage within a component
 * const { handleLogin, handleLogout, isLoading } = useAppAuthentication();
 */

export const useAppAuthentication = () => {
  const { loginWithRedirect, logout, ...props } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/dashboard',
      },
    });
  };

  const handleLogout = async () => {
    await trackLogout();
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleLogoutRedirect = async () => {
    await trackLogout();
    await handleLogout();
  };

  return {
    handleLogin,
    handleLogout,
    handleLogoutRedirect,
    ...props,
  };
};
