import { CircularProgress } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { StyledTooltip } from '@shared/components/StyledComponents';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { ReactElement, useRef, useState } from 'react';

type SidebarMenuProps = {
  children: ReactElement;
  onClose: () => void;
  isMobile?: boolean;
} & Omit<PopperProps, 'open'>;

interface CustomListItemProps extends ListItemTextProps {
  loading?: boolean;
  onClick: () => void;
}

type CustomListItemWithActionsProps = {
  dashboardId: number;
  label: string;
  onClick: () => void;
  onDelete: () => void;
  onEdit: () => void;
  isMobile?: boolean;
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  minWidth: '250px',
  maxWidth: '300px',
  background: theme.palette.background.lighter,
  borderRadius: theme.shape.borderRadius,
  transition: 'none !important',
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  paddingLeft: '8px',
  paddingRight: '60px!important', //30px each action icon
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: '6px',
  '& i': {
    fontSize: '18px',
  },
}));

function SidebarListItem({ onClick, loading, ...listItemProps }: CustomListItemProps) {
  return (
    <ListItem disablePadding secondaryAction={loading && <CircularProgress size={20} />}>
      <StyledListItemButton onClick={onClick}>
        <ListItemText {...listItemProps} />
      </StyledListItemButton>
    </ListItem>
  );
}

function SidebarListItemWithActions({ dashboardId, label, onClick, onDelete, onEdit, isMobile }: CustomListItemWithActionsProps) {
  const [openTooltip, setOpenTooltip] = useState(false);
  const textRef = useRef<HTMLSpanElement | null>(null);

  const handleMouseEnter = () => setOpenTooltip(textRef.current ? textRef.current.scrollWidth > textRef.current.clientWidth : false);
  const handleMouseLeave = () => setOpenTooltip(false);
  const { currentDashboard, allDashboards } = useDashboardContext();
  const isCurrent = currentDashboard?.id === dashboardId;
  const isDeleteHidden = allDashboards.length === 1;

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <Stack direction="row">
          <StyledIconButton onClick={onEdit}>
            <i className="ri-edit-box-line" />
          </StyledIconButton>
          {!isDeleteHidden && (
            <StyledIconButton onClick={onDelete}>
              <i className="ri-delete-bin-line" />
            </StyledIconButton>
          )}
        </Stack>
      }
      onMouseEnter={isMobile ? undefined : handleMouseEnter}
      onMouseLeave={isMobile ? undefined : handleMouseLeave}
      sx={{ '& .MuiListItemSecondaryAction-root': { right: '0px' } }}
    >
      <StyledTooltip title={label} open={openTooltip} enterDelay={100} placement="right">
        <StyledListItemButton
          onClick={onClick}
          disabled={isCurrent}
          sx={{
            opacity: '0.7',
            '&.Mui-disabled': {
              opacity: 1,
              backgroundColor: '#373f4a',
            },
          }}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              ref: textRef,
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </StyledListItemButton>
      </StyledTooltip>
    </ListItem>
  );
}

function SidebarMenu({ children, onClose, isMobile, ...popperProps }: SidebarMenuProps) {
  const { anchorEl } = popperProps;

  return (
    <StyledPopper
      open={Boolean(anchorEl)}
      placement={isMobile ? 'top' : 'right-start'}
      modifiers={[{ name: 'offset', enabled: true, options: { offset: [0, 5] } }]}
      {...popperProps}
    >
      <ClickAwayListener onClickAway={onClose}>
        <StyledPaper sx={isMobile ? undefined : { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>{children}</StyledPaper>
      </ClickAwayListener>
    </StyledPopper>
  );
}

export { SidebarListItem, SidebarListItemWithActions };
export default SidebarMenu;
