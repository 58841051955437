import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps, Theme, alpha, styled } from '@mui/material/styles';

type Props = {
  center?: boolean;
  children: React.ReactNode;
  error?: boolean;
  sx?: SxProps<Theme>;
};

export const StyledAlertLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body1,
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightBold,
  verticalAlign: 'baseline',
  '&:hover': {
    textDecoration: 'underline',
  },
})) as typeof Link;

export const StyledContentTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
}));

export default function Alert({ children, error, center, sx }: Props) {
  const boxSx = {
    padding: '8px',
    '& i': {
      fontSize: '18px',
      lineHeight: '20px',
    },
    ...(error && {
      border: theme => `3px solid ${theme.palette.error.main}`,
      borderRadius: theme => `${theme.shape.borderRadius}px`,
      backgroundColor: theme => alpha(theme.palette.error.main, 0.1),
      color: 'error.main',
    }),
    ...sx,
  };

  return (
    <Box sx={boxSx}>
      <Stack direction="row" spacing={1} justifyContent={center ? 'center' : undefined}>
        {error ? <i className="ri-error-warning-line ri-fw"></i> : null}
        <StyledContentTypography alignSelf="center">{children}</StyledContentTypography>
      </Stack>
    </Box>
  );
}
