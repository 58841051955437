import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, Typography } from '@mui/material';
import { Product } from '@protos/product';
import { useCallback, useState } from 'react';

interface EditProductDialogProps {
  product: Product;
  isOpen: boolean;
  onClose: () => void;
}

export const EditProductDialog: React.FC<EditProductDialogProps> = ({ product: productProp, isOpen, onClose }) => {
  const { apiClient } = useAdminApi();

  const [product, setProduct] = useState<Product>(productProp);

  const onUpdateProduct = useCallback(
    (newFields: { enabled: boolean; private: boolean }) => {
      if (apiClient && product) {
        apiClient.updateProduct(product.symbol, newFields).then(data => setProduct(data));
      }
    },
    [apiClient, product]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          const { enabled, private: isPrivate } = formJson;
          onUpdateProduct({ enabled: enabled === 'on', private: isPrivate === 'on' });
          onClose();
        },
      }}
    >
      <DialogTitle>Edit Product</DialogTitle>
      <DialogContent>
        <Box display="flex" marginRight={2} gap={1}>
          <Stack direction="row" alignItems="center">
            <Switch key={`${product.symbol}-enabled-switch`} id="name" name="enabled" size="small" defaultChecked={product.enabled} />
            <Typography fontSize={11}>Enabled</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Switch key={`${product.symbol}-private-switch`} id="name" name="private" size="small" defaultChecked={product.private} />
            <Typography fontSize={11}>Private</Typography>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} size="small" style={{ fontSize: 11 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" size="small" style={{ fontSize: 11 }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
