import { getWidgetOptions } from '@features/dashboard/widgets/chart/trading-view/widgetOptions';
import { DataFeedPermissions } from '@protos/charts';
import { logger, stringService } from '@services/context';
import {
  IBasicDataFeed,
  IChartingLibraryWidget,
  IDatafeedChartApi,
  IDatafeedQuotesApi,
  IExternalDatafeed,
  RangeOptions,
  ResolutionString,
  SeriesType,
  TimeFrameValue,
  VisibleTimeRange,
} from '@tradingview/types';
import { getPeriodBackValue } from './chartDefaults';

type TradingViewChartOptions = {
  containerRef: HTMLDivElement | undefined;
  isFloatingChart?: boolean;
  chartSymbol?: string;
  chartType?: SeriesType;
  chartInterval?: ResolutionString;
  chartState?: string;
  datafeed: IBasicDataFeed | (IDatafeedChartApi & IExternalDatafeed & IDatafeedQuotesApi);
  permissions: DataFeedPermissions;
};

type SavedLayout = {
  chartState: object;
};

class TradingViewChart {
  private readonly tvWidget: IChartingLibraryWidget;

  constructor(options: TradingViewChartOptions) {
    const { containerRef, chartSymbol, chartType, chartInterval, chartState, datafeed, isFloatingChart = false, permissions } = options;
    const widgetOptions = getWidgetOptions(chartSymbol, chartType, chartInterval, containerRef, datafeed, permissions);
    const chartWidgetOptions = isFloatingChart
      ? { ...widgetOptions, disabled_features: [...(widgetOptions.disabled_features ?? []), 'header_symbol_search'] }
      : widgetOptions;

    this.tvWidget = new (window as any).TradingView.widget(chartWidgetOptions);

    this.tvWidget.onChartReady(() => {
      if (chartState) {
        this.restoreLayout(chartState);
      }
    });
  }

  public openSymbolSearch() {
    this.tvWidget.onChartReady(() => {
      this.tvWidget.chart().executeActionById('symbolSearch');
    });
  }

  public addSymbolToChart(symbol: string) {
    this.tvWidget.onChartReady(() => {
      this.tvWidget.chart().setSymbol(symbol);
    });
  }

  public onAutoSaveNeeded(widgetId: string, callback: (stateToSave: string) => void) {
    this.tvWidget.onChartReady(() => {
      this.tvWidget.subscribe('onAutoSaveNeeded', () => {
        this.tvWidget.save(chartState => {
          const stateToSave = {
            chartState: chartState,
          };

          callback(JSON.stringify(stateToSave));
        });
      });
    });
  }

  public onIntervalChanged(
    callback: (
      interval: ResolutionString,
      timeframeObj?: {
        timeframe?: TimeFrameValue;
      }
    ) => void
  ) {
    this.tvWidget.onChartReady(() => {
      this.tvWidget
        .chart()
        .onIntervalChanged()
        .subscribe(null, (interval, timeframe) => {
          const timeFrame: RangeOptions = {
            val: { value: getPeriodBackValue(interval), type: 'period-back' as any },
            res: interval,
          };

          this.tvWidget.activeChart().setTimeFrame(timeFrame);
          callback(interval, timeframe);
        });
    });
  }

  public onVisibleRangeChanged(widgetId: string, callback: (stateToSave: string) => void) {
    this.tvWidget.onChartReady(() => {
      this.tvWidget
        .chart()
        .onVisibleRangeChanged()
        .subscribe(null, (range: VisibleTimeRange) => {
          //Save chart state but not for 1S resolution as too many updates
          if (this.tvWidget.chart().resolution() !== '1S') {
            this.tvWidget.save(chartState => {
              const stateToSave = {
                chartState: chartState,
              };

              callback(JSON.stringify(stateToSave));
            });
          }
        });
    });
  }

  public restoreLayout(savedLayoutString: string): void {
    this.tvWidget.onChartReady(() => {
      if (!savedLayoutString) {
        return;
      }
      const savedLayout = stringService.safeJsonParse<SavedLayout>(savedLayoutString);

      if (savedLayout && savedLayout.chartState) {
        this.tvWidget.load(savedLayout.chartState);
      } else {
        logger.error('Saved layout is missing state info', savedLayoutString);
      }
    });
  }

  public remove() {
    this.tvWidget.remove();
  }
}

export default TradingViewChart;
