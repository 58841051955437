import { AlertLevelEnum } from '@protos/alerts';

export const getAlertLevelStyles = (level: AlertLevelEnum) => {
  switch (level) {
    case 'critical':
      return { className: 'ri-close-circle-fill', color: '#FF6D46' };
    case 'info':
      return { className: 'ri-question-line', color: '#FFFFF2' };
    case 'warning':
      return { className: 'ri-error-warning-line', color: '#FF9900' };
    case 'error':
      return { className: 'ri-close-circle-line', color: '#FF6D46' };
    case 'unspecified':
      return { className: 'ri-circle-line', color: '#999999' };
    default:
      return { className: '', color: '' };
  }
};
