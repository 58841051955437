import { WidgetType } from '@features/dashboard/enums';
import { WidgetPayload } from '@features/dashboard/types';
import { amberOrange, buyBlue, buyBlueHover, sellRed, sellRedHover } from '@features/dashboard/widgets/trading-v2/constants';
import { useWidgets } from '@features/dashboard/widgets/useWidgets';
import { useAppAuthentication } from '@hooks/useAppAuthentication';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CandleStickChartIcon from '@mui/icons-material/CandlestickChart';
import CategoryIcon from '@mui/icons-material/Category';
import ClearIcon from '@mui/icons-material/Clear';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import HelpIcon from '@mui/icons-material/QuestionMark';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import TaskIcon from '@mui/icons-material/Task';
import ViewListIcon from '@mui/icons-material/ViewList';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Popper,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  styled,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dashboard } from '@protos/dashboard';
import { config, logger, stream } from '@services/context';
import { toastifyService } from '@services/ToastifyService';
import fluxAppLogoWhite from '@shared/assets/flux-app-logo-white.svg';
import { FluxIconButton } from '@shared/components/FluxIconButton';
import { FluxIconMenu } from '@shared/components/FluxIconMenu';
import { FluxTextMenu } from '@shared/components/FluxTextMenu';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { capitalizeFirstLetter, extractURLPageName } from '@utils/string';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const NETWORK_COLOR = { connected: 'success', reconnecting: 'warning', disconnected: 'error' };
type BadgeColor = 'success' | 'warning' | 'error';

const drawerWidth = 200;
const maxDashboardNameLength = 18;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface AdminMenuOption {
  name: string;
  path: string;
  Icon: ReactNode;
  disabled?: boolean;
}

type FluxAppBarProps = {
  children: ReactNode;
};

export const FluxAppBar = ({ children }: FluxAppBarProps) => {
  const user = useUserContext();
  const { handleLogout } = useAppAuthentication();
  const navigate = useNavigate();
  const {
    updateDashboard,
    deleteDashboard,
    addNewWidgets,
    addDashboard,
    currentDashboard: selectedDashboard,
    clearCurrentDashboard,
    allDashboards,
    isDashboardLoading,
    setCurrentDashboardById,
  } = useDashboardContext();
  const { menuItems, getWidgetIcon } = useWidgets();

  const currentUrl = window.location.href;
  const adminPageName = currentUrl.includes('admin') ? capitalizeFirstLetter(extractURLPageName(currentUrl)) : null;

  const [isAppbarOpen, setIsAppbarOpen] = useState(true);
  const clickTimerRef = useRef<NodeJS.Timeout | null>(null);

  const userInitials = useMemo(() => {
    if (!user?.full_name) return '';
    const nameParts = user.full_name.split(' ');
    return nameParts.length > 1 ? nameParts[0][0] + nameParts[nameParts.length - 1][0] : nameParts[0][0];
  }, [user?.full_name]);
  const networkStatus: BadgeColor = useMemo(() => NETWORK_COLOR[stream.status] as BadgeColor, [stream.status]);

  const allAdminMenuOptions: AdminMenuOption[] = useMemo(
    () => [
      { name: 'Products', path: 'products', Icon: <CategoryIcon sx={{ fontSize: 15 }} />, disabled: !user?.is_flux_admin },
      { name: 'Alerts', path: 'alerts', Icon: <ReportProblemIcon sx={{ fontSize: 15 }} />, disabled: !user?.is_flux_admin },
      { name: 'Settlements', path: 'settlements', Icon: <ViewListIcon sx={{ fontSize: 15 }} />, disabled: !user?.is_flux_admin },
      { name: 'Trading', path: 'trading', Icon: <CandleStickChartIcon sx={{ fontSize: 15 }} />, disabled: !user?.is_trading_admin },
      { name: 'Outliers', path: 'outliers', Icon: <AutoGraphIcon sx={{ fontSize: 15 }} /> },
      { name: 'Tasks', path: 'tasks', Icon: <TaskIcon sx={{ fontSize: 15 }} /> },
    ],
    [user?.is_flux_admin, user?.is_trading_admin]
  );

  const allSelecteDashboardWidgets = useMemo(() => selectedDashboard?.json?.widgets || [], [selectedDashboard]);
  const hasTradingWidget = useMemo(() => allSelecteDashboardWidgets.some(widget => widget.type === WidgetType.Trading), [allSelecteDashboardWidgets]);
  const hasTradingV2Widget = useMemo(
    () => allSelecteDashboardWidgets.some(widget => widget.type === WidgetType.TradingV2),
    [allSelecteDashboardWidgets]
  );
  const filteredMenuItems = useMemo(
    () =>
      menuItems
        .filter(item => {
          if (item.type === WidgetType.Trading && hasTradingV2Widget) return false;
          if (item.type === WidgetType.TradingV2 && hasTradingWidget) return false;
          return true;
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map(item => ({
          ...item,
          Icon: getWidgetIcon(item.type),
        })),
    [menuItems, hasTradingWidget, hasTradingV2Widget]
  );
  const allowedOCLTrading = useMemo(() => {
    if (!user?.trade_limits.length) return false;

    return user.trade_limits.some(limit => limit.exchange.includes('onyx'));
  }, [user?.trade_limits]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dashboardToDelete, setDashboardToDelete] = useState<Dashboard | null>(null);
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
  const [isEditingDashboard, setIsEditingDashboard] = useState<{ isEditing: boolean; id: number | null }>({ isEditing: false, id: null });
  const [editingDashboardName, setEditingDashboardName] = useState<string | undefined>();
  const [hoveredDashboardId, setHoveredDashboardId] = useState<number | null>(null);

  const isInvalidEditingDashboardName = useMemo(
    () => !editingDashboardName || allDashboards.some(d => d.id !== isEditingDashboard.id && d.name === editingDashboardName),
    [allDashboards, editingDashboardName, isEditingDashboard]
  );

  const onConfirmEditingChanges = useCallback(async () => {
    if (!isEditingDashboard.id || !editingDashboardName) return;

    const dashboardBeingEdited = allDashboards.find(d => d.id === isEditingDashboard.id);
    if (!dashboardBeingEdited) return;

    const transformedDashboard = {
      ...dashboardBeingEdited,
      name: editingDashboardName,
    };
    updateDashboard(transformedDashboard);

    setIsEditingDashboard({ isEditing: false, id: null });
    setEditingDashboardName(undefined);
  }, [isEditingDashboard, editingDashboardName, updateDashboard, allDashboards]);

  const onConfirmDeleteDashboard = useCallback(
    (dashboardId: number) => {
      deleteDashboard(dashboardId.toString());
    },
    [deleteDashboard]
  );

  const onCreateNewDashboard = useCallback(async () => {
    try {
      const dashboard = await addDashboard();

      navigate(`/dashboard/${dashboard.id}`);
    } catch (error: any) {
      toastifyService.showErrorMessage('Unable to create dashboard, please try again');
      logger.error('Error creating dashboard', error);
    }
  }, [navigate, addDashboard]);

  const onConfirmAddWidget = useCallback(
    async (type: WidgetType, defaultPayload?: WidgetPayload) => {
      addNewWidgets([{ id: uuidv4(), type, payload: defaultPayload }]);
    },
    [addNewWidgets]
  );

  const onDashboardClick = useCallback(
    (id: number) => {
      if (clickTimerRef.current) {
        clearTimeout(clickTimerRef.current);
        clickTimerRef.current = null;
      } else {
        clickTimerRef.current = setTimeout(() => {
          if (isEditingDashboard.isEditing) {
            setIsEditingDashboard({ isEditing: false, id: null });
            setEditingDashboardName(undefined);
          }

          setCurrentDashboardById(id.toString());
          navigate(`/dashboard/${id}`);
          clickTimerRef.current = null;
        }, 200);
      }
    },
    [navigate, isEditingDashboard, setCurrentDashboardById]
  );

  const onDashboardDoubleClick = useCallback(
    (id: number, event: React.MouseEvent<HTMLDivElement>) => {
      event?.stopPropagation();
      if (clickTimerRef.current) {
        clearTimeout(clickTimerRef.current);
        clickTimerRef.current = null;
      }

      setIsEditingDashboard({ isEditing: true, id: id });

      const dashboardName = allDashboards.find(d => d.id === id)?.name;
      if (dashboardName) {
        setEditingDashboardName(dashboardName);
      }
    },
    [allDashboards]
  );

  const onEditDashboardName = useCallback(
    (id: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      setIsEditingDashboard({ isEditing: true, id });
      const dashboardName = allDashboards.find(d => d.id === id)?.name;
      if (dashboardName) {
        setEditingDashboardName(dashboardName);
      }
    },
    [allDashboards]
  );

  useEffect(() => {
    if (selectedDashboard) {
      setEditingDashboardName(selectedDashboard.name);
    }
  }, [selectedDashboard]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={isAppbarOpen}>
        <Toolbar style={{ minHeight: 45, maxHeight: 45, paddingLeft: 10, paddingRight: 17 }}>
          <IconButton
            color="inherit"
            aria-label="Open Sidebar"
            onClick={() => setIsAppbarOpen(true)}
            edge="start"
            sx={[
              {
                mr: 2,
                fontSize: 20,
                color: !config.isDev ? (config.isUAT ? amberOrange : 'none') : buyBlue,
              },
              isAppbarOpen && { display: 'none' },
            ]}
          >
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>

          <Box display="flex" alignItems="center" gap={1}>
            {selectedDashboard && (
              <>
                <Typography variant="h6">{selectedDashboard.name}</Typography>
                <FluxTextMenu
                  label="+ Add New Widget"
                  menuList={filteredMenuItems.map(item => ({
                    Icon: item.Icon,
                    label: item.displayName,
                    onMenuItemClick: () => onConfirmAddWidget(item.type, item.defaultPayload),
                  }))}
                />
              </>
            )}
            {adminPageName && <Typography variant="h6">{`Admin - ${adminPageName}`}</Typography>}
          </Box>
          <Box display="flex" marginLeft="auto" alignItems="center">
            <FluxIconMenu
              tooltip="Help"
              CustomAvatar={<HelpIcon fontSize="small" sx={{ fontSize: 12 }} />}
              menuList={[
                {
                  Icon: <WhatsappIcon fontSize="small" sx={{ fontSize: 15 }} />,
                  label: 'Whatsapp',
                  onMenuItemClick: () => window.open('https://wa.me/447490073589', '_blank'),
                },
                {
                  Icon: <EmailIcon fontSize="small" sx={{ fontSize: 15 }} />,
                  label: 'Email',
                  onMenuItemClick: () => window.open('mailto:support@flux.live', '_blank'),
                },
                {
                  Icon: <PhoneIcon fontSize="small" sx={{ fontSize: 15 }} />,
                  label: 'Phone',
                  onMenuItemClick: () => window.open('tel:442081549880', '_blank'),
                },
                {
                  Icon: <SearchIcon fontSize="small" sx={{ fontSize: 15 }} />,
                  label: 'Lookup Symbols',
                  onMenuItemClick: () =>
                    window.open('https://docs.google.com/spreadsheets/d/1sqwqaFzOXnNnl4g6U4K9UGwAj70St-6eIZTbECr3RK8/edit?gid=0#gid=0', '_blank'),
                },
              ]}
              width={25}
              height={25}
            />
            <Badge
              color={networkStatus}
              variant="dot"
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <FluxIconMenu
                tooltip="Account"
                width={25}
                height={25}
                menuList={[
                  {
                    Icon: <SettingsIcon sx={{ fontSize: 15 }} fontSize="small" />,
                    label: 'Manage Account',
                    onMenuItemClick: () => navigate('/manage-account'),
                  },
                  {
                    Icon: <LogoutIcon sx={{ fontSize: 15 }} fontSize="small" color="error" />,
                    label: 'Logout',
                    onMenuItemClick: () => handleLogout(),
                  },
                ]}
                CustomAvatar={userInitials ? <Typography fontSize={12}>{userInitials}</Typography> : <AccountCircleIcon fontSize="small" />}
              />
            </Badge>
          </Box>
        </Toolbar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={isAppbarOpen}
        >
          <DrawerHeader style={{ minHeight: 45, maxHeight: 45 }}>
            <Box component="img" src={fluxAppLogoWhite} alt="Flux Logo" sx={{ height: 18, marginRight: 'auto' }} />
            <FluxIconButton
              Icon={ArrowCircleLeftIcon}
              label="Close Sidebar"
              onClick={() => setIsAppbarOpen(false)}
              sx={{ fontSize: 20, padding: 0 }}
            />
          </DrawerHeader>
          <Divider />
          {config.isDev && (
            <Box display="flex" justifyContent="center" alignContent="center" sx={{ backgroundColor: buyBlue }}>
              <Typography variant="caption" sx={{ fontSize: 10, marginRight: 1, color: 'white' }}>
                Development Environment
              </Typography>
            </Box>
          )}
          {config.isUAT && (
            <Box display="flex" justifyContent="center" alignContent="center" sx={{ backgroundColor: amberOrange }}>
              <Typography variant="caption" sx={{ fontSize: 10, marginRight: 1, color: 'white' }}>
                UAT Environment
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={1} height={10} marginBottom={1}>
            <Typography variant="caption" sx={{ fontSize: 10, marginLeft: 1 }}>
              ALL DASHBOARDS
            </Typography>
          </Box>
          <Button
            variant="outlined"
            size="small"
            onClick={() => onCreateNewDashboard()}
            sx={{ fontSize: 10, height: 25, padding: '0px  !important', marginLeft: 1, marginRight: 1 }}
          >
            + New Dashboard
          </Button>
          <Stack minHeight={70}>
            {!isDashboardLoading ? (
              allDashboards.length > 0 ? (
                <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                  {allDashboards
                    .sort((a, b) => a.id - b.id)
                    .map(dashboard => (
                      <ListItem key={dashboard.id} disablePadding>
                        <ListItemButton
                          selected={selectedDashboard ? dashboard.id === +selectedDashboard.id : false}
                          onClick={() => {
                            if (!isEditingDashboard.isEditing || (isEditingDashboard.isEditing && isEditingDashboard.id !== dashboard.id))
                              onDashboardClick(dashboard.id);
                          }}
                          onDoubleClick={event => {
                            if (!isEditingDashboard.isEditing || (isEditingDashboard.isEditing && isEditingDashboard.id !== dashboard.id))
                              onDashboardDoubleClick(dashboard.id, event);
                          }}
                          onMouseEnter={() => setHoveredDashboardId(dashboard.id)}
                          onMouseLeave={() => setHoveredDashboardId(null)}
                          sx={{
                            padding: 1,
                            '&:hover': { backgroundColor: '#142535 !important' },
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1,
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          {!isEditingDashboard.isEditing || (isEditingDashboard.isEditing && isEditingDashboard.id !== dashboard.id) ? (
                            <>
                              <DashboardIcon sx={{ fontSize: 15 }} />
                              <Tooltip title={dashboard.name.length > maxDashboardNameLength ? dashboard.name : undefined}>
                                <Typography variant="body1" sx={{ fontSize: 11, mb: 0.2 }}>
                                  {dashboard.name.length > maxDashboardNameLength
                                    ? dashboard.name.slice(0, maxDashboardNameLength) + '..'
                                    : dashboard.name}
                                </Typography>
                              </Tooltip>

                              {(hoveredDashboardId === dashboard.id || (dashboardToDelete && dashboardToDelete.id === dashboard.id)) && (
                                <Box display="flex" alignItems="center" gap={2} marginLeft="auto">
                                  <FluxIconButton
                                    Icon={EditIcon}
                                    label="Edit Dashboard Name"
                                    onClick={event => onEditDashboardName(dashboard.id, event)}
                                    sx={{
                                      fontSize: 15,
                                      padding: 0,
                                      opacity: hoveredDashboardId === dashboard.id ? 1 : 0,
                                      transition: 'opacity 0.3s ease-in-out',
                                    }}
                                  />
                                  <FluxIconButton
                                    Icon={DeleteIcon}
                                    label="Delete Dashboard"
                                    onClick={event => {
                                      event?.stopPropagation();
                                      setAnchorEl(event.currentTarget);
                                      setDashboardToDelete(dashboard);
                                    }}
                                    sx={{
                                      fontSize: 15,
                                      padding: 0,
                                      opacity: hoveredDashboardId === dashboard.id ? 1 : 0,
                                      transition: 'opacity 0.3s ease-in-out',
                                    }}
                                  />
                                </Box>
                              )}
                            </>
                          ) : (
                            <Box display="flex" alignItems="center" flex={1} justifyContent="space-between">
                              <TextField
                                size="small"
                                margin="normal"
                                variant="outlined"
                                sx={{
                                  maxWidth: 120,
                                  '& .MuiInputBase-input': {
                                    fontSize: 11,
                                    padding: 0.5,
                                    '&:focus': {
                                      borderColor: buyBlue,
                                    },
                                  },
                                  '& .MuiInputBase-root': {
                                    borderRadius: 0,
                                  },
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                                value={editingDashboardName}
                                onChange={e => setEditingDashboardName(e.target.value)}
                                onClick={e => e.stopPropagation()}
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!isInvalidEditingDashboardName) onConfirmEditingChanges();
                                  }
                                }}
                                error={isInvalidEditingDashboardName}
                              />
                              <Box display="flex" alignItems="center" gap={1} marginLeft="auto">
                                <Tooltip title="Discard Changes">
                                  <IconButton
                                    sx={{ fontSize: 15, padding: 0 }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      setIsEditingDashboard({ isEditing: false, id: null });
                                      setEditingDashboardName(undefined);
                                    }}
                                  >
                                    <ClearIcon sx={{ fontSize: 15, padding: 0, color: sellRed, '&:hover': { color: 'white' } }} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Confirm Changes">
                                  <IconButton
                                    sx={{ fontSize: 15, padding: 0 }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      onConfirmEditingChanges();
                                    }}
                                    disabled={isInvalidEditingDashboardName}
                                  >
                                    <DoneIcon
                                      sx={{
                                        fontSize: 15,
                                        padding: 0,
                                        color: isInvalidEditingDashboardName ? 'grey' : buyBlue,
                                        '&:hover': { color: 'white' },
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          )}
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              ) : (
                <Typography variant="caption" sx={{ fontSize: 11, marginLeft: 2, marginTop: 1, marginBottom: 1 }}>
                  No Dashboards Found
                </Typography>
              )
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={15} sx={{ alignSelf: 'center' }} />
              </Box>
            )}
          </Stack>

          {!config.isProd && allowedOCLTrading && (
            <>
              <Divider />
              <List>
                <ListItem key="ocl-trading" disablePadding>
                  <ListItemButton
                    selected={location.pathname.startsWith(`/ocl-trading`)}
                    onClick={() => {
                      clearCurrentDashboard();
                      navigate(`/ocl-trading`);
                    }}
                    sx={{
                      padding: 1,
                      '&:hover': { backgroundColor: '#142535 !important' },
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <CurrencyExchangeIcon sx={{ fontSize: 15 }} />
                    <Typography variant="body1" sx={{ fontSize: 11 }}>
                      OCL Trading
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          )}

          {user?.is_flux_admin && (
            <>
              <Divider />
              <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={1} height={10}>
                <Typography variant="caption" sx={{ fontSize: 10, marginLeft: 1 }}>
                  ADMIN MENU
                </Typography>
              </Box>

              <List>
                {allAdminMenuOptions
                  .filter(item => !item.disabled)
                  .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                  .map(adminMenuOption => (
                    <ListItem key={adminMenuOption.path} disablePadding>
                      <ListItemButton
                        selected={location.pathname.startsWith(`/admin/${adminMenuOption.path}`)}
                        onClick={() => {
                          clearCurrentDashboard();
                          navigate(`/admin/${adminMenuOption.path}`);
                        }}
                        sx={{
                          padding: 1,
                          '&:hover': { backgroundColor: '#142535 !important' },
                          display: 'flex',
                          flexDirection: 'row',
                          flex: 1,
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        {adminMenuOption.Icon}
                        <Typography variant="body1" sx={{ fontSize: 11, mb: 0.2 }}>
                          {adminMenuOption.name}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
              <Divider />
            </>
          )}

          <Box display="flex" justifyContent="center" marginTop="auto" alignContent="center">
            <Typography variant="caption" sx={{ fontSize: 9, textAlign: 'center' }}>
              © 2024 Onyx Hub. All Rights Reserved.
            </Typography>
          </Box>
        </Drawer>
        <Divider />
        <Stack maxHeight="97vh" minHeight="97vh" overflow="auto" width="100%" paddingBottom={1}>
          {children}
        </Stack>
      </AppBar>
      {currentUrl.includes('dashboard') && (
        <>
          <Backdrop open={isSpeedDialOpen} sx={{ color: '#fff', zIndex: theme => theme.zIndex.appBar + 1 }} />
          <SpeedDial
            ariaLabel="Add Widget"
            FabProps={{ size: 'small' }}
            sx={{
              position: 'fixed',
              bottom: 24,
              right: 16,
              zIndex: theme => theme.zIndex.appBar + 1,
              '& .MuiSpeedDial-fab': {
                bgcolor: buyBlue,
                right: '-40px ',
                '&:hover': {
                  bgcolor: buyBlueHover,
                },
              },
              '& .MuiSpeedDialAction-fab': {
                minWidth: 150,
                borderRadius: 0,
              },
            }}
            icon={<SpeedDialIcon />}
            onClose={() => setIsSpeedDialOpen(false)}
            onOpen={() => setIsSpeedDialOpen(true)}
            open={isSpeedDialOpen}
            direction="up"
          >
            {filteredMenuItems.map(widget => (
              <SpeedDialAction
                key={widget.type}
                icon={
                  <Box display="flex" alignItems="center" flex={1} gap={2} paddingLeft={1} paddingRight={1}>
                    <Typography sx={{ fontSize: 10, marginRight: 'auto' }}>{widget.displayName}</Typography>
                    {widget.Icon}
                  </Box>
                }
                onClick={() => onConfirmAddWidget(widget.type, widget.defaultPayload)}
              />
            ))}
          </SpeedDial>
        </>
      )}
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
          setDashboardToDelete(null);
        }}
      >
        <Popper
          id={anchorEl ? 'delete-dashboard-popper' : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          placement="right-start"
          sx={{
            zIndex: 10000,
          }}
        >
          <Stack sx={{ p: 1, bgcolor: 'background.paper', marginTop: 1, gap: 1 }}>
            <Stack>
              <Typography sx={{ fontSize: 11 }}>Delete dashboard - {dashboardToDelete?.name}?</Typography>
              <Typography variant="caption" sx={{ fontSize: 10 }}>
                This action cannot be undone.
              </Typography>
            </Stack>

            <Box display="flex" flex={1} justifyContent="end" gap={1} paddingLeft={12}>
              <Button variant="outlined" size="small" sx={{ fontSize: 9 }} onClick={() => setAnchorEl(null)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ fontSize: 9, backgroundColor: sellRed, '&:hover': { bgcolor: `${sellRedHover} !important`, color: `white !important` } }}
                onClick={() => {
                  if (dashboardToDelete) onConfirmDeleteDashboard(dashboardToDelete.id);
                  setAnchorEl(null);
                  setDashboardToDelete(null);
                }}
              >
                Delete
              </Button>
            </Box>
          </Stack>
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};
