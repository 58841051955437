import { styled } from '@mui/material';
import { ActionButton } from '@shared/components/Button';
import { useDashboardContext } from '@shared/contexts/DashboardContext';

type SaveButtonProps = {
  inputValue: string;
  currentDashboardName: string;
};

const StyledActionButton = styled(ActionButton)(({ theme }) => ({
  height: '34px',
  '& span': {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 25;

export default function EditDashboardSaveButton({ inputValue, currentDashboardName }: SaveButtonProps) {
  const { allDashboards } = useDashboardContext();
  const isNameInValid =
    inputValue === currentDashboardName ||
    allDashboards.find(elem => elem.name === inputValue || inputValue.length < NAME_MIN_LENGTH || inputValue.length > NAME_MAX_LENGTH);

  if (isNameInValid) {
    return <StyledActionButton type="submit" text="Save" disabled />;
  }

  return <StyledActionButton type="submit" text="Save" />;
}
