import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { ReactNode, useState } from 'react';

const border = '1px solid #2E3138';

const StyledPaper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginRight: '-5px',
    padding: '0 15px',
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundImage: 'unset',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  border,
  padding: '16px',
})) as typeof Stack;

const StyledArrow = styled('span')(({ theme }) => ({
  color: theme.palette.background.default,
  position: 'absolute',
  width: '20px',
  height: '20px',
  boxSizing: 'border-box',
  zIndex: 11,
  [theme.breakpoints.up('sm')]: {
    left: '-10px',
  },
  [theme.breakpoints.down('sm')]: {
    bottom: '-9px',
  },
  '&::before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: 'currentColor',
    transform: 'rotate(45deg)',
    [theme.breakpoints.up('sm')]: {
      borderBottom: border,
      borderLeft: border,
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: border,
      borderRight: border,
    },
  },
}));

type Props = {
  id?: string | undefined;
  open: boolean;
  isMobile?: boolean;
  children: ReactNode;
  anchorEl: any;
  onClose: (event: MouseEvent | TouchEvent) => void;
  arrow?: boolean;
  offset?: number[];
  placement?: PopperProps['placement'];
};

export default function Popup({ id, anchorEl, open, isMobile, children, onClose, arrow = false, offset = [], placement = 'right' }: Props) {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      placement={isMobile ? 'top-end' : placement}
      modifiers={[
        { name: 'offset', enabled: true, options: { offset } },
        { name: 'arrow', enabled: arrow, options: { element: arrowRef } },
      ]}
      sx={theme => ({ zIndex: theme.zIndex.modal })} // need to set this one high enough due pricing-sheet page that overlaps with z-index=999
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <StyledPaper>
            <ClickAwayListener onClickAway={onClose}>
              <StyledStack alignItems="center" spacing="9px" component={Paper}>
                {arrow && <StyledArrow ref={setArrowRef} />}
                {children}
              </StyledStack>
            </ClickAwayListener>
          </StyledPaper>
        </Fade>
      )}
    </Popper>
  );
}
