import { ColDef } from 'ag-grid-enterprise';
import { OutliersActionsCellRenderer } from './OutliersActionsCellRenderer';

export const useOutlierActionsColumns = <T>(columns: ColDef<T>[]) => {
  const actionsColumn: ColDef<T> = {
    headerName: 'Actions',
    cellRenderer: OutliersActionsCellRenderer,
    pinned: 'right',
  };

  return [...columns, actionsColumn];
};
