import { ReactNode, createContext, useContext, useState } from 'react';

type Props = {
  children: ReactNode;
  widgetId: string;
  initialValues: {
    chartState?: string;
  };
};

type ChartStateValue = { value: string | undefined; needsRefresh: boolean } | undefined;

type ChartContext = {
  currentChartState: ChartStateValue;
  setCurrentChartState: (newValue: ChartStateValue) => void;
};

const ChartContext = createContext<ChartContext>({ currentChartState: undefined, setCurrentChartState: () => {} });
ChartContext.displayName = 'ChartContext';

export default function ChartProvider({ widgetId, initialValues, ...otherProps }: Props) {
  const [currentChartState, setCurrentChartStateValue] = useState<ChartStateValue>(undefined);

  const setCurrentChartState = (newValue: ChartStateValue) => {
    setCurrentChartStateValue(newValue);
  };

  return <ChartContext.Provider value={{ currentChartState, setCurrentChartState }} {...otherProps} />;
}

export function useChartState(): ChartContext {
  const chartState = useContext(ChartContext);

  if (!chartState) {
    throw new Error('useChartState must be used within ChartProvider');
  }

  return chartState;
}
