import { ProductTenor } from '@protos/product';
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

type TradingTenorState = {
  widgetTenorMap: Record<string, { isSpread: boolean; tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[] }>;
  setWidgetTenorMap: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          isSpread: boolean;
          tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[];
        }
      >
    >
  >;
  getAllUsedTenors: (id: string, isSpread: boolean) => (ProductTenor | { left: ProductTenor; right: ProductTenor })[];
};

type Props = {
  children: ReactNode;
};

const TradingTenorContext = createContext<TradingTenorState | undefined>(undefined);
TradingTenorContext.displayName = 'TradingTenorContext';

export default function TradingTenorProvider({ ...otherProps }: Props) {
  const [widgetTenorMap, setWidgetTenorMap] = useState<
    Record<string, { isSpread: boolean; tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[] }>
  >({});

  const getAllUsedTenors = useCallback(
    (id: string, isSpread: boolean) => {
      const allUsedTenors: (ProductTenor | { left: ProductTenor; right: ProductTenor })[] = [];
      Object.entries(widgetTenorMap).forEach(([widgetId, { isSpread: spread, tenorsUsed }]) => {
        if (widgetId === id) return;
        if (spread === isSpread) {
          allUsedTenors.push(...tenorsUsed);
        }
      });
      return allUsedTenors;
    },
    [widgetTenorMap]
  );

  const value: TradingTenorState = useMemo(
    () => ({
      widgetTenorMap,
      setWidgetTenorMap,
      getAllUsedTenors,
    }),
    [widgetTenorMap, getAllUsedTenors]
  );

  return <TradingTenorContext.Provider value={value} {...otherProps} />;
}

export function useTradingTenorContext(): TradingTenorState {
  const context = useContext(TradingTenorContext);

  if (!context) {
    throw new Error('useTradingTenorContext must be used within a TradingTenorProvider');
  }

  return context;
}
