export type ChartSymbol = {
  symbol: string;
  product_symbol: string;
  description: string;
  tenor_code: string;
  tenor_month: string;
  tenor_year: string;
};

export type SearchSymbolResponse = {
  product_symbol: string;
  symbol: string;
  description: string;
  tenor_code: string;
  tenor_month: string;
  tenor_year: string;
  expiry: string | null;
  disabled: boolean;
  product_type: string;
};

export type SearchSymbolResult = {
  symbol: string;
  ticker: string;
  full_name: string;
  description: string;
  exchange: string;
  type: string;
}[];

export type SymbolInfo = {
  ticker: string;
  name: string;
  productSymbol: string;
  tenorCode: string;
  full_name: string;
  listed_exchange: string;
  format: string;
  description: string;
  type: string;
  session: string;
  timezone: string;
  exchange: string;
  minmov: number;
  pricescale: number;
  has_daily: boolean;
  has_weekly_and_monthly: boolean;
  has_intraday: boolean;
  has_seconds: boolean;
  supported_resolutions: string[];
  volume_precision: number;
  product_id: number;
  strip_id: number;
  data_status: string;
  widget_id: string;
  seasonalYear: string | undefined;
  isRollingContract: boolean;
};

import { ChartTickersEnum } from '@services/types';
import { ChartingLibraryWidgetOptions, ResolutionString } from '@tradingview/types';

export type ChartWidgetPayload = {
  chartState?: string;
  symbol?: string;
};

export enum TimeUnit {
  Second = 0,
  Minute = 1,
  Hour = 2,
  Day = 3,
  Week = 4,
  Month = 5,
}

export type Bars = {
  time: number;
  open: number;
  high: number;
  close: number;
  low: number;
}[];

export type SupportedResolutions = {
  supported_resolutions: string[];
};

export const INTERVAL_BACK_PERIODS = {
  '1D': { unit: TimeUnit.Month, period: 36 },
  '60': { unit: TimeUnit.Hour, period: 72 },
  '1': { unit: TimeUnit.Hour, period: 6 },
  '1S': { unit: TimeUnit.Minute, period: 60 },
};

export type ChartContainerProps = {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];
  datafeed: any;
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  auto_save_delay: ChartingLibraryWidgetOptions['auto_save_delay'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
  theme: ChartingLibraryWidgetOptions['theme'];
  debug: ChartingLibraryWidgetOptions['debug'];
  overrides: ChartingLibraryWidgetOptions['overrides'];
  enabled_features: ChartingLibraryWidgetOptions['enabled_features'];
  disabled_features: ChartingLibraryWidgetOptions['disabled_features'];
  timezone: ChartingLibraryWidgetOptions['timezone'];
  custom_css_url: ChartingLibraryWidgetOptions['custom_css_url'];
  studies_access: ChartingLibraryWidgetOptions['studies_access'];
  symbol_search_request_delay: ChartingLibraryWidgetOptions['symbol_search_request_delay'];
  studies_overrides?: ChartingLibraryWidgetOptions['studies_overrides'];
};

export type SearchSymbol = {
  symbol: string;
  ticker: string;
  full_name: string;
  description: string;
  exchange: string;
  type: string;
};

export const RESOLUTION_STRING_MAP: Record<string, ResolutionString> = {
  OneDay: '1D' as ResolutionString,
  OneHour: '1H' as ResolutionString,
  OneMonth: '1M' as ResolutionString,
  OneSecond: '1S' as ResolutionString,
  OneMinute: '1' as ResolutionString,
};

export type FloatingChartPayload = {
  symbol: string;
  chartState?: string;
};

export const API_RESOLUTION_MAP: Record<ResolutionString | string, ChartTickersEnum> = {
  '1s': '1s',
  '1': '1m',
  '60': '1h',
  '1d': '1d',
};

export interface DataFeedPermissions {
  cot_product_groups: string[];
}
