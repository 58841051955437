import { trackNavigation } from '@services/user-activity';
import { useEffect } from 'react';
import { useActivePage } from './useActivePage';
import { useBreadcrumbs } from './useBreadcrumbs';
import { useTrackPage } from './useTrackPage';

export function usePageTracking() {
  const { activeCrumb } = useBreadcrumbs();
  const { pageId } = useActivePage();
  const { trackPage } = useTrackPage();

  useEffect(() => {
    if (!pageId || !activeCrumb?.name || !trackPage) {
      return;
    }

    trackNavigation(activeCrumb.name);
  }, [pageId, activeCrumb?.name, trackPage]);
}
