import { Button, Divider, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

interface FluxTextMenuProps {
  menuList: { Icon?: React.ReactNode; label: string; onMenuItemClick: () => void }[];
  label: string;
}

export const FluxTextMenu: React.FC<FluxTextMenuProps> = ({ label, menuList }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = useMemo(() => !!anchorEl, [anchorEl]);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        aria-controls={open ? `${label}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          fontSize: 10,
          padding: '0px 5px !important',
        }}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id={`${label}-menu`}
        open={open}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuList.map(({ Icon, label, onMenuItemClick }, index) => (
          <Stack key={`${label}-${index}`}>
            <MenuItem
              onClick={() => {
                onMenuItemClick();
                setAnchorEl(null);
              }}
              sx={{
                '&:hover': {
                  backgroundColor: '#142535 !important',
                },
              }}
            >
              {Icon && <ListItemIcon sx={{ fontSize: 13 }}>{Icon}</ListItemIcon>}
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {label}
              </Typography>
            </MenuItem>
            {index !== menuList.length - 1 && <Divider style={{ marginTop: 0, marginBottom: 0 }} />}
          </Stack>
        ))}
      </Menu>
    </>
  );
};
