import { Box } from '@mui/material';
import { CreateLadderRequest } from '@protos/ladders';
import { Side } from '@protos/trading';
import { addSecondsToNow } from '@utils/date';
import { CustomCellEditorProps } from 'ag-grid-react';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLaddersApi } from '../hooks/useLaddersApi';
import NumberInput from './NumberInput';

export const OrderInputCellEditor = memo((props: CustomCellEditorProps) => {
  const { createLadder, expireLadder } = useLaddersApi();
  const bidInputRef = useRef<HTMLDivElement>(null);
  const offerInputRef = useRef<HTMLDivElement>(null);
  const { bid, offer } = props.value ? props.value : { bid: '', offer: '' };

  const [bidValue, setBidValue] = useState<string>(bid);
  const [offerValue, setOfferValue] = useState<string>(offer);

  const onKeyDown = useCallback(
    debounce(async (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (event.key === 'Tab') {
        event.preventDefault();

        const bidInputElement = bidInputRef.current?.querySelector('input');
        const offerInputElement = offerInputRef.current?.querySelector('input');

        if (event.target === bidInputElement) {
          offerInputElement?.focus();
          offerInputElement?.select();
        } else if (event.target === offerInputElement) {
          bidInputElement?.focus();
          bidInputElement?.select();
        }
      } else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        const currentFocusedCell = props.api.getFocusedCell();

        if (currentFocusedCell) {
          let nextRowIndex = currentFocusedCell.rowIndex;
          let nextColumn = currentFocusedCell.column;

          if (event.key === 'ArrowUp') {
            nextRowIndex -= 1;
          } else if (event.key === 'ArrowDown') {
            nextRowIndex += 1;
          } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            const allColumns = props.api.getAllDisplayedColumns();
            const currentColumnIndex = allColumns.findIndex(column => column.getColId() === currentFocusedCell.column.getColId());

            if (event.key === 'ArrowLeft' && currentColumnIndex > 0) {
              nextColumn = allColumns[currentColumnIndex - 1];
            } else if (event.key === 'ArrowRight' && currentColumnIndex < allColumns.length - 1) {
              nextColumn = allColumns[currentColumnIndex + 1];
            }
          }

          if (nextRowIndex < 0 || nextRowIndex >= props.api.getDisplayedRowCount()) return;

          const futureCellRow = document.querySelector(`.ag-row[row-index="${nextRowIndex}"] .ag-cell[col-id="${nextColumn.getId()}"]`);

          if (futureCellRow?.classList.contains('no-content')) return;

          props.api.setFocusedCell(nextRowIndex, nextColumn.getId());
        }
      } else if (event.key === 'Escape') {
        props.stopEditing();
        props.api.clearFocusedCell();
      } else if (event.key === 'Enter' && props.colDef.field) {
        const dataKeyToUpdate = props.colDef.field;
        const newRow = {
          ...props.node.data,
          [dataKeyToUpdate]: { bid: bidValue ? +bidValue : undefined, offer: offerValue ? +offerValue : undefined },
        };

        if (!bidValue && !offerValue) {
          const symbol =
            dataKeyToUpdate === 'value' ? `${newRow.symbol}${newRow.id}` : `${newRow.symbol}${newRow.id}-${newRow.symbol}${dataKeyToUpdate}`;

          await expireLadder(symbol, props.node.data.account_id || '');
        } else {
          const ladderCreateRequestPayload: CreateLadderRequest = {
            symbol: dataKeyToUpdate === 'value' ? `${newRow.symbol}${newRow.id}` : `${newRow.symbol}${newRow.id}-${newRow.symbol}${dataKeyToUpdate}`,
            expiry_timestamp: addSecondsToNow(+newRow.validity),
            orders: [
              ...(+bidValue ? [{ price: +bidValue, side: Side.BUY, amount: +newRow.size, hidden: false }] : []),
              ...(+offerValue ? [{ price: +offerValue, side: Side.SELL, amount: +newRow.size, hidden: false }] : []),
            ],
            account_id: props.node.data.account_id || '',
          };

          await createLadder(ladderCreateRequestPayload);
        }

        props.api.applyTransactionAsync({
          update: [newRow],
        });

        const allColumns = props.api.getAllDisplayedColumns();
        const currentColumnIndex = allColumns.findIndex(column => column.getColId() === props.colDef.field);

        const nextColumn = currentColumnIndex < allColumns.length - 1 ? allColumns[currentColumnIndex + 1] : allColumns[currentColumnIndex];

        props.api.setFocusedCell(dataKeyToUpdate === 'value' ? (props.node.rowIndex || 0) + 1 : props.node.rowIndex || 0, nextColumn.getColId());
      }
    }, 100),
    [props, bidValue, offerValue, createLadder]
  );

  useEffect(() => {
    if (!props.value) return;

    const { bid, offer } = props.value;
    setBidValue(bid);
    setOfferValue(offer);
  }, [props.value]);

  useEffect(() => {
    const inputElement = bidInputRef.current?.querySelector('input');
    inputElement?.focus();
    inputElement?.select();
  }, []);

  return (
    <Box display="flex" width="100%" height="100%">
      <NumberInput
        ref={bidInputRef}
        value={bidValue?.toString() || ''}
        onChange={(val: number | undefined) => setBidValue(val?.toString() || '')}
        placeholder="Bid"
        onKeyDown={onKeyDown}
        min={-99999}
        sx={{
          '& .MuiInputBase-input': {
            color: '#4189E8',
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 25,
          },
        }}
      />
      <NumberInput
        ref={offerInputRef}
        value={offerValue?.toString() || ''}
        onChange={(val: number | undefined) => setOfferValue(val?.toString() || '')}
        placeholder="Offer"
        min={-99999}
        onKeyDown={onKeyDown}
        sx={{
          '& .MuiInputBase-input': {
            color: '#CD4B4B',
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 25,
          },
        }}
      />
    </Box>
  );
});
