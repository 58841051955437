import { useApi } from '@shared/hooks/useApi';
import { User } from '@shared/protos/user';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export const UserContext = createContext<User | undefined>(undefined);
UserContext.displayName = 'UserContext';

export default function UserContextProvider(props: any) {
  const { apiClient } = useApi();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    async function fetchUser() {
      const newUser = await apiClient?.getUser();
      setUser(newUser);
    }

    fetchUser();
  }, [apiClient, setUser]);

  return <UserContext.Provider value={user} {...props} />;
}

export function useUserContext(): User | undefined {
  const user = useContext(UserContext);

  return useMemo(() => user, [user]);
}
