import { useAdminApi } from '@hooks/useAdminApi';
import { KillSwitch } from '@protos/trading';
import { useCallback, useState } from 'react';
import { useAsync } from 'react-use';

export const useTradingKillSwitch = (killSwitchType: 'otc' | 'hedger') => {
  const { apiClient } = useAdminApi();
  const [killSwitch, setKillSwitch] = useState<KillSwitch>();

  const onToggleKillSwitch = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, newCheckedValue: boolean) => {
      const newKillSwitch = await apiClient?.setKillSwitch(newCheckedValue, killSwitchType);
      if (!newKillSwitch) return;

      setKillSwitch(newKillSwitch);
    },
    [apiClient]
  );

  useAsync(async () => {
    if (!apiClient) return;

    const killSwitch = await apiClient.getKillSwitch(killSwitchType);

    setKillSwitch(killSwitch);
  }, [apiClient]);

  return { killSwitch, onToggleKillSwitch };
};
