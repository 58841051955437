import theme from '@shared/themes/darkTheme';
import { ColorType, LineData, Time, createChart } from 'lightweight-charts';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

export interface ExecutionVolumeDataValue {
  data: any[];
  isLoading: boolean;
}

export interface ExecutionVolumeData {
  otc: ExecutionVolumeDataValue;
  otc_client: ExecutionVolumeDataValue;
  exchange: ExecutionVolumeDataValue;
}
const chartMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const useExecutionVolumeGrids = (chartData: ExecutionVolumeData) => {
  const otcChartRef = useRef<any>();
  const otcChartContainerRef = useRef<HTMLDivElement>();

  const otcClientChartRef = useRef<any>();
  const otcClientChartContainerRef = useRef<HTMLDivElement>();

  const exchangeChartRef = useRef<any>();
  const exchangeChartContainerRef = useRef<HTMLDivElement>();

  const chartContainerRef = useRef<HTMLDivElement>(null);

  // OTC CHART - CREATION AND STYLING LOGIC
  useEffect(() => {
    if (!otcChartContainerRef.current || !chartContainerRef.current || chartData.otc.isLoading) return;

    otcChartRef.current = createChart(otcChartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: window.innerHeight / 3.5,
      layout: { background: { type: ColorType.Solid, color: theme.palette.background.darker }, textColor: 'white' },
      grid: { horzLines: { visible: false }, vertLines: { visible: false } },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: theme.palette.background.lighter,
        fixRightEdge: true,
        fixLeftEdge: true,
        uniformDistribution: true,
      },
    });

    const totalVolumeLineSeries = otcChartRef.current.addLineSeries();
    const spreadVolumeLineSeries = otcChartRef.current.addLineSeries();
    const outrightVolumeLineSeries = otcChartRef.current.addLineSeries();

    const totalVolumeLineData = chartData.otc.data.map(
      ({ timestamp, total_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: total_volume,
        } as LineData<Time>)
    );
    const spreadVolumeLineData = chartData.otc.data.map(
      ({ timestamp, spread_volume, outright_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: spread_volume,
        } as LineData<Time>)
    );
    const outrightVolumeLineData = chartData.otc.data.map(
      ({ timestamp, outright_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: outright_volume,
        } as LineData<Time>)
    );

    totalVolumeLineSeries.applyOptions({
      color: '#F79F1F',
      lineWidth: 2,
      title: 'Total Volume',
      priceLineVisible: false,
    });
    spreadVolumeLineSeries.applyOptions({
      color: '#A3CB38',
      lineWidth: 2,
      title: 'Spread Volume',
      priceLineVisible: false,
    });
    outrightVolumeLineSeries.applyOptions({
      color: '#1289A7',
      lineWidth: 2,
      title: 'Outright Volume',
      priceLineVisible: false,
    });

    totalVolumeLineSeries.setData(totalVolumeLineData);
    spreadVolumeLineSeries.setData(spreadVolumeLineData);
    outrightVolumeLineSeries.setData(outrightVolumeLineData);

    const visibleRange = otcChartRef.current.timeScale().getVisibleLogicalRange();
    if (visibleRange) {
      const from = new Date();
      const to = new Date(from);
      from.setDate(1);
      from.setMonth(0);
      to.setFullYear(to.getFullYear() + 1);
      otcChartRef.current.timeScale().setVisibleRange({ from: (from.getTime() / 1000) as Time, to: (to.getTime() / 1000) as Time });
    }

    return () => {
      otcChartRef.current.remove();
    };
  }, [chartData.otc.data, chartData.otc.isLoading]);

  // OTC CLIENT CHART - CREATION AND STYLING LOGIC
  useEffect(() => {
    if (!otcClientChartContainerRef.current || !chartContainerRef.current || chartData.otc_client.isLoading) return;

    otcClientChartRef.current = createChart(otcClientChartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: window.innerHeight / 3.5,
      layout: { background: { type: ColorType.Solid, color: theme.palette.background.darker }, textColor: 'white' },
      grid: { horzLines: { visible: false }, vertLines: { visible: false } },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: theme.palette.background.lighter,
        fixRightEdge: true,
        fixLeftEdge: true,
        uniformDistribution: true,
      },
    });

    const totalVolumeLineSeries = otcClientChartRef.current.addLineSeries();
    const spreadVolumeLineSeries = otcClientChartRef.current.addLineSeries();
    const outrightVolumeLineSeries = otcClientChartRef.current.addLineSeries();

    const totalVolumeLineData = chartData.otc_client.data.map(
      ({ timestamp, total_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: total_volume,
        } as LineData<Time>)
    );
    const spreadVolumeLineData = chartData.otc_client.data.map(
      ({ timestamp, spread_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: spread_volume,
        } as LineData<Time>)
    );
    const outrightVolumeLineData = chartData.otc_client.data.map(
      ({ timestamp, outright_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: outright_volume,
        } as LineData<Time>)
    );

    totalVolumeLineSeries.applyOptions({
      color: '#F79F1F',
      lineWidth: 2,
      title: 'Total Volume',
      priceLineVisible: false,
    });
    spreadVolumeLineSeries.applyOptions({
      color: '#A3CB38',
      lineWidth: 2,
      title: 'Spread Volume',
      priceLineVisible: false,
    });
    outrightVolumeLineSeries.applyOptions({
      color: '#1289A7',
      lineWidth: 2,
      title: 'Outright Volume',
      priceLineVisible: false,
    });

    totalVolumeLineSeries.setData(totalVolumeLineData);
    spreadVolumeLineSeries.setData(spreadVolumeLineData);
    outrightVolumeLineSeries.setData(outrightVolumeLineData);

    const visibleRange = otcClientChartRef.current.timeScale().getVisibleLogicalRange();
    if (visibleRange) {
      const from = new Date();
      const to = new Date(from);
      from.setDate(1);
      from.setMonth(0);
      to.setFullYear(to.getFullYear() + 1);
      otcClientChartRef.current.timeScale().setVisibleRange({ from: (from.getTime() / 1000) as Time, to: (to.getTime() / 1000) as Time });
    }

    return () => {
      otcClientChartRef.current.remove();
    };
  }, [chartData.otc_client.data, chartData.otc_client.isLoading]);

  // EXCHANGE CHART - CREATION AND STYLING LOGIC
  useEffect(() => {
    if (!exchangeChartContainerRef.current || !chartContainerRef.current || chartData.exchange.isLoading) return;

    exchangeChartRef.current = createChart(exchangeChartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: window.innerHeight / 3.5,
      layout: { background: { type: ColorType.Solid, color: theme.palette.background.darker }, textColor: 'white' },
      grid: { horzLines: { visible: false }, vertLines: { visible: false } },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: theme.palette.background.lighter,
        fixRightEdge: true,
        fixLeftEdge: true,
        uniformDistribution: true,
      },
    });

    const totalVolumeLineSeries = exchangeChartRef.current.addLineSeries();
    const spreadVolumeLineSeries = exchangeChartRef.current.addLineSeries();
    const outrightVolumeLineSeries = exchangeChartRef.current.addLineSeries();

    const totalVolumeLineData = chartData.exchange.data.map(
      ({ timestamp, total_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: total_volume,
        } as LineData<Time>)
    );
    const spreadVolumeLineData = chartData.exchange.data.map(
      ({ timestamp, spread_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: spread_volume,
        } as LineData<Time>)
    );
    const outrightVolumeLineData = chartData.exchange.data.map(
      ({ timestamp, outright_volume }) =>
        ({
          time: new Date(timestamp).getTime() / 1000,
          value: outright_volume,
        } as LineData<Time>)
    );

    totalVolumeLineSeries.applyOptions({
      color: '#F79F1F',
      lineWidth: 2,
      title: 'Total Volume',
      priceLineVisible: false,
    });
    spreadVolumeLineSeries.applyOptions({
      color: '#A3CB38',
      lineWidth: 2,
      title: 'Spread Volume',
      priceLineVisible: false,
    });
    outrightVolumeLineSeries.applyOptions({
      color: '#1289A7',
      lineWidth: 2,
      title: 'Outright Volume',
      priceLineVisible: false,
    });

    totalVolumeLineSeries.setData(totalVolumeLineData);
    spreadVolumeLineSeries.setData(spreadVolumeLineData);
    outrightVolumeLineSeries.setData(outrightVolumeLineData);

    const visibleRange = exchangeChartRef.current.timeScale().getVisibleLogicalRange();
    if (visibleRange) {
      const from = new Date();
      const to = new Date(from);
      from.setDate(1);
      from.setMonth(0);
      to.setFullYear(to.getFullYear() + 1);
      exchangeChartRef.current.timeScale().setVisibleRange({ from: (from.getTime() / 1000) as Time, to: (to.getTime() / 1000) as Time });
    }

    return () => {
      exchangeChartRef.current.remove();
    };
  }, [chartData.exchange.data, chartData.exchange.isLoading]);

  useEffect(() => {
    if (!otcChartRef.current || !otcClientChartRef.current || !exchangeChartRef.current) return;

    const resizeObserver = new ResizeObserver(
      debounce(entries => {
        entries.forEach(entry => {
          const { contentBoxSize, contentRect } = entry;
          let width = 0;

          if (contentBoxSize) {
            const boxSizeData = Array.isArray(contentBoxSize) ? contentBoxSize[0] : contentBoxSize;
            width = boxSizeData.inlineSize;
          } else {
            width = contentRect.width;
          }

          const marginedChartWidth = width - 80;
          unstable_batchedUpdates(() => {
            otcChartRef.current.applyOptions({ width: marginedChartWidth < 0 ? 0 : marginedChartWidth });
            otcClientChartRef.current.applyOptions({ width: marginedChartWidth < 0 ? 0 : marginedChartWidth });
            exchangeChartRef.current.applyOptions({ width: marginedChartWidth < 0 ? 0 : marginedChartWidth });
          });
        });
      }, 200)
    );

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [otcChartRef, otcClientChartRef, exchangeChartRef]);

  return {
    otcChartContainerRef,
    otcClientChartContainerRef,
    exchangeChartContainerRef,
    chartContainerRef,
  };
};
