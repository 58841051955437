import { debounce, FormControl, InputAdornment, InputLabel, OutlinedInput, styled } from '@mui/material';
import { IconComponent } from '@shared/components/IconComponent';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

type Props = {
  value: string;
  hideLabel?: boolean;
  miniSearch?: boolean;
  maxWidth?: string;
  onSearch: (text: string) => void;
  endAdornment?: React.ReactNode;
};

const StyledAdornment = styled(InputAdornment)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const Search: React.FC<Props> = ({ value, onSearch, hideLabel, miniSearch, maxWidth, endAdornment }) => {
  const [searchValue, setSearchValue] = useState(value);

  const debouncedOnSearch = useCallback(
    debounce(value => {
      onSearch(value);
    }, 300),
    [onSearch]
  );

  const onChangeSearchValue = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = event.target.value;
      setSearchValue(newValue);
      debouncedOnSearch(newValue);
    },
    [debouncedOnSearch]
  );

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  return (
    <FormControl sx={{ maxWidth: maxWidth ?? '250px', width: '100%', alignSelf: 'center' }}>
      {!hideLabel && <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>}
      <OutlinedInput
        value={searchValue}
        id="outlined-adornment-search"
        {...(!hideLabel && { label: 'Search' })}
        startAdornment={
          <StyledAdornment position="start">
            <IconComponent name="ri-search-line" iconStyle={{ fontSize: 11 }} />
          </StyledAdornment>
        }
        size="small"
        onChange={onChangeSearchValue}
        inputProps={{ style: { fontSize: 11 } }}
        {...(miniSearch && { style: { height: '20px', borderRadius: '0px' } })}
        endAdornment={endAdornment}
      />
    </FormControl>
  );
};
