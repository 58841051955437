import { SnapshotCallback } from '@protos/snapshots';
import { Stream, StreamEvent, Subscription } from './Stream';

export const streamSnapshotsService = (stream: Stream) => {
  let eventCallback: SnapshotCallback | undefined;
  let isSubscribed = false;

  stream.onEvent('snapshots', (event: StreamEvent) => {
    const snapshot = event.asSnapshots();
    if (!snapshot || !eventCallback) return;

    eventCallback(snapshot);
  });

  stream.onConnect(() => {
    if (isSubscribed) stream.subscribe(Subscription.snapshots());
  });

  const subscribe = (callback: SnapshotCallback) => {
    stream.subscribe(Subscription.snapshots());
    isSubscribed = true;
    eventCallback = callback;
  };

  const unsubscribe = () => {
    eventCallback = undefined;
    stream.unsubscribe(Subscription.snapshots());
    isSubscribed = false;
  };

  return {
    subscribe,
    unsubscribe,
  };
};
