import { Box, Button, ClickAwayListener, Grow, List, ListItem, ListItemButton, ListItemText, Paper, Popper, TextField, Tooltip } from '@mui/material';
import { SearchSymbolResponse } from '@protos/charts';
import ApiClient from '@services/ApiClient';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

const FormulaContractsRegex = /#([a-zA-Z0-9]+)/g;
const FormulaOperatorsRegex = /[*+\-/^)(]/g;

interface FormulaInputProps {
  apiClient: ApiClient;
  selectedFormula: string;
  onQuerySeasonalFormula: (formula: string, symbolsInFormula: string[], hasRollingSymbolInFormula: boolean) => void;
}

const InformationIcon = () => (
  <span style={{ fontSize: 16 }}>
    <Tooltip title="Type '#' to input Contract Symbol">
      <i className="ri-information-2-fill" />
    </Tooltip>
  </span>
);

export const FormulaInput = ({ apiClient, selectedFormula, onQuerySeasonalFormula }: FormulaInputProps) => {
  const [formula, setFormula] = useState<string>(selectedFormula);
  const [symbolSearchTerm, setSymbolSearchTerm] = useState<string>('');
  const [symbolOptions, setSymbolOptions] = useState<SearchSymbolResponse[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeOption, setActiveOption] = useState<number>(-1);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const getSymbolDetail = useCallback(async (symbol: string) => {
    const symbolDetail = await apiClient.getSymbol(symbol);
    return symbolDetail;
  }, []);

  const fetchSymbolOptions = useCallback(
    debounce(async (searchQuery: string) => {
      const symbolsLoader = apiClient.symbolsLoader();

      try {
        symbolsLoader.resetQuery({
          search: searchQuery || undefined,
        });
        const symbols = await symbolsLoader.loadData();
        setSymbolOptions(symbols);
      } catch (error) {
        console.error('Error fetching options:', error);
        setSymbolOptions([]);
      }
    }, 500),
    [apiClient]
  );

  const onFormulaInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFormula(value);
      const cursorPosition = e.target.selectionStart;
      if (!cursorPosition) return;
      const latestChar = value[cursorPosition - 1];
      if (latestChar.match(FormulaOperatorsRegex)?.length) {
        if (anchorEl) setAnchorEl(null);
        return;
      }

      const hashIndex = value.lastIndexOf('#', cursorPosition - 1);
      if (hashIndex !== -1) {
        const term = value.slice(hashIndex, cursorPosition);
        setSymbolSearchTerm(term);
        setAnchorEl(e.currentTarget);
      } else {
        setSymbolSearchTerm('');
      }
    },
    [anchorEl]
  );

  const onSymbolSelect = useCallback(
    (option: SearchSymbolResponse) => {
      const cursorPosition = inputRef.current?.selectionStart;
      if (!cursorPosition) return;

      const beforeCursor = formula.slice(0, cursorPosition - symbolSearchTerm.length);
      const afterCursor = formula.slice(cursorPosition);
      const newFormula = `${beforeCursor}#${option.symbol}${afterCursor}`;
      setFormula(newFormula);
      setSymbolSearchTerm('');
      setSymbolOptions([]);
      setActiveOption(-1);
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.setSelectionRange(beforeCursor.length + option.symbol.length + 1, beforeCursor.length + option.symbol.length + 1);
      }, 0);
    },
    [formula, symbolSearchTerm]
  );

  const onPopperClickAway = useCallback(() => {
    setSymbolSearchTerm('');
    setSymbolOptions([]);
    setActiveOption(-1);
    setAnchorEl(null);
  }, []);

  const hasFormulaARollingSymbol = async (symbolsInFormula: string[]) => {
    let isRollingSymbolInFormula = false;
    for (const symbol of symbolsInFormula) {
      const symbolDetail = await getSymbolDetail(symbol);
      if (!symbolDetail) continue;

      if (symbolDetail.description?.toLocaleLowerCase().includes('rolling')) {
        isRollingSymbolInFormula = true;
        break;
      }
    }

    return isRollingSymbolInFormula;
  };

  const onQueryFormula = useCallback(async () => {
    const contractSymbolsInFormula = [...formula.matchAll(FormulaContractsRegex)].map(match => match[1]);
    if (contractSymbolsInFormula.length === 0) return;

    const isRollingSymbolInFormula = await hasFormulaARollingSymbol(contractSymbolsInFormula);

    onQuerySeasonalFormula(formula?.toLocaleLowerCase(), contractSymbolsInFormula, isRollingSymbolInFormula);
    onPopperClickAway();
  }, [formula, onQuerySeasonalFormula, onPopperClickAway, getSymbolDetail]);

  const onUserKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (symbolOptions.length > 0) {
        if (e.key === 'ArrowDown') {
          setActiveOption(prev => (prev < symbolOptions.length - 1 ? prev + 1 : prev));
          e.preventDefault();
        } else if (e.key === 'ArrowUp') {
          setActiveOption(prev => (prev > 0 ? prev - 1 : prev));
          e.preventDefault();
        } else if (e.key === 'Enter' && activeOption !== -1) {
          onSymbolSelect(symbolOptions[activeOption]);
          e.preventDefault();
        }
      }
    },
    [symbolOptions, activeOption, onSymbolSelect]
  );

  useEffect(() => {
    if (symbolSearchTerm.startsWith('#') && symbolSearchTerm.length > 1) {
      const query = symbolSearchTerm.slice(1);
      fetchSymbolOptions(query);
    } else {
      setSymbolOptions([]);
    }
  }, [symbolSearchTerm, fetchSymbolOptions]);

  useEffect(() => {
    if (selectedFormula && formula === selectedFormula) onQueryFormula();
  }, []);

  return (
    <>
      <Box display="flex" alignItems="baseline" gap={1}>
        <TextField
          autoComplete="off"
          size="small"
          label="Input Formula"
          value={formula}
          onChange={onFormulaInputChange}
          onKeyDown={onUserKeyDown}
          fullWidth
          margin="normal"
          variant="outlined"
          inputRef={inputRef}
          InputProps={{
            endAdornment: <InformationIcon />,
          }}
          InputLabelProps={{ style: { fontSize: 11 } }}
          sx={{
            minWidth: 500,
            '& .MuiInputBase-input': {
              fontSize: 11,
            },
            marginTop: 0,
          }}
        />
        <Button variant="contained" color="primary" onClick={() => onQueryFormula()} size="small" style={{ fontSize: 11 }}>
          Query
        </Button>
      </Box>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        sx={theme => ({ zIndex: theme.zIndex.modal, maxHeight: 250, overflow: 'auto', width: inputRef.current?.clientWidth })}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={onPopperClickAway}>
                <>
                  {symbolOptions.length === 0 && <ListItem />}
                  <List>
                    {symbolOptions.map((option, index) => (
                      <ListItemButton
                        key={option.symbol}
                        onClick={() => onSymbolSelect(option)}
                        sx={{ fontSize: 11 }}
                        selected={index === activeOption}
                      >
                        <ListItemText primary={option.symbol.toLocaleUpperCase()} />
                      </ListItemButton>
                    ))}
                  </List>
                </>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
