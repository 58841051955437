import { SearchSymbolResponse } from '@protos/charts';

export type SeasonalChartPayload = {
  chartSymbol: SearchSymbolResponse;
  chartFormula: string;
  isFormulasEnabled: boolean;
};

export const chartMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const chartLineColors = [
  '#FFFFFF',
  '#F79F1F',
  '#A3CB38',
  '#1289A7',
  '#5758BB',
  '#FFC312',
  '#ED4C67',
  '#EA2027',
  '#CD6133',
  '#F78FB3',
  '#F19066',
];
export const SeasonYearsToFetch = 5;
export const DEFAULT_CHART_SYMBOL = {
  symbol: 'brt1',
  product_symbol: 'brt',
  product_type: 'index',
  description: 'Brent Future - Rolling month 1',
  tenor_code: '',
  tenor_month: '',
  tenor_year: '',
  expiry: null,
  disabled: false,
};
