import { useProductRiskStreaming } from '@hooks/useProductRiskStreaming';
import { useScreenSize } from '@hooks/useScreenSize';
import { useSnapshotsStreaming } from '@hooks/useSnapshotsStreaming';
import { Box, Divider, Stack } from '@mui/material';
import { Snapshot } from '@protos/snapshots';
import { ContractRisk, ProductRisk } from '@protos/trading';
import { config } from '@services/context';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useCallback, useState } from 'react';
import { transformRiskResponse } from '../../tradingAdminUtils';
import { TradingOrders } from '../TradingOrders';
import { TradingRiskChartAndMeta } from './TradingRiskChartAndMeta';

const DEFAULT_PRODUCT_SYMBOL = 'brt';

export const TradingRisk = () => {
  const { tenors, loaded: isTenorsLoaded } = useUserProductsAndTenorsContext();
  const { isMobileLandscape } = useScreenSize();

  const [data, setData] = useState<ContractRisk[]>([]);
  const [metaData, setMetaData] = useState<{ version: number; timestamp: string } | undefined>();
  const [snapshot, setSnapshot] = useState<Snapshot | undefined>();

  const onRiskStreaming = useCallback(
    (risk: ProductRisk) => {
      if (!isTenorsLoaded) return;

      const { version: currentVersion } = metaData || {};
      if (currentVersion && risk.version <= currentVersion) return;

      const { data, version, timestamp } = transformRiskResponse(risk, tenors);
      setData(data);
      if (version && timestamp) setMetaData({ version, timestamp });
    },
    [tenors, isTenorsLoaded, metaData]
  );

  const onSnapshotStreaming = useCallback((incomingSnapshot: any) => {
    if (incomingSnapshot) {
      setSnapshot(incomingSnapshot);
    }
  }, []);

  useProductRiskStreaming(
    onRiskStreaming,
    DEFAULT_PRODUCT_SYMBOL,
    config.otcAccountId,
    DEFAULT_PRODUCT_SYMBOL && !!config.otcAccountId && isTenorsLoaded && tenors.length > 0
  );
  useSnapshotsStreaming(onSnapshotStreaming, isTenorsLoaded && tenors.length > 0);

  return (
    <Stack width="100%">
      <TradingRiskChartAndMeta data={data} metaData={metaData} snapshot={snapshot} isMobileLandscape={isMobileLandscape} />
      {!isMobileLandscape && (
        <Box display="flex">
          <Divider />
          <TradingOrders hideFilters maxHeight={300} />
        </Box>
      )}
    </Stack>
  );
};
