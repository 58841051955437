import { logger } from '@services/context';

class StringService {
  public safeJsonParse = <T>(input: string): T | undefined => {
    try {
      return JSON.parse(input) as T;
    } catch (error) {
      logger.error('could not parse JSON ', error);
      return;
    }
  };

  public truncate = (input: string, maxLength: number): string => {
    if (input.length <= maxLength) {
      return input;
    }

    return `${input.slice(0, maxLength)}`;
  };
}

export default StringService;
