import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { alpha, styled, SxProps, Theme } from '@mui/material/styles';
import classNames from 'classnames';

export const InlineGroupClassNames = {
  root: 'FlxInlineGroup-root',
  child: 'FlxInlineGroup-child',
};

interface StyledBoxProps extends BoxProps {
  error?: boolean;
}

const StyledBox = styled((props: StyledBoxProps) => <Box {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'error',
})(({ theme, error }) => ({
  display: 'flex',
  height: '73px',
  ...(error && {
    outline: `3px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius,
  }),
}));

const StyledBoxChild = styled((props: StyledBoxProps) => <Box {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'error',
})(({ theme, error }) => ({
  flex: '1 1 0',
  border: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  position: 'relative',
  overflow: 'hidden',
  '&:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  '&:not(:first-of-type)': {
    borderLeft: 0,
  },
  '&:not(:first-of-type)::before': {
    content: '""',
    width: 1,
    top: '-1px',
    height: 'calc(100% + 2px)',
    backgroundColor: `${alpha(theme.palette.border, 0.1)}`,
    position: 'absolute',
  },
  '&:last-of-type': {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  '&:not(:last-of-type)': {
    borderRight: 0,
  },
  '&:focus-within': {
    borderColor: theme.palette.primary.main,
  },
  '&:focus-within::before': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:focus-within + div::before': {
    backgroundColor: theme.palette.primary.main,
  },
  ...(error && {
    borderColor: 'transparent',
    '&:focus-within': {},
    '&:focus-within::before': {},
    '&:focus-within + div::before': {},
  }),
}));

export type InlineFieldProps = {
  children: React.ReactElement | React.ReactNode;
  error?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
};

export function InlineField({ children, error, className, sx }: InlineFieldProps) {
  return (
    <StyledBox className={classNames(InlineGroupClassNames.root, className)} error={error} sx={sx}>
      <StyledBoxChild className={InlineGroupClassNames.child} error={error}>
        {children}
      </StyledBoxChild>
    </StyledBox>
  );
}

type InlineGroupProps = {
  children: React.ReactElement[] | React.ReactNode;
  error?: boolean;
};

export default function InlineGroup({ children, error }: InlineGroupProps) {
  return (
    <StyledBox className={InlineGroupClassNames.root} error={error}>
      {React.Children.map(children, child =>
        child ? (
          <StyledBoxChild className={InlineGroupClassNames.child} error={error}>
            {child}
          </StyledBoxChild>
        ) : null
      )}
    </StyledBox>
  );
}
