export enum EventLogTypeId {
  UserSignedInWeb = 8,
  UserNavigatedToPage = 11,
  UserClick = 12,
  UserSignedOutWeb = 13,
}

export interface UserActivity {
  eventLogTypeId: EventLogTypeId;
  timestamp: Date;
  details: string;
}
