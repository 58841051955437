class NumberService {
  threshold = 50;

  public formatNumber = (numberAsString: any): string => {
    if (isNaN(numberAsString) || numberAsString === null) {
      return '';
    }

    return Number(numberAsString).toFixed(2);
  };

  public formatPrice = (price: number): string => {
    return Math.floor(price).toLocaleString();
  };

  public formatQuantity = (quantity: any): string | number => {
    if (!quantity) return '';

    return Math.abs(quantity);
  };

  public getThresholdValue = (value: number): number => (Math.sign(value) * this.threshold * value) / 100;

  public isNumber = (val: string | number | null | undefined): boolean => {
    if (val === undefined || val === null || val === '') {
      return false;
    }

    if (typeof val === 'number') {
      return true;
    }

    return /^-?\d+(\.\d+)?$/.test(val);
  };
}

const numberService = new NumberService();
export { numberService };
