import { useStreamRFQ } from '@hooks/useStreamRFQ';
import { RFQPriceAmount, RFQTicker } from '@protos/ticker';
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

export type TradingPanel = {
  selectedProduct: string;
  selectedTenor: string | { left: string; right: string };
  tradeSize: number;
  selectedTradingAccount: string;
};

type TradingPanelState = {
  prices: { bid: RFQPriceAmount | undefined; ask: RFQPriceAmount | undefined };
  isLoadingPrices: boolean;
  subscriptionSymbol: string;
  setSubscriptionSymbol: (symbol: string) => void;
  setIsLoadingPrices: (isLoading: boolean) => void;
  setPrices: (prices: { bid: RFQPriceAmount | undefined; ask: RFQPriceAmount | undefined }) => void;
  subscriptionSize: number | undefined;
  setSubscriptionSize: (size: number | undefined) => void;
  unsubscribeSymbol: (symbol: string) => void;
};

type Props = {
  children: ReactNode;
};

const TradingContext = createContext<TradingPanelState | undefined>(undefined);
TradingContext.displayName = 'TradingContext';

export default function TradingProvider({ ...otherProps }: Props) {
  const [prices, setPrices] = useState<{ bid: RFQPriceAmount | undefined; ask: RFQPriceAmount | undefined }>({ bid: undefined, ask: undefined });
  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(true);
  const [subscriptionSymbol, setSubscriptionSymbol] = useState<string>('');
  const [subscriptionSize, setSubscriptionSize] = useState<number | undefined>(undefined);

  const rfqCallback = useCallback(
    (incomingTicker: RFQTicker) => {
      const { symbol, product_symbol, exchange } = incomingTicker;

      if (incomingTicker.bid && incomingTicker.ask) {
        const { amount: bidAmount } = incomingTicker.bid;
        const { amount: askAmount } = incomingTicker.bid;
        const rfqSymbolKey = typeof symbol === 'string' ? `${product_symbol}${symbol.split(product_symbol)[1]}` : `${symbol.front}-${symbol.back}`;

        const isValidSymbol = rfqSymbolKey === subscriptionSymbol;
        const isValidAmount = subscriptionSize ? subscriptionSize >= +bidAmount || subscriptionSize >= +askAmount : true;

        if (isValidSymbol && isValidAmount) {
          setPrices({ bid: incomingTicker.bid, ask: incomingTicker.ask });
          setIsLoadingPrices(false);
        }
      }
    },
    [subscriptionSymbol, subscriptionSize]
  );

  const { unsubscribeSymbol } = useStreamRFQ(rfqCallback, `${subscriptionSymbol}-${subscriptionSize}`, 'ice');

  const value: TradingPanelState = useMemo(
    () => ({
      prices,
      subscriptionSymbol,
      subscriptionSize,
      isLoadingPrices,
      setIsLoadingPrices,
      setSubscriptionSymbol,
      setSubscriptionSize,
      setPrices,
      unsubscribeSymbol,
    }),
    [subscriptionSymbol, subscriptionSize, isLoadingPrices, prices, unsubscribeSymbol, setSubscriptionSymbol, setSubscriptionSize, setPrices]
  );

  return <TradingContext.Provider value={value} {...otherProps} />;
}

export function useTradingContext(): TradingPanelState {
  const context = useContext(TradingContext);

  if (!context) {
    throw new Error('useTradingContext must be used within a TradingProvider');
  }

  return context;
}
