import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Divider, Stack } from '@mui/material';
import { LadderSettings } from '@protos/ladders';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useCallback, useEffect, useState } from 'react';
import { OCLGrid } from './components/non-spread/OCLGrid';
import { SpreadOCLGrid } from './components/spread/SpreadOCLGrid';

interface AllSettings {
  symbol: string;
  validity: string;
  size: string;
}

interface AllSpreadSettings {
  spreadSymbol: string;
  spreadValidity: string;
  spreadSize: string;
}

interface AdvancedOCLTradingProps {
  selectedAccount: string;
  setSelectedAccount: (account: string) => void;
}

export const AdvancedOCLTrading = ({ selectedAccount, setSelectedAccount }: AdvancedOCLTradingProps) => {
  const user = useUserContext();
  const { urlParams, resetURLSearchParams } = useURLSearchParams();

  const [allSettings, setAllSettings] = useState<AllSettings>();
  const [allSpreadSettings, setAllSpreadSettings] = useState<AllSpreadSettings>();

  const getNonSpreadSettings = useCallback(
    (settings: LadderSettings) => {
      const { symbol, validity, size } = settings;

      if (allSettings && allSettings.symbol === symbol && allSettings.validity === validity.toString() && allSettings.size === size.toString())
        return;

      setAllSettings({ ...allSettings, symbol, validity: validity.toString(), size: size.toString() });
    },
    [allSettings]
  );

  const getSpreadSettings = useCallback(
    (settings: LadderSettings) => {
      const { symbol, validity, size } = settings;

      if (
        allSpreadSettings &&
        allSpreadSettings.spreadSymbol === symbol &&
        allSpreadSettings.spreadValidity === validity.toString() &&
        allSpreadSettings.spreadSize === size.toString()
      )
        return;

      setAllSpreadSettings({ ...allSpreadSettings, spreadSymbol: symbol, spreadValidity: validity.toString(), spreadSize: size.toString() });
    },
    [allSpreadSettings]
  );

  useEffect(() => {
    if (!Object.keys(urlParams).length) {
      resetURLSearchParams({ ...allSettings, ...allSpreadSettings, account_id: selectedAccount });
    } else {
      if (!allSettings || !allSpreadSettings) return;

      // Consolidate all parameters
      const newParams = {
        symbol: allSettings.symbol,
        validity: allSettings.validity,
        size: allSettings.size,
        spreadSymbol: allSpreadSettings.spreadSymbol,
        spreadValidity: allSpreadSettings.spreadValidity,
        spreadSize: allSpreadSettings.spreadSize,
        account_id: selectedAccount,
      };

      // Compare and update only if there's a difference
      const paramsToUpdate = Object.keys(newParams).reduce((acc, key) => {
        if (urlParams[key] !== newParams[key]) {
          acc[key] = newParams[key];
        }
        return acc;
      }, {} as Record<string, any>);

      // Update the URL parameters in one call if there are changes
      if (Object.keys(paramsToUpdate).length) {
        resetURLSearchParams({ ...urlParams, ...paramsToUpdate });
      }
    }
  }, [urlParams, allSettings, allSpreadSettings, selectedAccount, resetURLSearchParams]);

  useEffect(() => {
    if (selectedAccount || !user?.abn_trad_accounts.length) return;

    if (urlParams?.account_id && user.abn_trad_accounts.includes(urlParams.account_id)) {
      setSelectedAccount(urlParams.account_id);
    } else {
      setSelectedAccount(user.abn_trad_accounts[0]);
    }
  }, [urlParams, selectedAccount, user]);

  return (
    <Stack width="100%" height="100%" padding={1} gap={1}>
      <Box display="flex" flex={1} alignItems="center" justifyContent="end" sx={{ bgcolor: 'background.paper', padding: 1 }}>
        <Box minWidth="90%" />
      </Box>
      <Box display="flex" flex={1} width="100%" height="100%" gap={1} alignItems="start">
        <OCLGrid getNonSpreadSettings={getNonSpreadSettings} selectedAccount={selectedAccount} />
        <Divider orientation="vertical" flexItem />
        <SpreadOCLGrid getSpreadSettings={getSpreadSettings} selectedAccount={selectedAccount} />
      </Box>
    </Stack>
  );
};
