import { Paginated } from './http';
import { urlQuery } from './url';

export interface DataApi<DataType> {
  // Did we load any data yet?
  hasLoadedData: () => boolean;

  // Are there more items to load?
  hasMoreData: () => boolean;

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isDataLoading: () => boolean;

  // Array of items loaded so far.
  data: DataType[];

  searchText?: string;

  // Callback function responsible for loading the next page of items.
  // returns the new data only
  loadData: () => Promise<DataType[]>;

  // Callback function responsible for loading the first page of items.
  // returns the latest no cursor data only
  simpleLoadData: () => Promise<DataType[]>;

  // reset filters
  resetQuery: (query: Record<string, any>) => void;

  // add/remove filters
  addFilter: (query: Record<string, any>) => void;

  // add search
  addSearchFilter: (searchText: string) => void;

  // query
  query: Record<string, string>;
}

export interface HttpClientInterface {
  getList: <T>(url: string, options?: any) => Promise<Paginated<T>>;
}

export class RestApiDataLoader<DataType> implements DataApi<DataType> {
  cli: HttpClientInterface;
  data: DataType[];
  query: Record<string, any>;
  url: string;
  options: Record<string, any>;
  cursor: string | undefined = undefined;
  loading = false;

  constructor(cli: HttpClientInterface, url: string, query?: Record<string, any>, options?: Record<string, any>) {
    this.cli = cli;
    this.url = url;
    this.query = query || {};
    this.options = options || {};
    this.data = [];
  }

  resetQuery(query?: Record<string, any>) {
    this.cursor = undefined;
    this.data = [];
    if (query) {
      this.query = Object.keys(query).reduce((acc, key) => {
        if (query[key]) {
          acc[key] = query[key];
        }
        return acc;
      }, {});
    } else {
      this.query = {};
    }
  }

  addFilter(query: Record<string, any>) {
    this.resetQuery({ ...this.query, ...query });
  }

  addSearchFilter(searchText: string) {
    this.resetQuery({ ...this.query, search: searchText });
  }

  hasLoadedData(): boolean {
    return this.cursor !== undefined;
  }

  hasMoreData(): boolean {
    return !!this.cursor;
  }

  isDataLoading(): boolean {
    return this.loading;
  }

  get searchText(): string {
    return this.query.search || '';
  }

  async loadData(): Promise<DataType[]> {
    const { data, cursor } = await this.load();
    this.data = this.cursor === undefined ? data : this.data.concat(data);
    this.cursor = cursor;

    return data;
  }

  async simpleLoadData(): Promise<DataType[]> {
    const { data } = await this.load();
    this.data = data;

    return data;
  }

  nextUrl(): string | undefined {
    if (this.hasMoreData() || !this.hasLoadedData()) {
      return urlQuery(this.url, { ...this.query, ...(this.cursor ? { cursor: this.cursor } : {}) });
    }
    return undefined;
  }

  async load(): Promise<Paginated<DataType>> {
    let paginatedResponse: Paginated<DataType>;
    const url = this.nextUrl();

    if (url) {
      this.loading = true;
      try {
        paginatedResponse = await this.cli.getList(url, this.options);
      } finally {
        this.loading = false;
      }

      return paginatedResponse;
    }

    return { data: [], cursor: undefined };
  }
}
