import { pageTitleService } from '@services/PageTitleService';
import { useEffect } from 'react';
import { useBreadcrumbs } from './useBreadcrumbs';

export function usePageTitle(pageTitle?: string) {
  const { activeCrumb } = useBreadcrumbs();
  const effectivePageTitle = pageTitle ?? activeCrumb?.name ?? '';

  useEffect(() => {
    pageTitleService.setTitle(effectivePageTitle);

    return () => {
      pageTitleService.resetToBaseTitle();
    };
  }, [effectivePageTitle]);
}
