import { FULLMO_TENOR_ID } from '@protos/grids';
import { RollingRowSettings, Row } from '../shared/types';

export type PricesRow = Row & {
  [key: string]: string;
};

export type PricesWidgetPayload = {
  selectedColumns?: string[];
  selectedRows?: string[];
  columnsOrder?: string[];
  userRollingRowSettings?: RollingRowSettings;
  isOverrideRolling?: boolean;
};

export const FULLMO_TENOR_ROW = { tenorId: FULLMO_TENOR_ID, tenorName: 'FULLMO', tenorFrequency: 'monthly' };
