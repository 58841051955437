import { WidgetProps } from '@features/dashboard/types';
import { MenuItem, Select } from '@mui/material';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useEffect, useState } from 'react';
import Widget, { Content, Header } from '../Widget';
import Settings from '../settings/Settings';
import { TradingGrid } from './TradingGrid';
import { TradingWidgetPayload } from './tradingTypes';

const TradingExchanges = [
  { value: 'ice', label: 'ICE' },
  { value: 'cme', label: 'CME' },
];

type TradingProps = WidgetProps & {
  payload?: TradingWidgetPayload;
};

export const Trading = ({ payload, ...otherProps }: TradingProps) => {
  const { id, title } = otherProps;
  const user = useUserContext();

  const [tradingAccounts, setTradingAccounts] = useState<string[]>([]);
  const [selectedTradingAccount, setSelectedTradingAccount] = useState<string | undefined>(undefined);
  const [selectedExchange, setSelectedExchange] = useState<string>(TradingExchanges[0].value);

  useEffect(() => {
    if (user) {
      setTradingAccounts(user.abn_trad_accounts);
    }
  }, [user]);

  useEffect(() => {
    if (tradingAccounts.length > 0) {
      setSelectedTradingAccount(tradingAccounts[0]);
    }
  }, [tradingAccounts]);

  if (!payload) return null;

  return (
    <Widget {...otherProps}>
      <Header title={title}>
        {selectedTradingAccount && tradingAccounts.length > 1 ? (
          <>
            {/* <Select
              size="small"
              autoWidth
              style={{ fontSize: 11, maxHeight: 23, fontWeight: 700, borderRadius: 0 }}
              value={selectedExchange}
              onChange={(event: any) => setSelectedExchange(event.target.value)}
              tabIndex={-1}
            >
              {TradingExchanges.map(({ value, label }) => (
                <MenuItem key={value} value={value} sx={{ fontSize: 11 }}>
                  {label}
                </MenuItem>
              ))}
            </Select> */}
            <Select
              size="small"
              autoWidth
              style={{ fontSize: 11, maxHeight: 23, fontWeight: 700, borderRadius: 0 }}
              value={selectedTradingAccount}
              onChange={(event: any) => setSelectedTradingAccount(event.target.value)}
              tabIndex={-1}
            >
              {tradingAccounts.map((account: string) => (
                <MenuItem key={account} value={account} sx={{ fontSize: 11 }}>
                  {account.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <></>
        )}
        <Settings id={`trading-settings-${id}`} title={title} widgetId={id} />
      </Header>
      {selectedTradingAccount && (
        <Content>
          <TradingGrid
            payload={payload}
            selectedTradingAccount={tradingAccounts.length > 1 ? selectedTradingAccount : undefined}
            selectedExchange={selectedExchange}
          />
        </Content>
      )}
    </Widget>
  );
};
