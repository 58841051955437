import UserActivityTracker from './UserActivityTracker';

const userActivityTracker = new UserActivityTracker();

function trackNavigation(pageTitle: string) {
  userActivityTracker.navigation(`User navigated to '${pageTitle}'`);
}

type ClickInfoArgument = Function | { component: string; action: string };

function trackClick(fn: Function, clickInfo?: ClickInfoArgument) {
  return (...args: any) => {
    const fnResult = fn(...args);

    const { component, action } = clickInfo ? (typeof clickInfo === 'function' ? clickInfo(...args) : clickInfo) : fnResult;
    userActivityTracker.click(`User clicked on '${action}' in '${component}'`);

    return fnResult;
  };
}

function trackContactSupport(supportName: string) {
  userActivityTracker.click(`User sent a message to ${supportName}`);
}

function trackLogin() {
  userActivityTracker.signedIn('Web login');
}

function trackLogout(): Promise<void> {
  userActivityTracker.signedOut('Web logout');
  return userActivityTracker.forceSave();
}

export { userActivityTracker, trackNavigation, trackClick, trackContactSupport, trackLogin, trackLogout };
