export type Dashboard = {
  id: number;
  name: string;
  json?: Record<string, any>;
  last_modified: string;
  // to do change this in the server
  // here just so we compile
  lastAccess?: Date;
  settings?: any;
  websocket_uid?: string;
};

// Handle dashboard updates
export class Dashboards {
  dashboards: Dashboard[];
  messageType: string;

  constructor(dashboards: Dashboard[] | Dashboard, messageType: string) {
    if (Array.isArray(dashboards)) {
      this.dashboards = dashboards;
      this.messageType = 'snapshot';
    } else {
      this.dashboards = [dashboards];
      this.messageType = messageType;
    }
  }

  get isSnapshot(): boolean {
    return this.messageType === 'snapshot';
  }

  get isUpdate(): boolean {
    return this.messageType === 'update';
  }

  get isCreate(): boolean {
    return this.messageType === 'create';
  }

  get isDelete(): boolean {
    return this.messageType === 'delete';
  }
}
