import { EventsAlertsDialog } from '@features/admin/alerts/EventsAlertsDialog';
import { EditProductDialog } from '@features/admin/products/components/EditProductDialog';
import { ProductInfo } from '@features/admin/products/components/ProductInfo';
import { SpecificationInfo } from '@features/admin/products/components/SpecificationInfo';
import { useProductPage } from '@features/admin/products/hooks/useProductPage';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Alert } from '@protos/alerts';
import { ADMIN_PRODUCT_ALERTS_COLUMNS } from '@protos/product';
import { BlotterCard } from '@shared/components/BlotterCard';
import { DagGraph } from '@shared/components/DagGraph/DagGraph';
import { transformDagData } from '@shared/components/DagGraph/dagGraphUtils';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import { RowClickedEvent } from 'ag-grid-enterprise';
import { useCallback, useState } from 'react';

export const ProductPage = () => {
  usePageTitle('Product details');

  const { product, symbol, alertsData, isLoadingBlotters, ProductBlotters, pricingDagData } = useProductPage();

  const [selectedEventsAlert, setSelectedEventsAlert] = useState<Alert | undefined>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const onAlertRowClicked = useCallback(
    (row: RowClickedEvent<Alert>) => {
      setSelectedEventsAlert(row.data as Alert);
    },
    [setSelectedEventsAlert]
  );

  if (!product || !symbol) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" flex={1} padding={2} gap={2}>
        <Paper>
          <Box display="flex" justifyContent="space-between" flex={1} alignItems="end">
            <Box display="flex" flexDirection="column" padding={2} paddingBottom={0} flex={1}>
              <Typography variant="h3">{product.symbol}</Typography>
              <Typography variant="h5" color="gray" marginTop={-1}>
                {product.full_name}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIsEditDialogOpen(true)}
              style={{ maxHeight: 30, marginRight: 16, fontSize: 11 }}
            >
              Edit Product
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} padding={2}>
            <ProductInfo product={product} />
            {product.specification && <SpecificationInfo specification={product.specification} />}
          </Box>
        </Paper>
        {isLoadingBlotters ? (
          <Paper>
            <Box display="flex" flex={1} justifyContent="center" alignContent="center" padding={10}>
              Loading more info...
            </Box>
          </Paper>
        ) : (
          <>
            {alertsData.length > 0 && (
              <>
                <Paper>
                  <Box display="flex" flex={1} justifyContent="stretch">
                    <BlotterCard
                      title="Alerts"
                      data={alertsData}
                      columns={ADMIN_PRODUCT_ALERTS_COLUMNS}
                      fullWidth
                      domLayout="autoHeight"
                      onRowClicked={onAlertRowClicked}
                    />
                  </Box>
                </Paper>
                {selectedEventsAlert && (
                  <EventsAlertsDialog selectedEventsAlert={selectedEventsAlert} onClose={() => setSelectedEventsAlert(undefined)} />
                )}
              </>
            )}
            <Paper>
              <Box display="flex" flexWrap="wrap" flex={1} gap={2} margin={2} justifyContent="stretch">
                {ProductBlotters.map(({ shouldRender, ...blotter }) => {
                  if (!shouldRender) return null;
                  return <BlotterCard key={blotter.title} {...blotter} />;
                })}
              </Box>
            </Paper>
            <DagGraph data={transformDagData(pricingDagData)} />
          </>
        )}
      </Box>
      <EditProductDialog product={product} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
    </>
  );
};
