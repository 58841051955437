import AppErrorBoundary from '@features/common/AppErrorBoundary';
import Box from '@mui/material/Box';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import { usePageTracking } from '@shared/hooks/usePageTracking';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { ReactNode } from 'react';
import { FluxAppBar } from './FluxAppBar';
import MobileNavBar from './MobileNavBar';

type Props = {
  children: ReactNode;
};

export default function AppFrame({ children }: Props) {
  usePageTitle();
  usePageTracking();
  const { isMobile } = useScreenSize();

  if (isMobile) {
    return (
      <AppErrorBoundary>
        <>
          <Box paddingBottom={12}>{children}</Box>
          <MobileNavBar />
        </>
      </AppErrorBoundary>
    );
  }

  return <FluxAppBar>{children}</FluxAppBar>;
}
