import { PricingDAGResponse } from '@protos/pricing';

export const transformDagData = (data: PricingDAGResponse[]) => {
  return {
    nodes: data.map(node => ({
      id: node.symbol,
      label: node.symbol,
    })),
    links: data.reduce((acc, node) => {
      const dependencies = Object.keys(node.dependencies);

      dependencies.forEach(dependency => {
        if (!data.find(d => d.symbol === dependency)) return;

        acc.push({
          source: node.symbol,
          target: dependency,
        });
      });

      return acc;
    }, [] as any[]),
  };
};
