export type AlertLevelEnum = 'unspecified' | 'info' | 'warning' | 'error' | 'critical';
export const ALERT_LEVEL_OPTIONS: AlertLevelEnum[] = ['unspecified', 'info', 'warning', 'error', 'critical'];

export type Alert = {
  id: number;
  group: string;
  message: string;
  timestamp: string;
  level: AlertLevelEnum;
  product_symbol: string;
  symbol: string;
  user_id: string;
  event_message: string;
  counter: number;
  dismissed: boolean;
};

export type AlertsQueryParams = { group?: string; user_id?: string; search?: string; cursor?: string; limit?: number };

export type AlertEvent = {
  id: number;
  alert_id: number;
  message: string;
  timestamp: string;
  dismiss: boolean;
};
