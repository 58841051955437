import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { styled } from '@mui/material/styles';

export const StyledScopedCssBaseline = styled(ScopedCssBaseline)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
}));
