import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import gb from 'date-fns/locale/en-GB';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface DateInputProps {
  label: string;
  onChange: (newValue: string | undefined) => void;
  value: string | number | Date | undefined;
  minDate?: Date;
  maxDate?: Date;
  clearable?: boolean;
}

export const DateInput = ({ label, onChange, value, minDate, maxDate, clearable = false }: DateInputProps) => {
  const [dateValue, setDateValue] = useState<Date | undefined>(value ? new Date(value) : undefined);

  const onDateChange = useCallback(
    debounce((newValue: Date | null) => {
      if (newValue) {
        const formattedDate = format(newValue, 'yyyy-MM-dd');
        setDateValue(newValue);
        onChange(formattedDate);
      } else if (clearable) {
        setDateValue(undefined);
        onChange(undefined);
      }
    }, 500),
    [onChange, clearable]
  );

  useEffect(() => {
    setDateValue(value ? new Date(value) : undefined);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={gb}>
      <DatePicker
        label={label}
        value={dateValue}
        onChange={onDateChange}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        slotProps={{
          field: { clearable },
          textField: {
            size: 'small',
            sx: {
              '& .MuiInputBase-input': {
                fontSize: 11,
              },
              '& .MuiInputAdornment-root': {
                marginLeft: 0,
              },
              minWidth: 220,
            },
            InputLabelProps: { style: { fontSize: 11 } },
          },
        }}
      />
    </LocalizationProvider>
  );
};
