import { SettlementType } from '@shared/protos/settlementPrices';
import { ReactNode, createContext, useContext, useMemo } from 'react';
import { RollingRowSettings } from '../shared/types';
import { SettlementPriceGrid, useSettlementGridState } from './useSettlementGridState';

type Props = {
  children: ReactNode;
  widgetId: string;
  initialValues: {
    selectedColumns: string[];
    selectedRows: string[];
    columnsOrder: string[];
    selectedSettlementTypes: SettlementType[];
    settlementDate: Date | string;
    userRollingRowSettings: RollingRowSettings;
    isOverrideRolling: boolean;
  };
};

const SettlementPricesContext = createContext<SettlementPriceGrid | undefined>(undefined);
SettlementPricesContext.displayName = 'SettlementPricesContext';

export default function SettlementPricesProvider({ widgetId, initialValues, ...otherProps }: Props) {
  const {
    selectedColumns,
    selectedRows,
    columnsOrder: initialColumnsOrder,
    selectedSettlementTypes: initialSettlements,
    settlementDate: initialSettlementDate,
    userRollingRowSettings: initialRollingRowSettings,
    isOverrideRolling: initialIsOverrideLoading,
  } = initialValues;

  const gridState = useSettlementGridState(
    selectedColumns,
    selectedRows,
    initialColumnsOrder,
    initialSettlements,
    initialSettlementDate,
    initialRollingRowSettings,
    initialIsOverrideLoading,
    widgetId
  );

  const value = useMemo(() => ({ ...gridState }), [gridState]);

  return <SettlementPricesContext.Provider value={value} {...otherProps} />;
}

export function useSettlementPricesSettings(): SettlementPriceGrid {
  const context = useContext(SettlementPricesContext);

  if (!context) {
    throw new Error('useSettlementPricesSettings must be used within SettlementPricesProvider');
  }

  return context;
}
