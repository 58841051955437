import { API_RESOLUTION_MAP, DataFeedPermissions, INTERVAL_BACK_PERIODS, SearchSymbol, SearchSymbolResult, SymbolInfo } from '@protos/charts';
import ApiClient from '@services/ApiClient';
import { ChartTickersEnum } from '@services/types';
import { Product, ProductTenor, productMaps } from '@shared/protos/product';
import { Bar, HistoryCallback, OnReadyCallback, PeriodParams, ResolutionString, SubscribeBarsCallback } from '@tradingview/types';
import { subscribeOnStream, unsubscribeFromStream } from './streaming';

const CONFIGURATION_DATA = {
  supported_resolutions: Object.keys(INTERVAL_BACK_PERIODS) as ResolutionString[],
};

interface SymbolPeriodApi {
  loadData: (periodParams: PeriodParams) => Promise<Bar[]>;
  lastBar: () => undefined | ({ symbol?: string } & Bar);
}

const symbolPeriodLoader = (apiClient: ApiClient, symbol: string, period: ChartTickersEnum): SymbolPeriodApi => {
  const api = apiClient.chartLoader(symbol, period);
  // cache of all bars
  const allBars: Bar[] = [];

  const sliceData = (to: number, countBack: number, remaining: boolean) => {
    const toIndex = allBars.findIndex(bar => bar.time < to);
    // to index is there
    if (toIndex > -1) {
      const fromIndex = toIndex + countBack;
      // we have enough data to send back
      if (allBars.length > fromIndex) {
        return allBars.slice(toIndex, fromIndex).reverse();
      } else if (remaining) {
        return allBars.slice(toIndex).reverse();
      }
    }
    return [];
  };

  const loadData = async (periodParams: PeriodParams) => {
    const { to, countBack } = periodParams;
    const toMillis = 1000 * to;
    let bars = sliceData(toMillis, countBack, false);
    if (bars.length) return bars;
    while (api.hasMoreData() || !api.hasLoadedData()) {
      const apiData = await api.loadData();
      apiData.forEach(({ timestamp, low, high, open, close, symbol }, index) => {
        allBars.push({
          time: new Date(timestamp).getTime(),
          low,
          high,
          open,
          close,
          ...(index === 0 && symbol ? { symbol } : {}),
        });
      });
      bars = sliceData(toMillis, countBack, false);
      if (bars.length) return bars;
    }
    return sliceData(toMillis, countBack, true);
  };

  const lastBar = () => {
    return allBars[0];
  };

  return {
    loadData,
    lastBar,
  };
};

const symbolLoader = (apiClient: ApiClient, symbol: string) => {
  const periodDataLoaders = {};

  const getPeriodDataApi = (period: ChartTickersEnum): SymbolPeriodApi => {
    if (!periodDataLoaders[period]) {
      periodDataLoaders[period] = symbolPeriodLoader(apiClient, symbol, period);
    }
    return periodDataLoaders[period];
  };

  return {
    getPeriodDataApi,
  };
};

const chartDatafeed = (
  widgetID: string,
  apiClient: ApiClient,
  userProducts: Product[],
  tenors: ProductTenor[],
  permissions: DataFeedPermissions
): any => {
  const { productMap, tenorMap } = productMaps(userProducts, tenors);
  const symbolsLoader = apiClient.symbolsLoader();
  const symbolCache = {};

  const onReady = (callback: OnReadyCallback) => {
    setTimeout(() => callback(CONFIGURATION_DATA));
  };

  const searchSymbols = async (userInput: string, _, symbolType: string) => {
    let filteredSymbols: SearchSymbol[] = [];
    symbolsLoader.resetQuery({
      search: userInput || undefined,
    });
    const symbols = await symbolsLoader.loadData();

    filteredSymbols = symbols?.reduce<SearchSymbol[]>((acc, symbol) => {
      const productInfo = productMap[symbol.product_symbol];

      if (productInfo) {
        acc.push({
          symbol: symbol.symbol,
          ticker: symbol.symbol,
          full_name: symbol.description,
          description: symbol.description,
          exchange: '',
          type: productInfo.product_group,
        });
      }

      return acc;
    }, []);

    return filteredSymbols;
  };

  const resolveSymbolAsync = async (symbol: string) => {
    const symbolName = symbol.toLocaleLowerCase();
    const chartSymbol = await apiClient.getSymbol(symbolName);

    if (!chartSymbol) {
      throw new Error('Cannot resolve symbol');
    }

    const isCOTProduct = !!chartSymbol.tenor_code;
    const cotProductGroup = productMap[chartSymbol.product_symbol].product_group;
    const hasUserCOTPermissions = permissions.cot_product_groups.includes(cotProductGroup);

    let symbolEntryPrices: any = {};
    if (isCOTProduct && hasUserCOTPermissions) {
      try {
        symbolEntryPrices = await apiClient.getContractAnalytics(symbolName);
      } catch (e) {
        console.error('Failed to get contract analytics', e);
      }
    }

    const productInfo = productMap[chartSymbol.product_symbol];
    const tenorInfo = tenorMap[chartSymbol.tenor_code];
    const isRolling = chartSymbol.description.toLocaleLowerCase().includes('rolling');

    return {
      ticker: symbolName,
      name: symbolName,
      productSymbol: productInfo.symbol,
      description: tenorInfo || isRolling ? chartSymbol.description : `Historical Ticker: ${chartSymbol.description}`,
      type: productInfo.product_group,
      timezone: 'Etc/UTC',
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      has_seconds: true,
      visible_plots_set: 'ohlc',
      has_weekly_and_monthly: false,
      supported_resolutions: CONFIGURATION_DATA.supported_resolutions,
      volume_precision: 2,
      intraday_multipliers: ['1', '60'],
      seconds_multipliers: [1],
      session: '24x7',
      ...(symbolEntryPrices.marketlongentryprice90d && symbolEntryPrices.marketshortentryprice90d
        ? {
            analytics: {
              longEntry: symbolEntryPrices.marketlongentryprice90d,
              shortEntry: symbolEntryPrices.marketshortentryprice90d,
            },
          }
        : {}),
    };
  };

  const getSymbolPeriod = (symbolInfo: SymbolInfo, resolution: ResolutionString): SymbolPeriodApi => {
    const period = API_RESOLUTION_MAP[resolution.toLocaleLowerCase()] as ChartTickersEnum;
    let loader = symbolCache[symbolInfo.ticker];
    if (!loader) {
      loader = symbolLoader(apiClient, symbolInfo.ticker);
      symbolCache[symbolInfo.ticker] = loader;
    }
    return loader.getPeriodDataApi(period);
  };

  const getBarsAsync = async (symbolInfo: SymbolInfo, resolution: ResolutionString, periodParams: PeriodParams) => {
    const api = getSymbolPeriod(symbolInfo, resolution);
    return await api.loadData(periodParams);
  };

  const subscribeBars = (
    symbolInfo: SymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: any
  ) => {
    const api = getSymbolPeriod(symbolInfo, resolution);
    const lastBar = api.lastBar();
    const transformedSymbolInfo = lastBar ? ({ ...symbolInfo, ticker: lastBar.symbol } as SymbolInfo) : symbolInfo;

    subscribeOnStream(transformedSymbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, lastBar, widgetID);
  };

  const unsubscribeBars = subscriberUID => {
    unsubscribeFromStream(subscriberUID, widgetID);
  };

  return {
    onReady,
    searchSymbols: (userInput: string, _, symbolType: string, onResultReadyCallback: (arg: SearchSymbolResult) => void) => {
      searchSymbols(userInput, _, symbolType).then(onResultReadyCallback);
    },
    resolveSymbol: (symbolName: string, onSymbolResolvedCallback: (arg: any) => void, onResolveErrorCallback: (arg: string) => void) => {
      resolveSymbolAsync(symbolName).then(onSymbolResolvedCallback, onResolveErrorCallback);
    },
    getBars: (
      symbolInfo: SymbolInfo,
      resolution: ResolutionString,
      periodParams: PeriodParams,
      onHistoryCallback: HistoryCallback,
      onErrorCallback: (error: any) => void
    ) => {
      getBarsAsync(symbolInfo, resolution, periodParams).then(d => {
        if (d.length > 0) {
          onHistoryCallback(d, { noData: false });
        } else {
          onHistoryCallback(d, { noData: true });
        }
      }, onErrorCallback);
    },
    subscribeBars,
    unsubscribeBars,
  };
};

export default chartDatafeed;
