import Form from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { useTradingOtcForm } from './useTradingOtcForm';

const uiSchema = {
  'ui:title': '',
};

export const TradingOtcParamsForm: React.FC = () => {
  const { formData, formSchema, onFormChange, onFormSubmit } = useTradingOtcForm();

  if (!formSchema) return null;

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      validator={validator}
      onChange={onFormChange}
      onSubmit={onFormSubmit}
      templates={MuiTheme.templates}
      widgets={MuiTheme.widgets}
      fields={MuiTheme.fields}
    />
  );
};
