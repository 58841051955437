import ApiClient from '@services/ApiClient';
import { config } from '@services/context';
import { useAsync } from 'react-use';
import { useAppAuthentication } from './useAppAuthentication';

export const useAdminApi = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAppAuthentication();

  const state = useAsync(async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      return new ApiClient(config.adminApiUrl, token);
    } else {
      throw new Error('Not authenticated');
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return {
    apiClient: state.value,
    ...state,
  };
};
