/**
 *
 * @param items Source array
 * @param prop Prop name to filter for
 * @param searchText Search text
 * @returns A filtered array containing matching elements, empty otherwise.
 */
export const searchArray = <T, K extends keyof T>(items: T[], prop: K, searchText: string): T[] => {
  const results = items.filter(item => String(item[prop]).toLowerCase().includes(searchText.toLowerCase()));
  return results;
};

/**
 *
 * @param arr Source array
 * @param size Desire chunk size
 * @returns Array of chunks based on chunk size
 */
export const chunkArray = <T>(arr: T[], size: number): T[][] =>
  arr.length > size ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)] : [arr];
