import { useScreenSize } from '@hooks/useScreenSize';
import { Box, Typography } from '@mui/material';
import Countdown from 'react-countdown';
import './fluxCountdownStyles.css';

interface FluxCountdownProps {
  end: Date;
  title: string;
  Completionist: React.FC;
  onCountdownComplete?: () => void;
}
export const FluxCountdown: React.FC<FluxCountdownProps> = ({ end, title, Completionist, onCountdownComplete }) => {
  const { isMobile } = useScreenSize();

  const CotDataRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <Box display="flex" flexDirection="column" height="100vh" alignItems="center" justifyContent="center" gap={3}>
          <Typography variant={!isMobile ? 'h3' : 'h6'} fontWeight={800} fontFamily="Noto Sans JP Medium" color="#333" textAlign="center">
            {title}
          </Typography>
          <div className="countdown">
            <div className={!isMobile ? 'countdown-item' : 'countdown-item-mobile'}>
              <span className={!isMobile ? 'countdown-number' : 'countdown-number-mobile'}>{days}</span>
              <span className={!isMobile ? 'countdown-label' : 'countdown-label-mobile'}>Days</span>
            </div>
            <div className={!isMobile ? 'countdown-item' : 'countdown-item-mobile'}>
              <span className={!isMobile ? 'countdown-number' : 'countdown-number-mobile'}>{hours}</span>
              <span className={!isMobile ? 'countdown-label' : 'countdown-label-mobile'}>Hours</span>
            </div>
            <div className={!isMobile ? 'countdown-item' : 'countdown-item-mobile'}>
              <span className={!isMobile ? 'countdown-number' : 'countdown-number-mobile'}>{minutes}</span>
              <span className={!isMobile ? 'countdown-label' : 'countdown-label-mobile'}>Minutes</span>
            </div>
            <div className={!isMobile ? 'countdown-item' : 'countdown-item-mobile'}>
              <span className={!isMobile ? 'countdown-number' : 'countdown-number-mobile'}>{seconds}</span>
              <span className={!isMobile ? 'countdown-label' : 'countdown-label-mobile'}>Seconds</span>
            </div>
          </div>
        </Box>
      );
    }
  };

  return <Countdown date={end} renderer={CotDataRenderer} onComplete={onCountdownComplete} />;
};
