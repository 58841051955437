import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { alpha, styled, SxProps, Theme } from '@mui/material/styles';
import { getCenteredAbsoluteElStyles, StyledButton } from './StyledComponents';

type ButtonProps = {
  icon?: string;
  disabled?: boolean;
  isLoading?: boolean;
  loadingSx?: SxProps<Theme>;
  text: string;
  isSuccess?: boolean;
  color?: string;
  successText?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
type ExtendedButtonProps = ButtonProps & React.ComponentProps<typeof StyledButton>;

export const Button = styled(StyledButton)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightExtraBold,
}));

const LoadingButton = styled(StyledButton)({
  position: 'relative',
  pointerEvents: 'none',
});

const SuccessButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.success.main, 0.1),
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  pointerEvents: 'none',
}));

export function ActionButton(props: ExtendedButtonProps) {
  const { icon, text, isLoading, isSuccess, successText, loadingSx, color = 'primary', ...buttonProps } = props;

  if (isLoading) {
    return (
      <LoadingButton fullWidth variant="outlined" data-testid="loading-action-btn" {...buttonProps}>
        <CircularProgress
          size={20}
          sx={{
            color: 'text.primary',
            ...getCenteredAbsoluteElStyles(),
            ...loadingSx,
          }}
        />
      </LoadingButton>
    );
  }

  if (isSuccess) {
    return (
      <SuccessButton fullWidth variant="outlined" data-testid="success-action-btn" {...buttonProps}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <i className="ri-checkbox-circle-line" style={{ fontSize: '18px', lineHeight: 1 }} />
          <span>{successText}</span>
        </Stack>
      </SuccessButton>
    );
  }

  return (
    <Button fullWidth variant="outlined" data-testid="standard-action-btn" color={color} {...buttonProps}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <i className={icon} style={{ fontSize: '24px', lineHeight: 1 }} />
        <span>{text}</span>
      </Stack>
    </Button>
  );
}

export function ErrorButton({ loadingSx, ...rest }: ExtendedButtonProps) {
  return <ActionButton color="error" loadingSx={{ color: 'error.main', ...loadingSx }} data-testid="error-action-btn" {...rest} />;
}

export const SaveChangesButton = styled(({ loadingSx, ...rest }: ExtendedButtonProps) => (
  <ActionButton loadingSx={{ color: 'success.main', ...loadingSx }} {...rest} />
))(({ theme, isLoading }) => ({
  borderColor: theme.palette.success.main,
  color: isLoading ? 'transparent' : theme.palette.success.main,
  '&:hover': {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  '&.Mui-disabled': {
    color: `${theme.palette.text.disabled} !important`,
    borderColor: alpha(theme.palette.primary.main, 0.1),
  },
}));
