import { Typography as MuiTypography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { ReactElement, ReactNode, forwardRef } from 'react';
import { WidgetProps } from '../types';

type HeaderLeftRightProps = {
  children: ReactElement | ReactElement[];
};

type HeaderProps = {
  children: ReactElement | ReactElement[];
  title: string;
  widgetIcon?: ReactElement;
};

type ContentProps = {
  children: ReactElement;
};

type Props = {
  children: ReactElement | ReactElement[] | ReactNode;
  blurComponent?: ReactElement;
} & WidgetProps;

const StyledContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.darker,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.background.darker}`,
  position: 'relative',
}));

const StyledHeader = styled(Stack)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.default,
}));

const StyledHeaderTitle = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  padding: '0 5px',
  marginBottom: 1,
  fontWeight: 'bold',
}));

const StyledBody = styled(Stack)({
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
});

function HeaderLeft({ children }: HeaderLeftRightProps) {
  return (
    <Stack direction="row" alignItems="center" columnGap="15px">
      {children}
    </Stack>
  );
}

function HeaderRight({ children }: HeaderLeftRightProps) {
  return (
    <Stack direction="row" alignItems="center" columnGap="15px" paddingRight="15px">
      {children}
    </Stack>
  );
}

function Header({ children, title, widgetIcon }: HeaderProps) {
  const { isMobile } = useScreenSize();

  const defaultChildren = <HeaderRight>{children}</HeaderRight>;
  const childrenIsArray = children && Array.isArray(children);

  const left = childrenIsArray ? children.find(child => child.type === HeaderLeft) : null;
  const right = childrenIsArray ? children.find(child => child.type === HeaderRight) ?? defaultChildren : defaultChildren;

  return (
    <StyledHeader direction="row" height={isMobile ? '48px' : '25px'}>
      {left}
      <Stack className="draggable-handle-area" sx={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
        {widgetIcon}
        <StyledHeaderTitle variant="body">{title}</StyledHeaderTitle>
      </Stack>
      {right}
    </StyledHeader>
  );
}

function Content({ children }: ContentProps) {
  return <StyledBody className="draggable-widget-content">{children}</StyledBody>;
}

const Widget = forwardRef<HTMLDivElement, Props>(({ children, blurComponent, ...blurProps }, ref) => (
  <StyledContainer ref={ref}>{children}</StyledContainer>
));

export { Content, Header, HeaderLeft, HeaderRight };
export default Widget;
